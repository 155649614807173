import { configureStore } from "@reduxjs/toolkit";
import auth_slice from "./auth-slice";
import product_slice from "./products_slice";
import cart_slice from "./cart_slice";
import ui_slice from "./ui_slice";
import order_slice from "./order_slice";
import wishlist_slice from "./wishlist_slice";
import blog_slice from "./blog_slice";
import banner_slice from "./banner_slice";



const store = configureStore({
    reducer:{
        auth: auth_slice.reducer,
        products: product_slice.reducer,
        cart: cart_slice.reducer,
        ui: ui_slice.reducer,
        order: order_slice.reducer,
        wishlist:wishlist_slice.reducer,
        blog: blog_slice.reducer,
        banners: banner_slice.reducer
    }
})

export default store;