import React from 'react'
import { Grid } from '@mui/material'
import BreadCrumb from '../components/breadCrumb'
import { privacyDesc } from '../utils'


export default function Privacy() {
  return (
    <>
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <BreadCrumb crumbs={[
                    {text:'Privacy policy', url:'/privacy-policy'}
                ]}/>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} md={12}>
                {
                    privacyDesc.map((term, index)=>{
                        return(
                            <div style={{padding:"20px"}}>
                                <h4>{term.title}</h4>
                                <p>{term.text}</p>
                            </div>
                        )
                    })
                }
            </Grid>
        </Grid>
    </>
  )
}




