import React from 'react'
import Skeleton from 'react-loading-skeleton';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../assets/css/components.css';



const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1080, min: 464 },
      items: 4,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
};

function LoadingSkeleton() {
  return (
    <div className='custom-carousel-div'>
      <div className='custom-carousel-wrapper'>
        <div className='custom-carousel-title'>
          <><Skeleton style={{height:'25px', width:'100px'}}/></>
          <Skeleton style={{height:'25px', width:'70px'}}/>
        </div>
        <div >
          <Carousel
              swipeable={true}
              draggable={false}
              showDots={false}
              responsive={responsive}
              className='custom-carousel'
              ssr={true}
          >
            <Skeleton style={{height:'320px', width:'200px'}}/>
            <Skeleton style={{height:'320px', width:'200px'}}/>
            <Skeleton style={{height:'320px', width:'200px'}}/>
            <Skeleton style={{height:'320px', width:'200px'}}/>
            <Skeleton style={{height:'320px', width:'200px'}}/>
            <Skeleton style={{height:'320px', width:'200px'}}/>
            <Skeleton style={{height:'320px', width:'200px'}}/>
            <Skeleton style={{height:'320px', width:'200px'}}/>
            <Skeleton style={{height:'320px', width:'200px'}}/>
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default LoadingSkeleton