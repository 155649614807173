import React, {useEffect, useState} from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { Badge, Button, Menu, MenuItem} from '@mui/material';
import 'react-dropdown/style.css';

import '../assets/css/styles.css';
import images from '../assets/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  blackColor,
  family_planning,
  activeColor,
  nav_tab_options,
  capitalizeFirstLetter,
  my_orders,
  addCommas
} from '../utils';

import LogoutIcon from '@mui/icons-material/Logout';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import DropDown from './dropDown';
import { openSearchDialog } from '../store/searchDialog_slice';
import { TypeAnimation } from 'react-type-animation';


function NavigationBar() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const signedIn = localStorage.getItem('isLoggedIn')
  const localName = localStorage.getItem('name')
  const localEmail = localStorage.getItem('email')

  const [isVisible, setIsVisible] = useState(false)
  
  const auth = useSelector((state)=>state.auth)
  const isLoggedIn = auth.isLoggedIn
  const first_name = auth.profile !== null && auth.profile !== undefined ? auth.profile.first_name : ''
  const second_name = auth.profile !== null && auth.profile !== undefined ? auth.profile.second_name : ''
  

  const cartItemNum = useSelector(state => state.cart.cartNumberofProducts) 
  const ttl_price = useSelector(state => state.cart.totalPrice)
  const wishlistNumItmem = useSelector(state => state.wishlist.wishListNumOfItems)
  
  const userEmail = auth.user !== null && auth.user !== undefined ? auth.user.email : localEmail

  // const [cartNumber, setCartNumber] = useState(parseInt(localStorage.getItem('cartNum')) || 0);
  // const [cartTotal, setCartTotal] = useState(parseInt(localStorage.getItem('cartTotal')) || 0);

  const cartNumber = localStorage.getItem('cartNum');
  const cartTotal = localStorage.getItem('cartTotal')


  useEffect(()=>{
  },[cartItemNum, auth, ttl_price, cartNumber, cartTotal]);

  const openSrch = ()=>{
    dispatch(openSearchDialog())
  }

  return (
    <nav className='navigation-bar'>
      <div className='navigation-info'>
        <div className='info'>
          Info: +256 706 404 040
          <NavLink to='about-us'>
            About Us
          </NavLink>
          <NavLink to='terms&conditions'>
            Terms & Conditions
          </NavLink>
        </div>
        <div className='advert' onClick={()=>navigate('/family-planning')}>
          ~ CHECKOUT FAMILY PLANNING PRODUCTS ~
        </div>
        <div className='faq'>
          <NavLink>
            FAQ
          </NavLink>
          {
            isLoggedIn || signedIn ?
              <NavLink to='/signout'>
                <Button variant='contained' color='error' size='small'>
                  <LogoutIcon/> Logout
                </Button>
              </NavLink>
            :
              <></>
          }
        </div>
      </div>
      <div className='navigation-auth'>
        <div className='logo'>
          <NavLink to='/'>
            <img
              src={images.logo6}
              alt=""
            />
          </NavLink>
        </div>
        <div className='nav-search'>
            <div className='nav-search-in-div' onClick={openSrch}>
              <SearchOutlinedIcon/> Search for Medicines and Health Products
            </div>
        </div>
        <div className='auth-wish-cart'>
          <NavLink
            to='signin'
            style={({isActive})=>{
              return {
                color: isActive ? activeColor : blackColor,
                textDecoration: isActive ? 'underline' : 'none'
              }
            }}
          >
            <div className='auth'>
              
              {
                isLoggedIn || signedIn ?
                  <NavLink to='/manage/account'>
                    <img src={images.user_profile} alt=''/>
                    <h3>Welcome!</h3>
                    <>
                      {
                        first_name !== '' && first_name !== undefined ?
                          capitalizeFirstLetter(first_name) + ' ' + capitalizeFirstLetter(second_name)
                        :
                          localName || userEmail
                      }
                    </>
                  </NavLink>
                :
                  <>
                    <img src={images.user_auth} alt=''/>
                    <h3>Account</h3>
                    <>Login/Register</>
                  </>
              }
            </div>
          </NavLink>
          <NavLink
            to='/wishlist'
          >
            <div className='wish'>
              <Badge badgeContent={isLoggedIn || signedIn ? wishlistNumItmem : 0}  color='primary'>
                <img src={images.heart} alt=''/>
              </Badge>
              <h3>Wishlist</h3>
              View your wishlist
            </div>
          </NavLink>
          <NavLink
            to='/cart'
          >
            <div className='cart'>
              <Badge
                badgeContent={
                  isLoggedIn || signedIn? 
                    cartItemNum 
                  : !isLoggedIn || signedIn === undefined && cartNumber?
                      cartNumber
                  : 0
                }
                color='primary'
              >
                <img src={images.shopping_cart} alt=''/>
              </Badge>
              <h3>Cart</h3>
              {
                isLoggedIn || signedIn ?
                  'Ugx. ' + addCommas(ttl_price.toString())
                : ((!isLoggedIn || signedIn === undefined) && cartTotal) ?
                  'Ugx. ' + addCommas(cartTotal.toString())
                :
                  'Ugx 0.0'
              }
            </div>
          </NavLink>
        </div>
      </div>
      <div className='navigation-tabs'>
        <div className='navigation-dropdown'>
          <div
            className='browse'
            onMouseEnter={()=>{
              setIsVisible(true)
            }}
            onMouseLeave={()=>{
              setIsVisible(false)
            }}

          > 
            {
              isVisible ?
                <MoreHorizIcon fontSize='large'/>
              :
                <MenuIcon fontSize='large'/>
            }
            Browse Categories
          </div>
          {
            isVisible ? 
              <DropDown
                onEnter={()=>{
                  setIsVisible(true)
                }}
                onLeave={()=>setIsVisible(false)}
              /> 
            : 
              <></>
          }
        </div>
        <div className='navigation-tab'>
          {
            nav_tab_options.map((item, index)=>{
              return(
                
                  <div key={index} className='nav-tab'>
                    <NavLink
                      to={item.url}
                      style={({isActive})=>{
                        // isActive ? setIsNavActive(true) : setIsNavActive(false)
                        return isActive ? {
                          backgroundColor:'whitesmoke',
                          color:'#36454F',
                          height:'100%',
                          width:'100%',
                          alignItems:'center',
                          display:'flex',
                          justifyContent:'center'
                        } : {}
                      }}
                    >
                    {item.page}
                    </NavLink>
                  </div>
              )
            })
          }
          {
            isLoggedIn || signedIn?
              <div className='nav-tab'>
              <NavLink to='/transactions'>
                {my_orders}
              </NavLink>
            </div>
          :
            <></>
          }
        </div>
        <div className='special-nav-tab'>

        </div>
        <div className='special-nav-tab-2'>

        </div>
        <div className='special-nav-overlay'>
          <NavLink
            to='/family-planning'
            id='special-nav-tab-link'
            style={({isActive})=>{
              return {
                textDecoration: isActive ? 'none' : 'none',
                color: isActive ? 'white' : 'white'
              }
            }}
          >
            <p className='animate-character'>{family_planning}</p>
          </NavLink>
        </div>
      </div>
    </nav>
  );
}


export const NavDropdown = (props)=> {
  const {text, data, imgSrc, isAuth, openIcon, closeIcon} = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={isAuth ? 'spec-navigation-dropdlink-auth' : 'spec-navigation-dropdlink'}>
      <img src={imgSrc} alt='' className='spec-navigation-droplink-auth-img'/>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {text}
        {
          open === true ? openIcon : closeIcon
        }

      </Button>
      
{      isAuth ?
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          className='auth-menu'
        >
          {data.map((item, index) => (
            <MenuItem key={index} style={{borderBottom:'1px dotted'}}>
              <NavLink to={item.url}>
                <div className='auth-menu-item'>
                  {item.icon}
                  <div className='auth-menu-item-spacer'></div>
                  {item.option}

                  <ArrowForwardIosOutlinedIcon style={{fontSize:'12px', marginTop:'5px', marginLeft:'10px'}}/>
                </div>
              </NavLink>
            </MenuItem>
          ))}
        </Menu>
      :
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          className='auth-menu'
        >
          {data.map((item, index) => (
            <MenuItem key={index}>
              <NavLink to={item.url}>
                {item}
              </NavLink>
            </MenuItem>
          ))}
        </Menu>
        }

    </div>
  );
}


export default NavigationBar;
