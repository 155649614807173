import React from 'react';
import { Container, Paper, Avatar, Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const AdLog = () => {
  const navigate = useNavigate();  //  useHistory failed to work so i used useNavigate

  //login logic
  const handleLogin = (event) => {
    event.preventDefault();

    // Accessing the input values
    const username = event.target.elements.username.value;
    const password = event.target.elements.password.value;

    // Auth logic: Connect to backend to verify credentials(Simeon Firebase)
    // For now, just log the entered credentials
    console.log('Entered Username:', username);
    console.log('Entered Password:', password);

    // Reset the form fields after handling login
    event.target.reset();

    // Redirect to the Analytics page
    navigate('/analytics');
  };

  // Stylish dynamic logo styles
  const logoStyles = {
    backgroundColor: 'red',
    width: '60px',
    height: '60px',
    fontSize: '36px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* Stylish Dynamic Logo */}
        <Avatar sx={logoStyles}>
          {/* You can replace the text with your logo image(Andrew) */}
          DX
        </Avatar>

        <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
          Admin Login
        </Typography>

        {/* Login Form */}
        <form onSubmit={handleLogin} style={{ width: '100%', mt: 3 }}>
          <TextField
            required
            fullWidth
            label="Username"
            margin="normal"
            variant="outlined"
            name="username"
          />
          <TextField
            required
            fullWidth
            label="Password"
            type="password"
            margin="normal"
            variant="outlined"
            name="password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log In
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default AdLog;
