import React from 'react'
import { Grid } from '@mui/material'
import BreadCrumb from '../components/breadCrumb'
import { termsDesc } from '../utils'


export default function Terms() {
  return (
    <>
        <Grid container>
            <Grid item xs={12} md={12} lg={12}>
                <BreadCrumb crumbs={[
                    {text:'Terms & Conditions', url:'/terms&conditions'}
                ]}/>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12} md={12}>
                {
                    termsDesc.map((term, index)=>{
                        return(
                            <div style={{padding:"20px"}}>
                                <h4>{term.title}</h4>
                                <p>{term.text}</p>
                            </div>
                        )
                    })
                }
            </Grid>
        </Grid>
    </>
  )
}
