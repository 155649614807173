import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Grid, Button, Paper } from '@mui/material';
import MainLayout from '../layouts/mainLayout';
import images from '../assets';
import '../assets/css/aboutUs.css';
import BreadCrumb from '../components/breadCrumb.js';

const AboutUs = () => {
  return (
    <Container className="about-us-container">
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <BreadCrumb crumbs={[
            {text:'about-us', url:'/about-us'}
          ]}/>
        </Grid>
      </Grid>
      {/* Hero Section */}
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h2" align="center" color="primary" gutterBottom>
            Welcome to DrugsExpress
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Your trusted partner in medication management
          </Typography>
          <Typography align="center" color="textSecondary" paragraph>
              At Drugs Express, we are committed to providing our customers with a hassle-free way to manage their medication needs. 
              With our easy-to-use platform and reliable delivery services, we make it simple to get the prescription and over-the-counter drugs you need, delivered right to your doorstep. Our expert pharmacists ensure that you receive the right medication, right on time, so you can focus on what matters most – your health and well-being.
              Choose Drugs Express today for all your medication needs
          </Typography>

        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className="about-us-hero-image" elevation={2}>
            {/* Add your hero image here */}
            {/* <img src={images.about} alt="About Us" /> */}
          </Paper>
        </Grid>
      </Grid>

      {/* Our Story Section */}
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h4" align="center" color="primary" gutterBottom id='our-story'>
            Our Story
          </Typography>
          <Paper className="about-us-story" elevation={0} >
            <Typography align="center" color="textSecondary" paragraph>
              Founded on the principles of innovation and excellence, Drugs Express started its journey with a vision
              to redefine possibilities. Over the years, we've grown into a dynamic force in the industry,
              pushing boundaries and challenging norms.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Our Values Section */}
      <Grid container spacing={4} justifyContent="center" id='values-div'>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" align="center" color="primary" gutterBottom>
            Our Values
          </Typography>
          <Paper className="about-us-values" elevation={0}>
            <Typography align="center" color="textSecondary" paragraph>
              <strong>Innovation:</strong> We embrace creativity and forward-thinking to drive innovation
              in everything we do.
            </Typography>
            <Typography align="center" color="textSecondary" paragraph>
              <strong>Collaboration:</strong> We believe in the power of collaboration, working together
              to achieve shared goals and success.
            </Typography>
            <Typography align="center" color="textSecondary" paragraph>
              <strong>Integrity:</strong> Our commitment to integrity is unwavering, ensuring honesty,
              transparency, and ethical business practices.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className="about-us-values-image" elevation={0}>
            {/* Add an image related to values here */}
            {/* <img src={images.value} alt="Values Image" /> */}
          </Paper>
        </Grid>
      </Grid>

      {/* Join Us Section */}
      {/* <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h4" align="center" color="primary" gutterBottom>
            Join Us on Our Journey
          </Typography>
          <Paper className="about-us-join" elevation={3} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            
            <img src="/career.jpg" alt="Values Image" style={{ width: '100%', height: 'auto', borderRadius: '8px', marginBottom: '16px' }} />
            <Typography align="center" color="textSecondary" paragraph>
              If you share our passion for innovation and want to be part of a team that's making a difference,
              we invite you to explore career opportunities with Drugs Express.
            </Typography>
            <Grid container justifyContent="center">
              <Button
                component={Link}
                to="/careers"
                variant="contained"
                color="primary"
                size="large"
              >
                Explore Careers
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid> */}
    </Container>
  );
};

AboutUs.layout = (page) => <MainLayout children={page} title="About Us" />;
export default AboutUs;
