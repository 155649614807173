import React, {useState, useEffect} from 'react'
import '../assets/css/medicine.css'
import { FormControl, InputLabel, Select, MenuItem, CircularProgress,
    Grid, FormControlLabel, FormGroup, Checkbox, Menu, Button, Paper,
    Popover, Stack, Pagination
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { getCategoriesAsync, getProductByCategoryId, filterProducts } from '../store/products_slice'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ProductCard from '../components/productCard'
import BreadCrumb from '../components/breadCrumb'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { capitalizeProductName } from '../utils'

function Medicine() {
    const [condition, setCondition] = useState('')
    const [category, setCategory] = useState(null)
    const [medicine, setMedicine] = useState([])
    const [checked, setChecked] = useState(false)
    const [fChecked, setFChecked] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [brands, setBrands] = useState([])
    const [brand, setBrand] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [page, setPage] = useState(1);
    const [pageSize] = useState(20);
    const [lastVisibleDocument, setlastVisibleDocument] = useState(null);
    const p = useParams();
    const productId = p.id;
    const range = Array.from({ length: 12 }, (_, index) => index);

    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };

    const dispatch = useDispatch()

    const handleChange = (event) => {
        setCondition(event.target.value)
    }

    const onMaleFilter = (filterTerm)=>{
        setIsLoading(true)
        setChecked((prevChecked) => {
            if(prevChecked){
                dispatch(filterProducts({gender:filterTerm}))
                .then(res=>{
                    if(res['meta']['requestStatus'] === 'fulfilled'){
                        setMedicine(res.payload)
                        setIsLoading(false)
                    }
                })   
            }
            else{
                dispatch(getProductByCategoryId({page, pageSize, lastVisibleDoc:null, id: p.id}))
                .then(res => {
                    setMedicine(res.payload.categoryProductsList)
                    setIsLoading(false)
                })
            }
            return prevChecked;
        });
        setIsLoading(false)
    }

    const onFemaleFilter = (filterTerm)=>{
        setIsLoading(true)
        setFChecked((prevChecked) => {
            if(prevChecked){
                dispatch(filterProducts({gender:filterTerm}))
                .then(res=>{
                    if(res['meta']['requestStatus'] === 'fulfilled'){
                        setMedicine(res.payload)
                        setIsLoading(false)
                    }
                })   
            }
            else{
                dispatch(getProductByCategoryId({page, pageSize, lastVisibleDoc:null, id: p.id}))
                .then(res => {
                    if(res['meta']['requestStatus'] === 'fulfilled'){
                        setMedicine(res.payload.categoryProductsList)
                        setIsLoading(false)
                    }
                })
            }
            return prevChecked;
        });
        setIsLoading(false)
    }

    const onBrandFilter = (filterTerm)=>{
        setIsLoading(false)
        if(checked){
            dispatch(filterProducts({gender:'male', brand:filterTerm}))
            .then(res=>{
                if(res['meta']['requestStatus'] === 'fulfilled'){
                    setMedicine(res.payload)
                    setBrand(filterTerm)
                    setIsLoading(false)
                }
            }) 
        }
        else if(fChecked){
            dispatch(filterProducts({gender:'female', brand: filterTerm}))
            .then(res=>{
                if(res['meta']['requestStatus'] === 'fulfilled'){
                    setMedicine(res.payload)
                    setBrand(filterTerm)
                    setIsLoading(false)
                }
            }) 
        }
        else{
            dispatch(filterProducts({brand: filterTerm}))
            .then(res=>{
                if(res['meta']['requestStatus'] === 'fulfilled'){
                    setMedicine(res.payload)
                    setBrand(filterTerm)
                    setIsLoading(false)
                }
            })
        }
        setIsLoading(false)
    }

    const handlePaginationChange = (event, value)=>{
        setPage(value) 
    }

    useEffect(()=>{
        setIsLoading(true)
        dispatch(getCategoriesAsync())
        .then(res=>{
            if(res['meta']['requestStatus'] === 'fulfilled'){
                setCategory(res.payload)
            }
            else if(res['meta']['requestStatus'] === 'pending'){
                setIsLoading(true)
            }

        })

        dispatch(getProductByCategoryId({page, pageSize, lastVisibleDoc:lastVisibleDocument, id: productId}))
        .then(res => {
            if(res['meta']['requestStatus'] === 'fulfilled'){
                setMedicine(res.payload.categoryProductsList)
                setlastVisibleDocument(res.payload.lastVisible)
                const manufacturers = res.payload.categoryProductsList
                .map(element => element.manufacturer_name)
                .filter((value, index, self) => self.indexOf(value) === index);

                setBrands(manufacturers)
                setIsLoading(false)
            }
            else if(res['meta']['requestStatus'] === 'pending'){
                setIsLoading(true)
            }
        })
        setIsLoading(false)
    },[page])

  return (
    <div className="medicine-page">
        <div className='search-form'>
            <BreadCrumb
                crumbs={[
                    {text:p.name, url:`/${p.id}/${p.name}/products`}
                ]}
            />

        </div>
        <div className='medicine-body'>
            <div className='filter-div'>
                <div className='filter-heading'><FilterListOutlinedIcon/>filter</div>
                <div className='category-div'>
                    <div className='sex-filter'>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={()=>{
                                            if(fChecked){
                                                setFChecked(false)
                                            }
                                            setChecked(!checked)
                                            onMaleFilter('male')
                                        }}
                                        checked={checked}
                                    />
                                }
                                label="Male"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={()=>{
                                            if(checked){
                                                setChecked(false)
                                            }
                                            setFChecked(!fChecked)
                                            onFemaleFilter('female')
                                        }}
                                        checked={fChecked}
                                    />
                                }
                                label="Female"
                            />
                            {/* <FormControlLabel control={<Checkbox />} label="Unisex"/>    */}
                        </FormGroup>
                    </div>
                    {/* <div className='brand-filter'>
                        <FormControl fullWidth size='small'>
                            <InputLabel>Filter By Brand</InputLabel>
                            <Select
                                value={condition}
                                label='Condition'
                                onChange={handleChange}
                            >
                                {
                                    brands.length > 0 ?
                                        brands.map((c, index)=>{
                                            return (
                                                <MenuItem key={index} style={{padding:'0'}}>
                                                    <div
                                                        onClick={()=>{
                                                            onBrandFilter(c)
                                                        }}
                                                        style={{
                                                            margin:'5px',
                                                            width:'100%',
                                                            height:'30px',
                                                            display:'flex',
                                                            alignItems:'center'
                                                        }}
                                                    > 
                                                        { capitalizeProductName(c) }
                                                    </div>
                                                </MenuItem>
                                            )
                                        })
                                    :
                                        <CircularProgress/>
                                }
                                
                            </Select>
                        </FormControl>
                    </div> */}
                    {/* <div className='category-filter'>
                        <FormControl fullWidth size='small'>
                            <InputLabel>Filter By Category</InputLabel>
                            <Select
                                value={condition}
                                label='Condition'
                                onChange={handleChange}
                            >
                                {
                                    category !== null ?
                                        category.map((c, index)=>{
                                            return (
                                                <MenuItem key={index}>{c.name}</MenuItem>
                                            )
                                        })
                                    :
                                        <CircularProgress/>
                                }
                                
                            </Select>
                        </FormControl>
                    </div> */}
                </div>
            </div>
            <div className='mob-filter-div'>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    size='small'
                >
                    <FilterAltOutlinedIcon/> Filter
                </Button>
                    <Popover
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                        PaperProps={{
                            style: {
                                width: '90%',
                                maxWidth: '90%',
                            },
                        }}
                    >
                        <MenuItem onClick={handleClose}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={()=>{
                                                if(fChecked){
                                                    setFChecked(false)
                                                }
                                                setChecked(!checked)
                                                onMaleFilter('male')
                                            }}
                                            checked={checked}
                                        />
                                    }
                                    label="Male"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={()=>{
                                                if(checked){
                                                    setChecked(false)
                                                }
                                                setFChecked(!fChecked)
                                                onFemaleFilter('female')
                                            }}
                                            checked={fChecked}
                                        />
                                    }
                                    label="Female"
                                />
                                {/* <FormControlLabel control={<Checkbox />} label="Unisex"/> */}
                            </FormGroup>
                        </MenuItem>
                        {/* <MenuItem onClick={handleClose} style={{width:'300px'}}>
                            <FormControl fullWidth size='small'>
                                <InputLabel>Filter By Brand</InputLabel>
                                <Select
                                    value={condition}
                                    label='Condition'
                                    onChange={handleChange}
                                >
                                    {
                                        brands.length > 0 ?
                                            brands.map((c, index)=>{
                                                return (
                                                    <MenuItem key={index} style={{padding:'0'}}>
                                                        <div
                                                            onClick={()=>{
                                                                onBrandFilter(c)
                                                            }}
                                                            style={{
                                                                margin:'5px',
                                                                width:'100%',
                                                                height:'30px',
                                                                display:'flex',
                                                                alignItems:'center'
                                                            }}
                                                        > 
                                                            { capitalizeProductName(c) }
                                                        </div>
                                                    </MenuItem>
                                                )
                                            })
                                        :
                                            <CircularProgress/>
                                    }
                                    
                                </Select>
                            </FormControl>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>

                        </MenuItem> */}
                    </Popover>
            </div>
            <div className='medicine'>
                {
                    checked && !fChecked ?
                        <div className='filter-tag'>
                            showing "MALE" products
                        </div>
                    : fChecked && !checked ?
                        <div className='filter-tag'>
                            showing "FEMALE" products
                        </div>
                    :
                        <div></div>
                }
                <Grid container spacing={1}>
                    {
                        medicine.length > 0 || !isLoading ?
                            medicine.map((item, index)=>{
                                return(
                                    <Grid item xs={6} sm={3} md={3} lg={3} key={item.id}>
                                        
                                        <ProductCard
                                            image={item.images.length > 0 ? item.images[0] : ''}
                                            discount={item.discount}
                                            title={item.name}
                                            newPrice={item.has_flash_sale ? item.flash_sale_b2c_price : ''}
                                            originalPrice={item.b2c_price}
                                            id={item.id}
                                            is_discounted={item.has_flash_sale}
                                            dispensation={item.dispensation === 'prescription' || item.dispensation === 'controlled' ? "redirect" : false}
                                        />
                                    </Grid>
                                )
                            })
                        : (isLoading) ?
                            range.map((item, index)=>{
                                return(
                                    <Grid item xs={6} sm={3} md={3} lg={3} key={item}>
                                        <Skeleton style={{height:'320px', width:'200px'}}/>
                                    </Grid>
                                );
                            })
                        :
                            <>
                                An Error occurred. Try reloading this page.
                            </>
                    }
                </Grid>
                <div className='medicine-pagination-div'>
                    <Stack spacing={2}>
                        <Pagination count={10} page={page} onChange={handlePaginationChange}/>
                    </Stack>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Medicine