import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CircularProgress, Dialog, Paper,
    FormControlLabel, FormGroup, Checkbox, Box
} from '@mui/material';
import '../assets/css/orders.css';
import BreadCrumb from '../components/breadCrumb';
import { getUserOrdersAsync, deleteUserOrderAsync } from '../store/order_slice';
import MopedOutlinedIcon from '@mui/icons-material/MopedOutlined';
import CheckIcon from '@mui/icons-material/Check';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import images from '../assets/index.js';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { addCommas } from '../utils.js';



function OrderTransactions() {
    const dispatch = useDispatch();
    const redOrders = useSelector(state => state.orders.orders)
    const rOrders = redOrders !== undefined || redOrders !== null ? redOrders.orders : []
    const user = useSelector(state => state.auth.user)
    const localUEmail = localStorage.getItem('email')

    const user_email = user !== null ? user.email : localUEmail

    const [value, setValue] = React.useState(0);
    const [orders, setOrders] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [orderNumber, setOrderNumber] = useState('');
    const [orderId, setOrderId] = useState(null);
    const [orderDeleted, setOrderDeleted] = useState(false);
    const [orderDeleteFailed, setOrderDeleteFailed] = useState(false);
    const [noOrders, setNoOrders] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [checked, setChecked] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (rOrders && rOrders.length > 0) {
            const filteredOrders = filterOrders(rOrders);
            
            setOrders(filteredOrders);
        } else {
            dispatch(getUserOrdersAsync(user_email))
                .then(res => {
                    setIsLoading(true);
                    if (res['meta']['requestStatus'] === 'fulfilled') {
                        if (res.payload.length > 0) {
                            const filteredOrders = filterOrders(res.payload);
                            setOrders(filteredOrders);
                        } else {
                            setNoOrders(true);
                        }
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.log("OR GO ELSE WHERE",error);
                });
        }
        setIsLoading(false);
    }, [orders, orderDeleted, orderDeleteFailed, selectedOption, dispatch, rOrders,]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = ()=>{
        setOpen(false)
    }

    const cancelOrder = (id) => {

        dispatch(deleteUserOrderAsync(id))
        .then(res => {
            if(res['meta']['requestStatus'] === 'fulfilled'){
                setOrderDeleted(true)
                setOpen(false)
            }
            else if(res['meta']['requestStatus'] === 'rejected'){
                orderDeleteFailed(true)
            }

            setOrderDeleted(false)
            setOrderDeleteFailed(false)
        })
        .catch(error => console.log('An error occurred'))
    };

    const handleSwitchChange = (event)=>{
        setChecked(event.target.checked)
    }
    

    const handleCheckChange = (event) => {
        if (event.target.checked) {
            setSelectedOption(event.target.name);
        } else {
            setSelectedOption(null);
        }
    };

    const filterOrders = (orders) => {
        const filteredOrders = orders.filter(order => {
            const status = order.data;
    
            switch (selectedOption) {
                case 'Received':
                    return status.pending && !status.assigned && !status.completed && !status.cancelled && !status.deliverer_assigned && !status.delivering;
                case 'Packaged':
                    return status.assigned && !status.pending && !status.completed && !status.cancelled && !status.deliverer_assigned && !status.delivering;
                case 'On the way':
                    return (status.deliverer_assigned || status.delivering) && !status.pending && !status.completed && !status.cancelled;
                case 'Delivered':
                    return status.completed && !status.pending && !status.assigned && !status.cancelled && !status.deliverer_assigned && !status.delivering;
                default:
                    return true;
            }
        });
    
        return filteredOrders.sort((a, b) => new Date(b.data.time) - new Date(a.data.time));
    };

  return (
    <>
        <BreadCrumb crumbs={[{text:'Orders', url:'/transactions'}]}/>
        <div className='order-tab'>
            <div className='completed-orders-btn-div'>
                <Paper elevation={2} className='order-filter-card'>
                    <p>Filter orders</p>
                    <FormGroup>
                        <Box display="flex">
                            {['Received', 'Packaged', 'On the way', 'Delivered'].map((option, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={selectedOption === option}
                                            onChange={handleCheckChange}
                                            name={option}
                                        />
                                    }
                                    label={option}
                                />
                            ))}
                        </Box>
                    </FormGroup>
                </Paper>
            </div>
            {
                isLoading ?
                    <>
                        <CircularProgress />
                        <p>Loading...</p>
                    </>
                :
                    orders.map((orderItem, index) => {
                        let orderDate = new Date(orderItem.data.time);
                        let formattedDate = orderDate.toLocaleDateString("en-US", {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        });

                        return (
                            <Paper className='order-card' elevation={2} key={orderItem.id}>
                                <div className='order-card-id'>
                                    <p>Date placed: <span>{formattedDate}</span></p>
                                    {
                                        (orderItem.data.assigned || orderItem.data.deliverer_assigned || orderItem.data.completed || orderItem.data.delivering) ?
                                            <></>
                                        :
                                            <Button color='error' onClick={() => {
                                                setOrderNumber(orderItem.data.order_number)
                                                setOrderId(orderItem.id)
                                                setOpen(true)
                                            }}>
                                                <DeleteForeverOutlinedIcon /> Cancel Order
                                            </Button>
                                    }
                                </div>
                                <div className='order-card-details'>
                                    <div className='order-number'>
                                        <p>Order ID: </p>
                                        <h5>{orderItem.data.order_number}</h5>
                                    </div>
                                    <div className='order-amount'>
                                        <p>Total Amount: </p>
                                        <h5>{addCommas(orderItem.data.order_total_amount.toString())}<span> Ugx</span></h5>
                                    </div>
                                    <div className='order-item-number'>
                                        <p>Item Number: </p>
                                        <h5>{orderItem.data.products.length}</h5>
                                    </div>
                                    <div className='order-status'>
                                        <p>Status: </p>
                                        <h5>
                                            {
                                                orderItem.data.pending && !orderItem.data.assigned && !orderItem.data.completed && !orderItem.data.cancelled && !orderItem.data.deliverer_assigned && !orderItem.data.delivering ?
                                                    'Order Received'
                                                : orderItem.data.assigned && !orderItem.data.pending && !orderItem.data.completed && !orderItem.data.cancelled && !orderItem.data.deliverer_assigned && !orderItem.data.delivering ?
                                                    'Order Packaged'
                                                : (orderItem.data.deliverer_assigned || orderItem.data.delivering) && !orderItem.data.pending && !orderItem.data.completed && !orderItem.data.cancelled ?
                                                    'On the way'
                                                : orderItem.data.completed && !orderItem.data.pending && !orderItem.data.assigned && !orderItem.data.cancelled && !orderItem.data.deliverer_assigned && !orderItem.data.delivering ?
                                                    'Delivered'
                                                :
                                                    ''
                                            }
                                        </h5>
                                    </div>
                                </div>
                                <div className='order-tracker'>
                                    <div className='order-bar-div'>
                                        <div className={
                                            orderItem.data.pending || orderItem.data.assigned || orderItem.data.deliverer_assigned || orderItem.data.delivering || orderItem.data.completed ?
                                                'order-bar-active' : 'order-bar'
                                        }></div>
                                        <div className={
                                            orderItem.data.pending || orderItem.data.assigned || orderItem.data.deliverer_assigned || orderItem.data.delivering || orderItem.data.completed ?
                                                'order-bar-icon-active' : 'order-bar-icon'
                                        }>
                                            <CheckIcon fontSize='medium' />
                                        </div>
                                        <div className='order-bar-status'>
                                            <p>Order Confirmed</p>
                                        </div>
                                    </div>
                                    <div className='order-bar-div'>
                                        <div className={
                                            orderItem.data.assigned || orderItem.data.deliverer_assigned || orderItem.data.delivering || orderItem.data.completed ?
                                                'order-bar-active' : 'order-bar'
                                        }></div>
                                        <div className={
                                            orderItem.data.assigned || orderItem.data.deliverer_assigned || orderItem.data.delivering || orderItem.data.completed ?
                                                'order-bar-icon-active' : 'order-bar-icon'
                                        }>
                                            <LocalPostOfficeIcon fontSize='medium' />
                                        </div>
                                        <div className='order-bar-status'>
                                            <p>Order Packaged</p>
                                        </div>
                                    </div>
                                    <div className='order-bar-div'>
                                        <div className={orderItem.data.deliverer_assigned || orderItem.data.delivering || orderItem.data.completed ?
                                            'order-bar-active' : 'order-bar'
                                        }></div>
                                        <div className={
                                            orderItem.data.deliverer_assigned || orderItem.data.delivering || orderItem.data.completed ?
                                                'order-bar-icon-active' : 'order-bar-icon'
                                        }>
                                            <MopedOutlinedIcon fontSize='medium' />
                                        </div>
                                        <div className='order-bar-status'>
                                            <p>On the way</p>
                                        </div>
                                    </div>
                                    <div className='order-bar-div'>
                                        <div className={orderItem.data.completed ? 'order-bar-active' : 'order-bar'}></div>
                                        <div className={orderItem.data.completed ? 'order-bar-icon-active' : 'order-bar-icon'}>
                                            <AllInboxIcon fontSize='medium' />
                                        </div>
                                        <div className='order-bar-status'>
                                            <p>Delivered</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='order-items'>
                                    {
                                        orderItem.data.products.map((orderProduct, index) => {
                                            
                                            return (
                                                <div key={index} className='order-product'>
                                                    {/* Render order product details */}
                                                    {
                                                        orderItem.data.products.map((orderProduct, index)=>{
                                                            // console.log('LOLOLO>>>', orderProduct)
                                                            return(
                                                                <Paper elevation={1} className='order-item-card' key={orderProduct.id}>
                                                                    <div className='order-item-img'>
                                                                        {
                                                                            orderProduct.image !== '' ?
                                                                                <img src={orderProduct.image} alt=''/>
                                                                            :
                                                                                <img src={images.drugs} alt='' style={{width:'70px', margin:'10px 0px 0px 10px'}}/>
                                                                        }
                                                                    </div>
                                                                    <div className='order-item-detail'>
                                                                        <p id="order-item-name">{orderProduct.name}</p>
                                                                        <p>{orderProduct.price ? `${addCommas(orderProduct.price.toString())} Ugx` : 'Reload page to view'}</p>
                                                                        <p>{orderProduct.quantity} {orderProduct.units}(s)</p>
                                                                    </div>
                                                                </Paper>           
                                                            )
                                                        })
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Paper>
                        );
                    })
            }
        </div>
        <Dialog
            onClose={handleClose}
            open={open}
            aria-labelledby='order-delete'
            aria-describedby='order-delete'
            className='order-delete-dialog'
        >
            <Paper elevation={2} className='order-delete-card'>
                <div className='order-delete-icon-div'>
                    <WarningAmberOutlinedIcon style={{color:'orange'}} fontSize='large'/>
                </div>
                <div className='order-delete-div'>
                    {
                        orderDeleted && !orderDeleteFailed ?
                            <p>Order cancelled successfully.</p>
                        : orderDeleteFailed && !orderDeleted?
                            <p>Operation Failed. Try again</p>
                        :
                            <>
                                <p>Are you sure you want to cancel order</p>
                                <h5>{orderNumber} ?</h5>
                            </>
                    }
                </div>
                <div className='order-delete-action'>
                    {
                        orderDeleted ?
                            <></>
                        :
                            <>
                                <Button color='error' onClick={()=>{
                                    setOpen(false)
                                    setOrderNumber('')
                                }}>
                                    <CloseOutlinedIcon/> No
                                </Button>
                                <Button color='success' onClick={()=>cancelOrder(orderId)}>
                                    <CheckIcon/> Yes
                                </Button>
                            </>
                    }
                </div>
            </Paper>
        </Dialog>
    </>
  )
}


export default OrderTransactions