import React, { useState } from "react";
import '../assets/css/blog.css';
import { getAllBlogPosts, getAllBlogCategories, addToBookmarks } from "../store/blog_slice";
import { useDispatch } from "react-redux";
import { IconButton, Paper, Tooltip, Dialog } from "@mui/material";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import BreadCrumb from "../components/breadCrumb";
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check';
import { convertEpochToDatetime } from "../utils";
import images from "../assets";


export default function BlogHome(){
  const dispatch = useDispatch()
  const [blogCategories, setBlogCategories] = React.useState([]);
  const [blogPosts, setBlogPosts] = React.useState([]);
  const [loading, setLoading] = React.useState(false)
  const [notLoggedIn, setNotLoggedIn] = React.useState(false)
  const [addedSuccessfully, setAddedSuccessfully] = useState(false)
  const numbers = Array.from({ length: 10 }, (_, index) => index);
  const uid = localStorage.getItem('user_id')
  const isLoggedIn = localStorage.getItem('isLoggedIn')


  React.useEffect(()=>{
    setLoading(true)
    dispatch(getAllBlogPosts())
    .then(res=>{
      if(res['meta']['requestStatus'] === 'fulfilled'){
        setBlogPosts(res.payload)
      }
    })
    .catch(error=>console.log('An error occurred'))

    dispatch(getAllBlogCategories())
    .then(res=>{
      if(res['meta']['requestStatus'] === 'fulfilled'){
        setBlogCategories(res.payload)
      }
    })
    .catch(error=>{console.log('An error occured.')})
    setLoading(false)
  },[blogPosts])

  const addBookmark = (bkmrk)=>{
    dispatch(addToBookmarks(bkmrk))
    .then((res)=>{
      if(res['meta']['requestStatus'] === 'fulfilled'){
        setAddedSuccessfully(true)
      }
      else if(res['meta']['requestStatus'] === 'rejected'){

      }

    })
    .catch(error=>console.log('An error occurred'))

    setTimeout(()=>{
      setAddedSuccessfully(false);
    },2000)
  }

  const handleClose = ()=>{
    setNotLoggedIn(false)
  }


  return(
    <>
      <div className="blog-wrapper">
        {/* <div className="blog-categories">
          <div className="category-title"><h4>Blog Categories.</h4></div>
          {
            loading ?
              numbers.map((num,index)=>{
                return(
                  <BlogCategoryLoadingSkeleton key={num}/>
                )
              })
            :
              blogCategories.map((element, index)=>{
                return(
                  <div className="blog-category-element" key={element.id}>
                    {element.name}
                  </div>
                )
              })
          }
        </div>
        <div className="mob-blog-cattegories">

        </div> */}
        <div className="blog-area">
          <BreadCrumb crumbs={[{text:'blog', url:'/blog'}]}/>
          {
            loading ?
              numbers.map((num,index)=>{
                return(
                  <BlogLoadingSkeleton key={num}/>
                )
              })
            :
              blogPosts.map((element, index)=>{
                const date = new Date(element.time)
                let year = date.getFullYear()
                let day = date.getDate()
                let month = date.getMonth()
                return(
                  <Paper elevation={2} key={element.id} className="blog-paper" style={{marginTop:'20px'}}>
                    <div className="blog-date">
                      {/* <p>{ day < 10 ? '0' + day : day }/{ month < 10 ? '0' + month : month }/{ year }</p> */}
                      <p>{convertEpochToDatetime(element.time)}</p>
                    </div>
                    <div className="blog-title">
                      <img src={element.images[0] ? element.images[0] : images.image_gallery} alt=''/>
                      <div className="blog-text">
                        <h3>{element.title}</h3>
                        <div className="blog-body">
                          <p>{element.body}</p>
                        </div>
                        <div className="blog-actions">
                          <NavLink to={{pathname:`/blog/${element.title}`}}>
                            view more
                          </NavLink>
                          {
                            addedSuccessfully ?
                              <div style={{display:'flex', width:'auto'}}>
                                <CheckIcon/>
                                'Added to bookmarks'
                              </div>
                            :
                              <Tooltip title="Add Bookmark">
                                <IconButton onClick={()=>{
                                  if(isLoggedIn){
                                    const bk = {
                                      "item_id": element.id,
                                      "item_type": "post",
                                      "products": element.products,
                                      "time": Date.now(),
                                      "user": uid
                                    }
                                    
                                    addBookmark(bk)
                                  }
                                  else{
                                    setNotLoggedIn(true)
                                  }
                                }}>
                                  <BookmarkAddOutlinedIcon fontSize="large"/>
                                </IconButton>
                              </Tooltip>
                          }
                        </div>
                      </div>
                    </div>
                  </Paper>
                )
              })
          }
        </div>
      </div>
      <Dialog open={notLoggedIn} onClose={handleClose}>
        <div className="blogNotloggedIn">
          <IconButton
            onClick={()=>{
              setNotLoggedIn(false)
            }}
          >
            <CloseIcon/>
          </IconButton>
        </div>
        <div className="blogNotLoggedInIcon">
          <WarningAmberIcon fontSize='large' style={{color:'orange'}}/>
        </div>
        <div className="blogNotLoggedInDiv">
          You are not logged in. 
          <NavLink to='/signin'>
            Sign in here
          </NavLink>
        </div>
      </Dialog>
    </>
  );
}

const BlogLoadingSkeleton = ()=>{
  return(
    <Skeleton style={{width:'650px', height:'250px'}}/>
  )
}
 
const BlogCategoryLoadingSkeleton = ()=>{
  return(
    <Skeleton style={{width:'170px', height:'40px'}}/>
  )
}