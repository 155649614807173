import React from 'react'
import '../assets/css/components.css'
import images from '../assets'

function Banner(props) {
  const handleClick = () => {
    const url = `https://wa.me/${props.phoneNumber}?text=${encodeURIComponent(props.message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className='banner-div' onClick={handleClick}>
        <img src={images.ctadsk} alt='' className='ctadsk'/>
        <img src={images.ctamob} alt='' className='ctamob'/>
        <img src={images.ctaipd} alt='' className='ctaipd'/>
    </div>
  )
}

export default Banner