import React from 'react'

function NoInternet() {
  return (
    <div>
        <p>No Internet Connection</p>
    </div>
  )
}

export default NoInternet