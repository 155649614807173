import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { db } from '../firebase'
import { collection, getDocs, query, where, getDoc, doc, addDoc, updateDoc, deleteDoc } from 'firebase/firestore'


const blog_posts = collection(db, 'posts')
const blog_categories = collection(db, 'post_categories')
const userCol = collection(db, 'users')
const commentsCol = collection(db, 'comments')

export const getAllBlogPosts = createAsyncThunk('posts', async()=>{
    try {
        const postsSnapShot = await getDocs(blog_posts);
        const posts = postsSnapShot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        return posts

    } catch (error) {
        throw error;
    }
})

export const getAllBlogCategories = createAsyncThunk('posts/categories', async()=>{
    try {
        const postsCategoriesSnapShot = await getDocs(blog_categories)
        const categories = postsCategoriesSnapShot.docs.map(doc=>doc.data())

        return categories;

    } catch (error) {
        throw error;
    }
})


export const getBlogPost = createAsyncThunk('post/get', async(blog_title)=>{
    try {
        const q = query(blog_posts, where('title', '==', blog_title))
        const blogPostSnapShot = await getDocs(q)
        const blog = blogPostSnapShot.docs.map((doc)=>{
            doc.data()
            return {
                post_id:doc.id,
                post:doc.data()
            }
        })

        return blog;

    } catch (error) {
        throw error;
    }
})

export const getBloggerById = createAsyncThunk('auth/get', async(id)=>{
    try {
      const userDoc = await getDoc(doc(userCol, id))
  
      return userDoc.data();
      
    } catch (error) {
      throw error;
    }
})


export const getBlogComments = createAsyncThunk('posts/comments', async(id)=>{
    try {
        const q = query(commentsCol, where('thing_id', '==', id))
        const commentSnapShot  = await getDocs(q)
        const comments = commentSnapShot.docs.map((doc)=>doc.data())

        return comments

    } catch (error) {
        throw error;
    }
})

export const addToBookmarks = createAsyncThunk('posts/add-bookmark', async(post)=>{
    try {
        addDoc(blog_posts, post)
        
    } catch (error) {
        throw error;
    }
})

export const addBlogComment = createAsyncThunk('blog/add-comment', async(comment)=>{
    try {
        addDoc(commentsCol, comment)
    } catch (error) {
        throw error;
    }
})

export const updateComment = createAsyncThunk('blog/update-comment', async(params)=>{
    try {
        const q = query(commentsCol, 
            where("sender.id", "==", params.id),
            where("thing_id", "==", params.t_id),
            where("thing_type", "==", "post")
        )
        const querySnapshot = await getDocs(q)

        if (querySnapshot.docs.length > 0) {
            
            const id = querySnapshot.docs[0].id;
            const docRef = doc(commentsCol, id)
  
            await updateDoc(docRef, {
              text: params.text
            });
  
            return null;
          } 

    } catch (error) {
        throw error;
    }
})

export const deleteComment = createAsyncThunk('blog/delete-comment', async(params)=>{
    try {
        let id = null;
        const q = query(commentsCol, 
            where("sender.id", "==", params.id),
            where("thing_id", "==", params.t_id),
            where("thing_type", "==", "post")
        )
        const querySnapshot = await getDocs(q)

        querySnapshot.docs.map((doc)=> id = doc.id)

        const docRef = doc(commentsCol, id);

        await deleteDoc(docRef)
    } catch (error) {
        throw error;
    }
})

const blog_slice = createSlice({
    name:'blog',
    initialState:{
        isLoading: false,
        postsList: null,
        postsCategories: null,
        error: null,
        blogPost: null,
        bloggerProfile: null,
        comments: null
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllBlogPosts.pending, (state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getAllBlogPosts.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.postsList = action.payload
            state.error = null
        })
        .addCase(getAllBlogPosts.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(getAllBlogCategories.pending, (state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getAllBlogCategories.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.postsCategories = action.payload
            state.error = null
        })
        .addCase(getAllBlogCategories.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(getBlogPost.pending, (state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getBlogPost.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.blogPost = action.payload
            state.error = null
        })
        .addCase(getBlogPost.rejected, (state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getBloggerById.pending, (state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getBloggerById.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.bloggerProfile = action.payload;
            state.error = null
        })
        .addCase(getBloggerById.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(getBlogComments.pending, (state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getBlogComments.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.comments = action.payload
            state.error = null
        })
        .addCase(getBlogComments.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(addToBookmarks.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(addToBookmarks.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null;
            
        })
        .addCase(addToBookmarks.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
        })
        .addCase(addBlogComment.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(addBlogComment.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null;            
        })
        .addCase(addBlogComment.rejected, (state, action)=>{
            state.isLoading = false;
            state.error= action.error.message
        })
        .addCase(updateComment.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(updateComment.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null;            
        })
        .addCase(updateComment.rejected, (state, action)=>{
            state.isLoading = false;
            state.error= action.error.message
        })
        .addCase(deleteComment.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(deleteComment.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null;            
        })
        .addCase(deleteComment.rejected, (state, action)=>{
            state.isLoading = false;
            state.error= action.error.message
        })
    }
})


export const blogActions = blog_slice.actions;
export default blog_slice;