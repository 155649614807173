import React, {useEffect} from 'react'
import { Paper, Button } from '@mui/material'
import images from '../../assets/index.js'
import './searchProductCard.css';
import { NavLink } from 'react-router-dom'
import { addCommas, capitalizeProductName } from '../../utils.js'
import { closeSearchDialog } from '../../store/searchDialog_slice.js';
import { useDispatch } from 'react-redux';

function SearchProductCard(props) {
    const dispatch = useDispatch();

    const handleClose = ()=>{
        dispatch(closeSearchDialog())
    }
  return (
    <NavLink
        to={`/${props.title}/${props.id}/details`}
        className='search-product-link'
    >    
        <Paper elevation={2} className='search-pdt-card' onClick={handleClose}>
            <div className='search-pdt-img'>
                {
                    props.image === 'no_image' ?
                        <img src={images.drugs} alt=''/>
                    :
                        <img src={props.image} alt=''/>
                }
            </div>
            <div className='search-pdt-detail'>
                <p id="search-pdt-title">{capitalizeProductName(props.title, ' ')}</p>
                <p id="search-pdt-manufacturer">by {props.manufacturer}</p>
                {
                    props.isFlashSale ?
                        <div className='search-pdt-dsctd'>
                            <p id="search-pdt-orig-price">{addCommas(props.price.toString())} Ugx.</p>
                            <p id="search-pdt-new-price">{addCommas(props.discount_price.toString())} Ugx.</p>
                        </div>
                    :
                        <p id="search-pdt-new-price">{addCommas(props.price.toString())} Ugx.</p>
                }
            </div>
        </Paper>
    </NavLink>
  )
}

export default SearchProductCard