import React,{useEffect, useState} from 'react'
import { storage } from '../firebase'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import images from '../assets/index'
import { useDispatch, useSelector } from 'react-redux'
import '../assets/css/productDetails.css'
import {
  Button,
  CircularProgress,
  Paper,
  Rating,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton, 
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions
} from '@mui/material';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { getCategoryProductsAsync, getProductDetails } from '../store/products_slice';
import {useForm} from 'react-hook-form';
import { createCartItemAsync } from '../store/cart_slice'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import BreadCrumb from '../components/breadCrumb';
import { addCommas, convertEpochToDatetime, quantityText} from '../utils';
import { addItemToWishListAsync } from '../store/wishlist_slice';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { Carousel } from 'react-responsive-carousel';
import { addReview, getReviews } from '../store/reviews_slice';
import { styled } from '@mui/material/styles';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const SimilarProductCard = React.lazy(()=>import('../components/similarProduct'));

function ProductDetails(props) {
  const {pathname} = useLocation()
  const {id} = useParams();
  const dispatch = useDispatch();
  const {register, handleSubmit} = useForm()
  

  const [product, setProduct] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isAddingToWishList, setIsAddingToWishList] = useState(false)
  const [productPrice, setProductPrice] = useState(0)
  const [originalPrice, setOriginalPrice] = useState(0)
  const [quantity, setQuantity] = useState(1)
  const [userEmail, setUserEmail] = useState(null);
  const [productWarning, setProductWarning] = useState([])
  const [unit, setUnit] = useState(null)
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [isAdding, setIsAdding] = useState(false)
  const [noData, setNoData] = useState(false)
  const [productId, setProductId] = useState('')
  const [reviews, setReviews] = useState([])
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState(0)
  const [comment, setComment] = useState('')
  const [isOtc, setIsOtc] = useState(false)
  const [isPrescription, setIsPrescription] = useState(false)
  const [isControlled, setIsControlled] = useState(false)
  const [isFixed, setIsFixed] = useState(true);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [addPrescToCartSuccess, setAddPrescToCartSuccess] = useState(false);
  const [uploadFileSuccess, setUploadFileSuccess] = useState(false);
  const [url, setUrl] = useState('')
  const [progress, setProgress] = useState(0);


  const user = useSelector(state => state.auth.user)
  const user_id = useSelector(state => state.auth.id)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const allProducts = useSelector(state => state.products.products)
  const allReviews = useSelector(state => state.reviews.productReviewsList)
  // const similarProducList = useSelector(state => state.product)

  const localLoggedIn = localStorage.getItem('isLoggedIn')
  const localEmail = localStorage.getItem('email')
  const localUID = localStorage.getItem('user_id')
  const localName = localStorage.getItem('name')


  const handleOnSubmit = (data)=>{
    setIsAdding(true)
    if(isLoggedIn || localLoggedIn){
      const currentDate = new Date()
      
      data['total_price']=productPrice
      data['product'] = {
        id:productId,
        name: product.name,
        price: product.b2c_price,
        quantity: parseInt(quantity),
        units:product.units_of_measure,
        image:product.images.length > 0 ? product.images[0] : ''
      }
      data['date_created'] = currentDate.getTime()
      data['owner_email'] = userEmail
      data['is_order'] = false
      data['owner_id'] = user_id ? user_id : localUID

      dispatch(createCartItemAsync(data))
      .then((res)=>{
        if(res['meta']['requestStatus'] === 'fulfilled'){
          setShowAlert(true)
          setAlertMessage(res.payload.message)
          setAlertVariant(res.payload.variant)
        }

        setIsAdding(false)
      })
      .catch((error)=>console.log("An error occurred"))
  
      
      setTimeout(()=>{
        setShowAlert(false)
        setAlertMessage('')
        setAlertVariant('')
      }, 3000)
    }
    else if(!isLoggedIn || !localLoggedIn){

      let cart = localStorage.getItem('cart')
      cart = cart ? JSON.parse(cart) : []

      let cartNum = parseInt(localStorage.getItem('cartNum'))
      let cartTotal = parseInt(localStorage.getItem('cartTotal'))

      const data = {}
      const currentDate = new Date()

      data['total_price']=productPrice
      data['product'] = {
        id:productId,
        name: product.name,
        price: product.b2c_price,
        quantity: parseInt(quantity),
        units:product.units_of_measure,
        image:product.images.length > 0 ? product.images[0] : ''
      }
      data['date_created'] = currentDate.getTime()
      data['owner_email'] = userEmail
      data['is_order'] = false
      data['owner_id'] = user_id ? user_id : localUID


      
      cart.push(data)


      const updatedCart = JSON.stringify(cart)
      const newCartNum = cart.length;
      const newCartTotal = cartTotal + productPrice

      localStorage.setItem('cart', updatedCart)
      localStorage.setItem('cartNum', newCartNum)
      localStorage.setItem('cartTotal', newCartTotal)

      setShowAlert(true)
      setAlertMessage('Added to Cart')
      setAlertVariant('success')

      setIsAdding(false)

      setTimeout(()=>{
        setShowAlert(false)
        setAlertMessage('')
        setAlertVariant('')
      }, 3000)
    }
  }

  const addToWishList = ()=>{

    if(isLoggedIn || localLoggedIn){
      const currentDate = new Date()
      const w = {
        item_id: id,
        item_type: "product",
        products: [id],
        time: currentDate.getTime(),
        user: user_id ? user_id : localUID
      }      

      dispatch(addItemToWishListAsync(w))
      .then((res)=>{
        setIsAddingToWishList(true)
        if(res['meta']['requestStatus'] === 'fulfilled'){
          setShowAlert(true)
          setAlertMessage(res.payload.message)
          setAlertVariant(res.payload.variant)
        }
        setIsAddingToWishList(false)
      })
      .catch((error)=>console.log("An error occurred"))
  

      setTimeout(()=>{
        setShowAlert(false)
        setAlertMessage('')
        setAlertVariant('')
      }, 3000)
      setIsAddingToWishList(false)
    }
    else if(!isLoggedIn || !localUID){
      setShowAlert(true)
      setAlertMessage('You are not logged in. Item was not added to wishlist!')
      setAlertVariant('error')
      setIsAdding(false)
      setTimeout(()=>{
        setShowAlert(false)
        setAlertMessage('')
        setAlertVariant('')
      }, 3000)
    }
  };

  const handleClickOpen = ()=>{
    setOpen(true)
  }

  const handleClose = ()=>{
    setOpen(false)
  }

  const onSubmitReview = ()=>{
    const currentDate = new Date()

    const review = {
      comment: comment,
      rating: value,
      reviewer: {
        id: localUID,
        name: localName ? localName : 'unknown user',
        email: localEmail,
        img: ''
      },
      thing_id: productId,
      thing_type: 'product',
      time: currentDate.getTime()
    }

    dispatch(addReview(review))
    .then(res => {
      if(res['meta']['requestStatus'] === 'pending'){
      }
      else if(res['meta']['requestStatus'] === 'fulfilled'){

        setValue(0);
        setComment('')
        setOpen(false);
      }
      else if(res['meta']['requestStatus'] === 'rejected'){

        console.log('An error occurred')
      }
    })
    .catch(error => console.log('An error occurred.'))

    setValue(0);
    setComment('')
    setOpen(false)
  }

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10);
    if (newQuantity > 0) {
      setQuantity(newQuantity);
      setProductPrice(originalPrice * newQuantity);
    }
  };

  const handleUploadChange = (e) => {
    if (e.target.files) {
      const fileList = e.target.files;

      const filesArr = Array.from(fileList)

      setFile(filesArr);
    }
  };

  const MAX_CONCURRENT_UPLOADS = 6;

  const handleUpload = async (e) => {
    e.preventDefault();
  
    setUploading(true);
  
    if (file.length > 0) {
      const currentDate = new Date();
      const URLArr = [];
  
      const uploadFile = (f) => {
        return new Promise((resolve, reject) => {
          const storageRef = ref(storage, `orders/${f.name}`);
          const uploadTask = uploadBytesResumable(storageRef, f);
  
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              console.log('Upload Progress:', percent);
            },
            (error) => {
              console.error('Upload failed.');
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((downloadURL) => {
                  URLArr.push(downloadURL);
                  resolve(downloadURL);
                })
                .catch((error) => {
                  console.error('Failed to get download URL.');
                  reject(error);
                });
            }
          );
        });
      };
  
      const uploadBatches = async (files, batchSize) => {
        for (let i = 0; i < files.length; i += batchSize) {
          const batch = files.slice(i, i + batchSize);
          await Promise.all(batch.map(uploadFile));
        }
      };
  
      try {
        await uploadBatches(file, MAX_CONCURRENT_UPLOADS);
        setUploadFileSuccess(true);
        setUploading(false);
        setFile(null);
  
        const data = {
          'total_price': originalPrice,
          'product': {
            "id": productId,
            "name": product.name,
            "price": product.b2c_price,
            "quantity": parseInt(quantity),
            "units": product.units_of_measure,
            "image": product.images.length > 0 ? product.images[0] : ''
          },
          'prescription_pictures': URLArr,
          'date_created': currentDate.getTime(),
          'owner_email': userEmail,
          'is_order': false,
          'owner_id': user_id ? user_id : localUID
        }
  
        const res = await dispatch(createCartItemAsync(data));
        if (res.meta.requestStatus === 'fulfilled') {
          setAddPrescToCartSuccess(true);
  
          setTimeout(() => {
            setAddPrescToCartSuccess(false);
            setUploadFileSuccess(false);
          }, 3000);
        }
  
        setTimeout(() => {
          setAddPrescToCartSuccess(false);
          setUploadFileSuccess(false);
        }, 3000);
      } catch (error) {
        console.error('One or more uploads failed:', error);
      }
  
      setTimeout(() => {
        setUploadFileSuccess(false);
      }, 3000);
    }
  };

  
  
  useEffect(()=>{
    // window.scrollTo(0,0)
    if(user !== null){
      setUserEmail(user.email)
    }

    if(allProducts !== null){
      const pDetail = allProducts.filter(product => product.id === id.trim());

      if(pDetail[0] !== undefined){
        let sideEffects = []
        setProductId(pDetail[0].id)
        setProduct(pDetail[0])
        // setProductPrice(pDetail[0].b2c_price)
        setOriginalPrice(pDetail[0].b2c_price)
        setUnit(pDetail[0].units_of_measure)

        if(pDetail[0].dispensation === 'prescription'){
          setIsPrescription(true)
        }

        if(pDetail[0].dispensation === 'otc'){
          setIsOtc(true)
        }

        if(pDetail[0].dispensation === 'controlled'){
          setIsControlled(true)
        }

        if(pDetail[0].adverse_reactions !== null){
          sideEffects.push(pDetail[0].adverse_reactions)
        }
  
        if(pDetail[0].precautions !== null){
          sideEffects.push(pDetail[0].precautions)
        }
  
        if(pDetail[0].contra_indications !== null){
          sideEffects.push(pDetail[0].contra_indications)
        }
  
        setProductWarning(sideEffects)
        setIsLoading(false)

        dispatch(getReviews(pDetail[0].id))
        .then(res =>{

          if(res['meta']['requestStatus'] === 'fulfilled'){
            setReviews(res.payload)
          }
        })

      }
      else{
        dispatch(getProductDetails(id))
        .then((res)=>{
    
          if(res['meta']['requestStatus'] === 'fulfilled' && res.payload[0] !== undefined){
            let sideEffects = []
            setProductId(res.payload[0].id)
            setProduct(res.payload[0])
            setProductPrice(res.payload[0].b2c_price)
            setOriginalPrice(res.payload[0].b2c_price)
            setUnit(res.payload[0].units_of_measure)

            if(res.payload[0].dispensation === 'prescription'){
              setIsPrescription(true)
            }
    
            if(res.payload[0].dispensation === 'otc'){
              setIsOtc(true)
            }
    
            if(res.payload[0].dispensation === 'controlled'){
              setIsControlled(true)
            }
            
            if(res.payload[0].adverse_reactions !== null){
              sideEffects.push(res.payload[0].adverse_reactions)
            }
    
            if(res.payload[0].precautions !== null){
              sideEffects.push(res.payload[0].precautions)
            }
    
            if(res.payload[0].contra_indications !== null){
              sideEffects.push(res.payload[0].contra_indications)
            }
    
            setProductWarning(sideEffects)
            setIsLoading(false) 
    
          }
          else{
            setNoData(true)
          }
        })
      }

      if(allReviews !== null){
        const reviews = allReviews.filter(review => review.thing_id === id.trim());

        if(reviews !== undefined){
          setReviews(reviews)
        }
      }
    }
    else{
      dispatch(getProductDetails(id))
      .then((res)=>{
  
        if(res['meta']['requestStatus'] === 'fulfilled' && res.payload[0] !== undefined){
          let sideEffects = []
          setProductId(res.payload[0].id)
          setProduct(res.payload[0])
          // setProductPrice(res.payload[0].b2c_price)
          setOriginalPrice(res.payload[0].b2c_price)
          setUnit(res.payload[0].units_of_measure)

          if(res.payload[0].dispensation === 'prescription'){
            setIsPrescription(true)
          }
  
          if(res.payload[0].dispensation === 'otc'){
            setIsOtc(true)
          }
  
          if(res.payload[0].dispensation === 'controlled'){
            setIsControlled(true)
          }

          if(res.payload[0].adverse_reactions !== null){
            sideEffects.push(res.payload[0].adverse_reactions)
          }
  
          if(res.payload[0].precautions !== null){
            sideEffects.push(res.payload[0].precautions)
          }
  
          if(res.payload[0].contra_indications !== null){
            sideEffects.push(res.payload[0].contra_indications)
          }
  
          setProductWarning(sideEffects)
          setIsLoading(false) 
  
        }
        else{
          setNoData(true)
        }
      })

      dispatch(getReviews(id))
      .then(res =>{
        if(res['meta']['requestStatus'] === 'fulfilled'){
          setReviews(res.payload)
        }
      })
      .catch(error=>{
        console.log('Error occured')
      })
    }
    
    const handleScroll = () => {
      const stickyDiv = document.querySelector('.sticky-div');
      const footer = document.querySelector('.stopDiv');

      if (!stickyDiv || !footer) return;

      const stickyDivRect = stickyDiv.getBoundingClientRect();
      const footerRect = footer.getBoundingClientRect();

      if (stickyDivRect.bottom >= footerRect.top) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };


  },[pathname, id, user, reviews, productPrice, allProducts])

  return (  
    <>
    <div className='detail-container'>
      <BreadCrumb
        crumbs={[
          {text:product.category_name, url:`/${product.category}/${product.category_name}/products`},
          {text: product.name, url:''}
        ]}/>
      {showAlert && <Alert severity={alertVariant} variant='filled' style={{borderRadius:'0px'}}>{alertMessage}</Alert>}
      <div className={`sticky-div ${isFixed ? 'fixed' : ''}`}>
        {
          isOtc && !isPrescription && !isControlled ?
            <Paper elevation={3} className='cart-add-paper'>
              <form className='cart-add-form' onSubmit={handleSubmit(handleOnSubmit)}>
                <div className='cart-add-form-inputs'>
                  <div className='quantity-div'>
                    <p className="qauntity-label">{quantityText}: </p> 
                    <input 
                      type='number'
                      min='1'
                      className='quantity-input'
                      placeholder='0'
                      {...register('quantity',)}
                      onChange={handleQuantityChange}
                    />
                  </div>
                  <div className='price-div'>
                    <h4 className='price-label'>
                      = {addCommas(productPrice.toString())} <span style={{fontWeight:'bold', fontSize:'15px'}}>Ugx.</span></h4>
                  </div>
                </div>
                <div className='cart-add-button'>
                  {
                                        
                    showAlert ? 
                        <Alert
                          severity={alertVariant}
                          variant='filled'
                          style={{borderRadius:'0px'}}
                          className='mob-add-tocart-alert'
                          >
                            {alertMessage}
                        </Alert>
                    :
                      <Button
                        variant='contained'
                        color='error'
                        className='add-to-cart-btn'
                        type='submit'
                        size='small'
                        >
                          {!isAdding ? <><AddShoppingCartIcon/> {'Add to Cart'}</> : <CircularProgress size={25}/>}
                      </Button>
                  }
                </div>
              </form>

            </Paper>
          : (isPrescription && !isOtc && !isControlled) ?
            <Paper>
              {
                uploading ?
                  <div className="cart-add-progress">
                    <CircularProgress size="20px"/>
                    <h5>uploading your prescription, this may take a while....</h5>
                  </div>
                : ( uploadFileSuccess ) ?
                    <div className='cart-add-pres-success'>
                      <TaskAltOutlinedIcon fontSize="large"/>
                      <h5>your prescription has been recieved. Adding product to your cart</h5>
                    </div>
                : (addPrescToCartSuccess) ?
                  <div className='cart-add-success'>
                    <TaskAltOutlinedIcon fontSize="large"/>
                    <h5>Successfully added to cart.</h5>
                  </div>
                :
                  <form className='cart-add-form'>
                    <div className='prescription-upload'>
                      This product requires a prescription from a health worker/facility.<br/>
                      <NavLink to='terms&conditions'>Terms & Conditions</NavLink>
                    </div>
                    <div className='cart-browse-presc'>
                      <input type='file' onChange={handleUploadChange} id="upload-file" style={{ display: 'none' }} multiple/>
                      { !uploading ? <p className='cart-browse-upd-name'>{file && file.map((f, index)=><span key={index}>{f.name}, </span>)}</p> : <></>}
                      {
                        !file &&
                          <label htmlFor="upload-file">
                            <Button
                              component="span"
                              role={undefined}
                              variant="contained"
                              tabIndex={-1}
                              startIcon={<DriveFolderUploadOutlinedIcon/>}
                              color="primary"
                            >
                              Browse File
                            </Button>
                          </label>
                      }
                      {
                        file &&
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<FileUploadIcon />}
                            onClick={handleUpload}
                            color='success'
                          >
                            Upload prescription
                            <VisuallyHiddenInput type="file" />
                          </Button>

                      }
                    </div>
                  </form>
              }
            </Paper>
          : (isControlled && !isOtc && !isPrescription) ?
            <Paper>
              <form className='cart-add-form'>
                <div className='controlled-upload-div'>
                  <WarningAmberIcon className='warning-icon' fontSize='large'/>
                  <div className='controlled-upload'>
                    This is a controlled product. 
                    Contact us directly on how to purchase.<br/>
                    <NavLink>Terms & Conditions</NavLink>
                  </div>
                </div>
              </form>
            </Paper>
          :
            <></>
        }
      </div>
      <div className='details-div'>
        <div className='product-preview'>
          <Carousel>
            {
              product.images !== undefined && product.images.length ?
                product.images.map((item, index)=>{
                  return(
                    <div style={{height:'500px'}} key={index}>
                      <img src={item} alt='' style={{objectFit:'fill', height:'100%'}}/>
                    </div>
                  )
                })
              :
                <div>
                  <img src={images.drugs} alt='' />
                </div>
            } 
          </Carousel>
        </div>
        <div className='product-detail'>
          {
            isLoading ?
              <div className='product-loading'>
                <CircularProgress/>
              </div>
            :
            <>
              <h1>{product.name}</h1>
              <p className='detail-manufacturer'>by {product.manufacturer_name}</p>
              <div className='detail-stock'>
                <img src={images.approve} alt=''/>  
                In Stock
              </div>
              <div className='rating-div'>
                <Rating value={4} readOnly style={{color:'teal'}}/>
                <p>4.0</p>
              </div>
              <div className='detail-delivery'>
                <img src={images.fast_delivery} alt=""/>
                <p >same day delivery: Kampala, Mukono, Jinja...</p>
              </div>
              <div className='detail-price'>
                <h3>Ugx. {addCommas(originalPrice.toString())}<span id='detail-price-unit'> per {unit}</span></h3>
              </div>

              {
                isOtc && !isPrescription && !isControlled ?
                  <div style={{marginTop:'20px'}} className='detail-wishlist'>
                    <Typography variant="h5" className='mob-quantity-label'>
                      Quantity:
                    </Typography>
                    <div className='mob-quantity-form-div'>
                      <img
                          src={images.minus}
                          alt=''
                          onClick={()=>{
                            if(quantity === 1){

                            }
                            else{
                              let q = quantity - 1;
                              const new_product_price = originalPrice * q
                              setProductPrice(new_product_price)
                              setQuantity(q)
                            }
                          }}
                        />
                      <Typography variant="h5">
                        {quantity}
                      </Typography>
                      <img
                        src={images.add}
                        alt=''
                        onClick={()=>{
                          let q = quantity + 1;
                          const new_product_price = originalPrice * q
                          setProductPrice(new_product_price)
                          setQuantity(q)
                        }}
                      />
                    </div>
                    <div className='detail-buttons-div'>
                      <Button variant='contained' color='error' onClick={()=>handleOnSubmit({})} id='mob-add-to-cart-btn'>
                        Add to Cart
                      </Button>
                    {
                      showAlert && <Alert
                        severity={alertVariant}
                        variant='filled'
                        style={{borderRadius:'0px'}}
                        className='mob-add-tocart-alert'
                        >
                          {alertMessage}
                        </Alert>
                    }
                      <IconButton variant='outlined' id='mob-add-to-wishlist'>
                        <FavoriteBorderIcon fontSize='large'/>
                      </IconButton>
                      <Button 
                        variant='contained'
                        size='small'
                        color='secondary'
                        id='detail-wishlist-btn'
                        onClick={()=>addToWishList()}
                      >
                        {
                          !isAddingToWishList ?
                            <>
                              <FavoriteBorderIcon/> Add to Wishlist
                            </>
                          :
                            <CircularProgress size={30} style={{color:'white'}}/>
                        }
                      </Button>
                    </div>
                  </div>
                : (isPrescription && !isOtc && !isControlled) ?
                  <div style={{marginTop:'20px'}} className='detail-wishlist'>
                    <Paper elevation={6} className='mob-presc-card'>
                      <div className='mob-prescription-div'>
                        This product requires a prescription from a health worker/facility.<br/>
                        <NavLink>Terms & Conditions</NavLink>
                      </div>
                    </Paper>
                  </div>
                : (isControlled && !isPrescription && !isOtc) ?
                  <div style={{marginTop:'20px'}} className='detail-wishlist'>
                    <Paper elevation={6} className='mob-controlled-card'>
                      
                      <div className='mob-controlled-div'>
                        <WarningAmberIcon fontSize='large' style={{color:'#EE1C22'}}/>
                        This is a controlled product.
                        Contact us directly to purchase.<br/>
                        <NavLink>Terms & Conditions</NavLink>
                      </div>
                    </Paper>
                  </div>
                :
                <></>
              }
            </>
          }
        </div>
      </div>
      <div className='product-detail-accordion-div'>
        <div  style={{backgroundColor:'white'}}>
          <h1 className='detail-heading'>Description</h1>

          <p style={{fontSize:'17px'}}>
            {product.description}
          </p>
        </div>
        <div className='product-detail-accordion'>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <h2>Usage / Instructions</h2>
            </AccordionSummary>
            <AccordionDetails>
              {product.how_to_use}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <h2>Warning / Side effects</h2>
            </AccordionSummary>
            <AccordionDetails>
              {
                productWarning.length > 0 ?
                  productWarning.map((element, index)=>{
                    return(
                      <p key={index}>{element}</p>
                    )
                  })
                :
                 <p>No content found</p>
              }

            </AccordionDetails>
          </Accordion>
          <Accordion >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <h2>Ingredients</h2>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
             {
               product.ingredients !== undefined && product.ingredients.length > 0?
                product.ingredients.map((element, index)=>{ 
                  
                  return(
                    <li key={index}>{element}</li>  
                  )
                })
              :
               <li>No content found</li>
             }
             </ul>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className='product-detail-desclaimer'>
          <Paper className='desclaimer-paper' elevation={2}>
            <div className='descalimer-heading-div'>
              <img src={images.warning} alt=''/>
              <p className='desclaimer-heading'>Disclaimer.</p>
            </div>
            <p className='desclaimer'>Drugs Express Aims to deliver expert-reviewed, 
            accurate, and trustworthy information to its users.<br/>
              However, its crucial to note that the provided information is not a replacement for advice from a
                qualified physician.<br/>
              The content is for informational purposes only, may not encompass every aspect of health conditions, 
              lab tests, medicines, or potential side effects.<br/>
              For comprehensive guidance on diseases or medications, consult your doctor to foster and uphold the 
              doctor-patient relationship.
            </p>
          </Paper>
        </div>
        <div className='product-detail-review'>
          <div className='product-detail-review-head'>
            <h3>Reviews.</h3>
            <Button onClick={handleClickOpen}><AddOutlinedIcon/> Add</Button>
          </div>
          <div className='product-detail-paper-div'>
            {
              reviews.length === 0 ?
                <p>No reviews</p>
              :

              <Grid container spacing={2}>
                {                
                  reviews.map((item, index)=>{
                    return(
                      <Grid item xs={12} md={6} lg={6} key={index}>
                        <Paper className='product-detail-review-paper'>
                          <div className='product-detail-review-rating'>
                            <p>{item.reviewer.name || item.reviewer.name === '' ? item.reviewer.name : 'unknown user'}</p>
                            <Rating value={item.rating ? item.rating : 3} readOnly/>
                          </div>
                          {item.comment ? item.comment : ''}
                          <p className='product-detail-review-date'>{convertEpochToDatetime(item.time)}</p>
                        </Paper>
                      </Grid>
                    )
                  })
                }
              </Grid>
            }
          </div>
        </div>
      </div>
      <div className='stopDiv'></div>
    </div>
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle>
        <p id='review-dialog-heading'>"{product.name}"</p>
      </DialogTitle>
      <DialogContent style={{width:'500px'}}>
        {
          isLoggedIn || localLoggedIn ?
            <DialogContentText>
            <div className='review-rate'>
              Rate this product: 
              <Rating
                value={value}
                onChange={(event, newValue)=>setValue(newValue)}
                id='review-rate-counter'
              />
            </div>
            <div className='review-comment'>
              <p>Add Review Comment:</p>
              <TextField
                autoFocus
                required
                margin="dense"
                id="review"
                name="review-comment"
                type="email"
                fullWidth
                variant="standard"
                multiline
                maxRows={3}
                onChange={(event)=>{
                  setComment(event.target.value)
                }}
              />
            </div>

          </DialogContentText>
          :
            <>You are not logged in.</>
        }
      </DialogContent>
      <DialogActions>
        {
          isLoggedIn || localLoggedIn ?
            <>
              <Button onClick={()=>setOpen(false)}>Cancel</Button>
              <Button 
                // type='submit' 
                onClick={()=>{
                  onSubmitReview()
                }}
                >
                  Submit
                </Button>
            </>
          :
            <></>
        }
      </DialogActions>
    </Dialog>
    </>
  )
}

export default ProductDetails