import React, { useState } from 'react'
import '../assets/css/styles.css'
import images from '../assets/index.js'
import {
  capitalizeProductName,
  cosmetics,
  cough_cold_fever,
  family_planning,
  health_devices,
  supplements,
  stomach_disorder,
  pain,
  anti_malarials,
  dropDownList
} from '../utils.js'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';


function DropDown(props) {
  // const dropDownList = [
  //   {
  //     image:images.family,
  //     text:family_planning,
  //     icon:KeyboardArrowRightIcon
  //   },
  //   {
  //     image:images.antimalarials,
  //     text:anti_malarials,
  //     icon:KeyboardArrowRightIcon
  //   },
  //   {
  //     image:images.allergy,
  //     text:cough_cold_fever,
  //     icon:KeyboardArrowRightIcon
  //   },
  //   {
  //     image:images.sick,
  //     text: pain,
  //     icon:KeyboardArrowRightIcon
  //   },
  //   {
  //     image:images.bloating,
  //     text: stomach_disorder,
  //     icon:KeyboardArrowRightIcon
  //   },
  //   {
  //     image:images.protection,
  //     text:cosmetics,
  //     icon:KeyboardArrowRightIcon
  //   },
  //   {
  //     image:images.protein_shake,
  //     text:supplements,
  //     icon:KeyboardArrowRightIcon
  //   },
  //   {
  //     image:images.cardiology,
  //     text:health_devices,
  //     icon:KeyboardArrowRightIcon
  //   },
  // ]



  const [isExtraVisible, setIsExtraVisible] = useState(false)
  const [isCosmeticsVisible, setIsCosmeticsVisible] = useState(false)
  const [isCoughAndFluVisible, setIsCoughAndFluVisible] = useState(false)
  const [isHealthSupplementsVisible, setIsHealthSupplementsVisible] = useState(false)
  const [isHealthDevicesVisible, setIsHealthDevicesVisible] = useState(false)
  const [isAntiMalarialVisible, setIsAntiMalarialVisible] = useState(false)
  const [isPainFeverVisible, setIsPainFeverVisible] = useState(false)
  const [isStomachDisorderVisible, setIsStomachDisorderVisible] = useState(false)

  return (
      <div className='navigation-dropdown-panel-div'>
        <div 
          className='navigation-dropdown-panel'
          onMouseEnter={props.onEnter}
          onMouseLeave={props.onLeave}
        >
          {
            dropDownList.map((item, index)=>{
              return(
                  <div 
                    className='navigation-dropdown-child'
                    key={index}
                    onMouseEnter={()=>{
                      if(item.text === family_planning){
                        setIsExtraVisible(true)
                      }

                      if(item.text === cosmetics){
                        setIsCosmeticsVisible(true)
                      }

                      if(item.text === cough_cold_fever){
                        setIsCoughAndFluVisible(true)
                      }

                      if(item.text === health_devices){
                        setIsHealthDevicesVisible(true)
                      }

                      if(item.text === supplements){
                        setIsHealthSupplementsVisible(true)
                      }

                      if(item.text === anti_malarials){
                        setIsAntiMalarialVisible(true)
                      }

                      if(item.text === pain){
                        setIsPainFeverVisible(true)
                      }

                      if(item.text === stomach_disorder){
                        setIsStomachDisorderVisible(true)
                      }

                    }}
                    onMouseLeave={()=>{
                      setIsExtraVisible(false)
                      setIsCosmeticsVisible(false)
                      setIsCoughAndFluVisible(false)
                      setIsHealthDevicesVisible(false)
                      setIsHealthSupplementsVisible(false)
                      setIsAntiMalarialVisible(false)
                      setIsPainFeverVisible(false)
                      setIsStomachDisorderVisible(false)
                    }}
                  >
                    <div className='navigation-dropdown-child-img'>
                      <img src={item.image} alt=''/>  
                    </div>
                    <div className='navigation-dropdown-child-p'>
                      <p>{item.text}</p>
                    </div>
                    <div className='navigation-dropdown-child-icon'>
                      <item.icon />
                    </div>
                    {
                      isExtraVisible && item.text === family_planning ?
                        <FamilyPlanningSidePanel isSticky={false}/>
                      : (isCosmeticsVisible && item.text === cosmetics) ?
                        <CosmeticsSidePanel isSticky={false}/>
                      : (isCoughAndFluVisible && item.text === cough_cold_fever) ?
                        <CoughFluSidePanel isSticky={false}/>
                      :  (isHealthSupplementsVisible && item.text === supplements) ?
                            <HealthSupplementsSidePanel isSticky={false}/>
                      : (isHealthDevicesVisible && item.text === health_devices) ?
                        <HealthDevicesSidePanel isSticky={false}/>
                      : (isAntiMalarialVisible && item.text === anti_malarials) ?
                        <AntiMalarialSidePanel isSticky={false}/>
                      : (isStomachDisorderVisible && item.text === stomach_disorder) ?
                        <StomachDisorderSidePanel isSticky={false}/>
                      : (isPainFeverVisible && item.text === pain) ?
                        <PainFeverSidePanel isSticky={false}/>
                      :
                        <></>
                    }
                  </div>
              )
            })
          }
        </div>
      </div>
  )
}

function DropDownLink(props){
  return(
    <NavLink
      to={{pathname:`/${props.title}/${props.id}/details`}}
      style={({isActive})=>{
        return {
          textDecoration: isActive ? 'underline' : 'none'
        }
      }}
    >
      <Paper className='dropDownLinkPaper'>
        <div className='family_planning_products'>
          {
            props.img === 'no-image' ?
              <img src={images.image_gallery} alt='' className='dropDownLinkPaper-no-image'/>
            :
              <img src={props.img} alt='' className='dropDownLinkPaper-image'/>
          }
          <p>{props.title}</p>
        </div>
      </Paper>
    </NavLink>
  )
}

export function FamilyPlanningSidePanel(props){
  const familyPlanningList = useSelector(state => state.products.familyPlanningProducts)
  const fp = familyPlanningList !== null ? familyPlanningList : []

  
  return(
    <div className={!props.isSticky ? 'navigation-side-panel-family-planning' : 'sticky-navigation-side-panel-family-planning'}>
      <Grid container spacing={2}>
        
            {
              fp.map((item, index)=>{
                return(
                  <Grid item xs={12} sm={12} md={6} key={item}>
                    <DropDownLink 
                      title={capitalizeProductName(item.name)}
                      id={item.id}
                      img={item.images.length > 0 ? item.images[0] : 'no-image'}
                    />
                  </Grid>
                )
              })
            }
      </Grid>
    </div>
  )
}

export function CosmeticsSidePanel(props){
  const cosmeticsList = useSelector(state => state.products.cosmeticsProducts)
  const cosmetics = cosmeticsList !== null ? cosmeticsList : []
  return(
    <div className={!props.isSticky ? 'navigation-side-panel-cosmetics' : 'sticky-navigation-side-panel-cosmetics'}>
      <Grid container spacing={2}>
            {
              cosmetics.map((item, index)=>{
                return( 
                  <Grid item xs={12} sm={12} md={6} key={item}>
                    <DropDownLink 
                      title={capitalizeProductName(item.name)}
                      id={item.id}
                      img={item.images.length > 0 ? item.images[0] : 'no-image'}
                    />
                  </Grid>
                )
              })
            }
      </Grid>
    </div>
  )
}

export function CoughFluSidePanel(props){
  const coughFluList = useSelector(state => state.products.coughFluProducts)
  const coughFlu = coughFluList !== null ? coughFluList : []
  
  return(
    <div className={!props.isSticky ? 'navigation-side-panel-cough-flu' : 'sticky-navigation-side-panel-cough-flu'}>
      <Grid container spacing={2}>
        
            {
              coughFlu.map((item, index)=>{
                return(
                  <Grid item xs={12} sm={12} md={6} key={item}>
                    <DropDownLink
                      title={capitalizeProductName(item.name)}
                      id={item.id}
                      img={item.images.length > 0 ? item.images[0] : 'no-image'}
                    />
                  </Grid>
                )
              })
            }
      </Grid>
    </div>
  )
}

export function HealthSupplementsSidePanel(props){
  const healthSupplementsList = useSelector(state => state.products.vitaminsMineralsProducts)
  const healthSupplements = healthSupplementsList !== null ? healthSupplementsList : []
  
  return(
    <div className={!props.isSticky ? 'navigation-side-panel-supplements' : 'sticky-navigation-side-panel-supplements'}>
      <Grid container spacing={2}>      
            {
              healthSupplements.slice(0, 10).map((item, index)=>{
                return(
                  <Grid item xs={12} sm={12} md={6} key={item}>
                    <DropDownLink
                      title={capitalizeProductName(item.name)}
                      id={item.id}
                      img={item.images.length > 0 ? item.images[0] : 'no-image'}
                    />
                  </Grid>
                )
              })
            }
      </Grid>
    </div>
  )
}

export function HealthDevicesSidePanel(props){
  const healthDevicesList = useSelector(state => state.products.healthDeviceProducts)
  const healthDevices = healthDevicesList !== null ? healthDevicesList : []
  
  return(
    <div className={!props.isSticky ? 'navigation-side-panel-health-devices' : 'sticky-navigation-side-panel-health-devices'}>
      <Grid container spacing={2}>
            {
              healthDevices.slice(0, 10).map((item, index)=>{
                return(
                  <Grid item xs={12} sm={12} md={6} key={item}>
                    <DropDownLink
                      title={capitalizeProductName(item.name)}
                      id={item.id}
                      img={item.images.length > 0 ? item.images[0] : 'no-image'}
                    />
                  </Grid>
                )
              })
            }
      </Grid>
    </div>
  )
}

export function AntiMalarialSidePanel(props){
  const antiMalarialsList = useSelector(state => state.products.malariaProducts)
  const antiMalarial = antiMalarialsList !== null ? antiMalarialsList : []
  
  return(
    <div className={!props.isSticky ? 'navigation-side-panel-anti-malaria' : 'sticky-navigation-side-panel-anti-malaria'}>
      <Grid container spacing={2}>
            {
              antiMalarial.slice(0, 10).map((item, index)=>{
                return(
                  <Grid item xs={12} sm={12} md={6} key={item}>
                    <DropDownLink
                      title={capitalizeProductName(item.name)}
                      id={item.id}
                      img={item.images.length > 0 ? item.images[0] : 'no-image'}
                    />
                  </Grid>
                )
              })
            }
      </Grid>
    </div>
  )
}

export function StomachDisorderSidePanel(props){
  const stomachDisorderList = useSelector(state => state.products.stomachProducts)
  const stomach = stomachDisorderList !== null ? stomachDisorderList : []
  
  return(
    <div className={!props.isSticky ? 'navigation-side-panel-stomach' : 'sticky-navigation-side-panel-stomach'}>
      <Grid container spacing={2}>
            {
              stomach.map((item, index)=>{
                return(
                  <Grid item xs={12} sm={12} md={6} key={item}>
                    <DropDownLink
                      title={capitalizeProductName(item.name)}
                      id={item.id}
                      img={item.images.length > 0 ? item.images[0] : 'no-image'}
                    />
                  </Grid>
                )
              })
            }
      </Grid>
    </div>
  )
}

export function PainFeverSidePanel(props){
  const painFeverList = useSelector(state => state.products.painAndFeverProducts)
  const painFever = painFeverList !== null ? painFeverList : []
  
  return(
    <div className={!props.isSticky ? 'navigation-side-panel-pain' : 'sticky-navigation-side-panel-pain'}>
      <Grid container spacing={2}>      
            {
              painFever.map((item, index)=>{
                return(
                  <Grid item xs={12} sm={12} md={6} key={item}>
                    <DropDownLink
                      title={capitalizeProductName(item.name)}
                      id={item.id}
                      img={item.images.length > 0 ? item.images[0] : 'no-image'}
                    />
                  </Grid>
                )
              })
            }
      </Grid>
    </div>
  )
}


export default DropDown 