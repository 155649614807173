import React from 'react'
import { NavLink } from 'react-router-dom'
import '../assets/css/components.css'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

function BreadCrumb(props) {

  return (
    <div className='breadcrumb'>
        <NavLink to='/'>
            <div className='home-crumb'>
                <HomeOutlinedIcon/>
                <p>Home</p>
            </div>
        </NavLink>
        {
            props.crumbs.map((element, index)=>{
                return(
                    <React.Fragment key={index}>
                        <div className='nav-crumb-slash'>
                            <KeyboardArrowRightIcon/>
                        </div>
                        <NavLink to={element.url} >
                            <div className='nav-crumb'>
                                <p>{element.text}</p>
                            </div>
                        </NavLink>
                    </React.Fragment>
                )
            })
        }
    </div>
  )
}

export default BreadCrumb