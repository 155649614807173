import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { collection, getDocs, query, where, limit, startAfter, orderBy, addDoc } from 'firebase/firestore';
import { db } from '../firebase';

const reviews_col = collection(db, 'reviews')

export const getReviews = createAsyncThunk('review/get', async (id)=>{
    try {
        let reviewsQuery = query(reviews_col, where('thing_id', '==', id), where('thing_type', '==', 'product'))

        const reviewSnapShot = await getDocs(reviewsQuery)
        const reviewsList = reviewSnapShot.docs.map(doc => doc.data())

        return reviewsList;

    } catch (error) {
        throw error;
    }
})

export const addReview = createAsyncThunk('review/add', async (reviewItem)=>{
    try {
        await addDoc(reviews_col, reviewItem)
    } catch (error) {
        throw error;
    }
})


const review_slice = createSlice({
    name:'reviews',
    initialState:{
        isLoading : false,
        error : null,
        productReviewsList: []
    },
    extraReducers: (builder)=>{
        builder
        .addCase(getReviews.pending, (state)=>{
            state.isLoading = true
            state.error = null
        })
        .addCase(getReviews.fulfilled, (state, action)=>{
            state.isLoading = false
            state.error = null
            state.productReviewsList = action.payload
        })
        .addCase(getReviews.rejected, (state, action)=>{
            state.isLoading = false
            state.error = action.error.message
        })
        .addCase(addReview.pending, (state)=>{
            state.isLoading = true
            state.error = null
        })
        .addCase(addReview.fulfilled, (state)=>{
            state.isLoading = false
            state.error = null
        })
        .addCase(addReview.rejected, (state, action)=>{
            state.isLoading = false
            state.error = action.error.message
        })
    }
})

export const reviewActions = review_slice.actions;
export default review_slice;