import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { NavLink } from 'react-router-dom';

// icons
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined';
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';
import SickOutlinedIcon from '@mui/icons-material/SickOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import { useSelector } from 'react-redux';
import { Badge } from '@mui/material';
import { cart } from '../utils';

export default function MobileNavigation() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const cartItemNum = useSelector(state => state.cart.cartNumberofProducts)
  const auth = useSelector(state => state.auth)
  const signedIn = localStorage.getItem('isLoggedIn')

  React.useEffect(()=>{},[auth.isLoggedIn, signedIn])
  
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding className='auth-list-item'>
          {
            (auth.user !== null && auth.isLoggedIn === true) || signedIn ?
              <NavLink to='signout'>
                <div className='auth-list-item-div'>
                  <div className='auth-list-item-text'>
                    <p>Logout</p>
                  </div>
                  <LogoutOutlinedIcon fontSize='large' className='account-icon'/>
                </div>
              </NavLink>
            :
            <NavLink to='signin'>
              <div className='auth-list-item-div'>
                <AccountCircleOutlinedIcon fontSize='large' className='account-icon'/>
                <div className='auth-list-item-text'>
                  <p><span className='welcome-tag'>Welcome!</span><br/>Login/Sign Up</p>
                </div>
                <ArrowForwardIosRoundedIcon className='account-icon'/>
              </div>
            </NavLink>
          }
        </ListItem>
      </List>
      <List>
        <ListItem className='mob-link-list-item'>
          <NavLink to="/">
            <div className='mob-link'>
              <HomeOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Home
            </div>
          </NavLink>
        </ListItem>
        {
          (auth.user !== null && auth.isLoggedIn === true) || signedIn ?
            <ListItem className='mob-link-list-item'>
              <NavLink to="/transactions">
                <div className='mob-link'>
                  <ReceiptOutlinedIcon
                    fontSize='medium'
                    className='mob-link-icon'
                  />
                  Orders
                </div>
              </NavLink>
            </ListItem>
          :
            <></>
        }
        {
          (auth.user !== null && auth.isLoggedIn === true) || signedIn ?
            <ListItem className='mob-link-list-item'>
              <NavLink to="/cart">
                <div className='mob-link'>
                <Badge badgeContent={cartItemNum && cartItemNum} color='secondary'>
                  <ShoppingCartCheckoutOutlinedIcon
                    fontSize='medium'
                    className='mob-link-icon'
                  />
                  {cart}
                </Badge>
                </div>
              </NavLink>
            </ListItem>
          :
            <></>
        }
        {
          (auth.user !== null && auth.isLoggedIn === true) || signedIn ?
            <ListItem className='mob-link-list-item'>
              <NavLink to='/wishlist'>
                <div className='mob-link'>
                  <FavoriteBorderOutlinedIcon fontSize='medium' className='mob-link-icon'/>
                  Wishlist
                </div>
              </NavLink>
            </ListItem>
          :
            <></>
        }
        <ListItem className='mob-link-list-item'>
          <NavLink to="/offers">
            <div className='mob-link'>
              <RedeemOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Offers
            </div>
          </NavLink>
        </ListItem>
        <ListItem className='mob-link-list-item'>
          <NavLink to='/blog'>
            <div className='mob-link'>
              <ArticleOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Health Articles
            </div>
          </NavLink>
        </ListItem>
      </List>
      <Divider/>
      <List>
      <ListItem className='mob-link-list-item'>
          <NavLink to="/family-planning">
            <div className='mob-link'>
              <FamilyRestroomOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Family Planning
            </div>
          </NavLink>
        </ListItem>
        {/* <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <MedicationOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Medicine
            </div>
          </NavLink>
        </ListItem> */}
        <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <SelfImprovementOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Personal Care
            </div>
          </NavLink>
        </ListItem>
        <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <ChildCareIcon fontSize='medium' className='mob-link-icon'/>
              Baby Care
            </div>
          </NavLink>
        </ListItem>
        <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <HealthAndSafetyOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Vitamins & Nutrition
            </div>
          </NavLink>
        </ListItem>
        <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <MonitorHeartOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Health Devices
            </div>
          </NavLink>
        </ListItem>
        <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <SickOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Health Conditions
            </div>
          </NavLink>
        </ListItem>
      </List>
      <Divider/>
      <List>
      <ListItem className='mob-link-list-item'>
        <NavLink to='/about-us'>
          <div className='mob-link'>
            <InfoOutlinedIcon fontSize='medium' className='mob-link-icon'/>
            About Us
          </div>
        </NavLink>
      </ListItem>
      <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <LocalPhoneOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Contact Us
            </div>
          </NavLink>
        </ListItem>
        <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <HelpOutlineOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Need Help
            </div>
          </NavLink>
        </ListItem>
        <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <PrivacyTipOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Privacy Policy
            </div>
          </NavLink>
        </ListItem>
        <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <ReplayOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Return Policy
            </div>
          </NavLink>
        </ListItem>
        <ListItem className='mob-link-list-item'>
          <NavLink>
            <div className='mob-link'>
              <NotesOutlinedIcon fontSize='medium' className='mob-link-icon'/>
              Terms and Conditions
            </div>
          </NavLink>
        </ListItem>
      </List>
      <div className='mob-nav-footer'>
        <p>For Delivery or Inquiry Call Us On:</p>
        <p> +256 706 404 040</p>
      </div>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} style={{color:"gray"}}>
            {
              cartItemNum > 0 && (auth.isLoggedIn || signedIn) ?
                <Badge badgeContent={'!'} color='secondary'>
                  <MenuIcon color='gray'/>
                </Badge>
              :
              <MenuIcon color='gray'/>
            }
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}