import React, {useEffect, useState, createContext} from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'
import '../assets/css/layoutStyle.css'
import NavigationBar from '../components/Navigation'
import MobileNavigation from '../components/mobileNavigation'
import images from '../assets/index'
import LogoutIcon from '@mui/icons-material/Logout';
import { useSelector, useDispatch } from 'react-redux'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import WifiOffRoundedIcon from '@mui/icons-material/WifiOffRounded';
import {
  Alert,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Rating,
  TextField
} from '@mui/material'
import { getFeedback, updateFeedBackAsync } from '../store/order_feedback';
import { createCartItemAsync } from '../store/cart_slice'
import { closeSearchDialog } from '../store/searchDialog_slice'
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, SearchBox, Pagination, Configure } from 'react-instantsearch';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchProductCard from '../components/searchProductCard/searchProductCard.js';
import { addCommas } from '../utils.js';
import ProductCard from '../components/productCard.js'



function MainLayout() {
  const authState = useSelector(state => state.auth.isLoggedIn)
  const openSearch = useSelector(state => state.dialog.openSearch)
  const DialogContext = createContext();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [navValue, setNavValue] = useState(null);
  const [navLoggedInValue, setNavLoggedInValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [feedBack, setFeedback] = useState(null)
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  // const [openCookies, setOpenCookies] = useState(true)
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wishlistNumItem = useSelector(state => state.wishlist.wishListNumOfItems)
  const cartNumItem = useSelector(state => state.cart.cartNumberofProducts)
  const user_id = useSelector(state=>state.auth.id)
  const user = useSelector(state=>state.auth.user)
  const loggedIn = localStorage.getItem('isLoggedIn')
  let cart = localStorage.getItem('cart')
  let cartNum = localStorage.getItem('cartNum')
  let cartTotal = localStorage.getItem('cartTotal')
  const u_email = localStorage.getItem('email')

  // const banners = useSelector(state=>state.banners.bannerList)
  // const searchClient = algoliasearch(process.env.REACT_APP_ALOGLIA_ONE, process.env.REACT_APP_ALOGLIA_TWO);
  const searchClient = algoliasearch('KNV1FMAL7M', 'd4cc4c916ab116c5e377895e94e27e3e');
  const queryHook = (query, search) => {
    const s = search(query);
  };

  const Hit = ({ hit }) => {
    return(

      <SearchProductCard
        id={hit.id}
        title={hit.name}
        gen_name={hit.generic_name}
        manufacturer={hit.manufacturer_name}
        price={hit.b2b_price}
        category={hit.category_name}
        isFlashSale={hit.has_flash_sale}
        image={hit.images === undefined ? 'no_image' : hit.images[0]}
        discount_price={hit.flash_sale_b2c_price}
      />
  )};
  
  const Results = () => {
    return(
      <Hits hitComponent={Hit}/>
    )
  }

  useEffect(() => {
    const handleOnlineStatus = () => {
      if (!navigator.onLine) {
        setOpen(true);
      }
    };
  
    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus)

    handleOnlineStatus();


  
    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);


  useEffect(() => {
    const initializeCart = () => {
      if (!cart) {
        localStorage.setItem('cart', JSON.stringify([]));
      }
      if(!cartNum){
        localStorage.setItem('cartNum', 0)
      }
      if(!cartTotal){
        localStorage.setItem('cartTotal', 0)
      }
    };
  
    const handleFeedback = async (userId) => {
      try {
        const res = await dispatch(getFeedback(userId));
        if (res.meta.requestStatus === 'fulfilled' && res.payload.length !== 0) {
          setFeedback(res.payload);
          setOpenFeedback(true);
        }
      } catch (error) {
        console.error('Error fetching feedback:', error);
      }
    };
  

  
    const userEmail = user ? user.email : u_email;
  
    if (loggedIn !== null && loggedIn !== undefined && authState === true) {
      setIsLoggedIn(loggedIn);
      handleFeedback(user_id);

    } else if (loggedIn === null || loggedIn === false || loggedIn === undefined) {
      initializeCart();
    }
  }, [loggedIn, authState]);


  const handleChange = (event, newValue)=>{
    setNavValue(newValue)

    switch(newValue){
      case 0:
        navigate('/')
        break;
      case 1:
        navigate('/offers')
        break;
      case 2:
        navigate('/signin')
        break;

    }
  }

  const handleLoggedInChange = (event, newValue)=>{
    setNavLoggedInValue(newValue)

    switch(newValue){
      case 0:
        navigate('/')
        break;
      case 1:
          navigate('/cart')
          break;
      case 2:
        navigate('/wishlist')
        break;
      case 3:
        navigate('/offers')
        break;
      case 4:
        navigate('/manage/account')
        break;
    }
  }

  const handleClose = ()=>{
    setOpen(false)
  }

  const submitFeedback = ()=>{
    const currentDate = new Date()

    const obj = {
      uid:user_id,
      bad:[comment],
      good:[comment],
      pending:false,
      rating:rating,
      rejected:false,
      responded:true,
      responded_from:'client',
      responder_id:user_id,
      updated_at:currentDate.getTime()
    }

    dispatch(updateFeedBackAsync(obj))
    .then(res => {
      if(res['meta']['requestStatus'] === 'fulfilled'){
        setOpenFeedback(false)
      }
    })
  }

  const cancelFeedback = ()=>{
    const currentDate = new Date()

    const obj = {
      uid:user_id,
      bad:[],
      good:[],
      pending:false,
      rating:rating,
      rejected:true,
      responded:true,
      responded_from:'client',
      responder_id:user_id,
      updated_at:currentDate.getTime()
    }

    dispatch(updateFeedBackAsync(obj))
    .then(res => {
      if(res['meta']['requestStatus'] === 'fulfilled'){
        setOpenFeedback(false)
      }
    })
  }

  const handleCloseSearch = ()=>{
    dispatch(closeSearchDialog());
  }


  return (
    <>
      <div className='layout'>
        <div className='main-layout'>
            <header className='dk-header'>
                <NavigationBar/>
            </header>
            <header className='mb-header'>
              <MobileNavigation/>
              <img src={images.logo6} alt=''/>

              <div >
                <div className='mb-header-action-user-mgt'>
                  {
                    !loggedIn ?
                      <div className={authState ? 'mb-header-action-point':'mb-header-action-point-out'}>
                        <NavLink to='signin'>
                          Sign In <LoginOutlinedIcon style={{fontSize:'20px'}}/>
                        </NavLink>
                      </div>
                    :
                      <></>

                  }
                  {
                    loggedIn ?
                      <div >
                        <NavLink to='signout' style={{color:'crimson', textDecoration:'none'}}>
                          Sign Out <LogoutIcon style={{fontSize:'20px'}}/>
                        </NavLink>
                      </div>
                    :
                      <></>
                  }
                </div>
              </div>

            </header>

            <main>
                <Outlet/>
            </main>
            <Paper 
              sx = {{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:150 }}
              className='mob-bottom-nav'
              elevation={3}
            >
              
                {
                  isLoggedIn !== null && isLoggedIn ?
                    <BottomNavigation showLabels onChange={handleLoggedInChange} value={navLoggedInValue}>
                      <BottomNavigationAction label="Home" icon={<HomeOutlinedIcon/>}/>
                      <BottomNavigationAction 
                        label="Cart"
                        icon={
                          <Badge badgeContent={cartNumItem} color='primary'>
                            <ShoppingCartOutlinedIcon/>
                          </Badge>
                        }
                      />
                      <BottomNavigationAction
                        label="Wish List"
                        icon={
                          <Badge badgeContent={wishlistNumItem} color='primary'>
                            <FavoriteBorderOutlinedIcon/>
                          </Badge>
                        }
                      />
                      <BottomNavigationAction label="Offers" icon={<LocalOfferOutlinedIcon/>} />
                      <BottomNavigationAction label="Account" icon={<ManageAccountsOutlinedIcon/>}/>
                    </BottomNavigation>
                  :
                    <BottomNavigation showLabels onChange={handleChange} value={navValue} >
                      <BottomNavigationAction label="Home" icon={<HomeOutlinedIcon/>} />
                      <BottomNavigationAction label="Offers" icon={<LocalOfferOutlinedIcon/>} />
                      <BottomNavigationAction label="Sign In" icon={<AccountCircleOutlinedIcon/>} />     
                    </BottomNavigation>
                }
            </Paper>

            <Footer/>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="no-internet" aria-describedby='No internet connection.'>
        <DialogContent>
          <div className='no-network'>
            <WifiOffRoundedIcon fontSize='large' style={{color:'#EE1C22'}}/>
            <p>No internet connection.</p>
            <p>Check your network or reload this page</p>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openFeedback} onClose={handleClose} aria-labelledby='feedback' aria-describedby='User experience feedback'>
        <DialogContent>
          <div className='feedback-div'>
            <p>Please rate your experience on Drugs Express.</p>
            <div className='rating-div'>
              <Rating value={rating} onChange={(event, newValue)=>{setRating(newValue)}} size='large'/>
            </div>
            <TextField
              multiline
              rows={5}
              label="Leave comment"
              onChange={(event)=>{
                setComment(event.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color='error'
            variant='contained'
            onClick={(event)=>{cancelFeedback()}}
          >
            Cancel
          </Button>
          <Button
            color='success'
            variant='contained'
            onClick={(event)=>{submitFeedback()}}
            type='submit'
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSearch} onClose={handleCloseSearch} fullWidth="md" maxWidth="md" scroll='paper'>
        <InstantSearch searchClient={searchClient} indexName="products">
          <Paper elevation={2}>
              <DialogTitle className="search-dialog-title">
                <div className="search-dialog-div">
                  <Configure hitsPerPage={10}/>
                  <SearchBox 
                      classNames={{
                        root:'search-box-root',
                        input:'search-input',
                        form:'search-box-form', 
                        submit:'submit-icon',
                        reset:'reset-icon'
                      }}
                      submitIconComponent={({ classNames }) => (
                          <div className={classNames.submitIcon}><SearchOutlinedIcon/></div>
                      )}
                      resetIconComponent={({ classNames }) => (
                          <div style={{color:"red"}}>reset</div>
                      )}
                      placeholder='Search for Medicines and Health Products ...'

                      queryHook={queryHook}
                  />
                </div>
                <Button
                  onClick={handleCloseSearch}
                >
                  close
                </Button>
              </DialogTitle>
              <DialogContent dividers={true} sx={{ overflowY: 'auto', maxHeight: '400px', minHeight:'400px' }}>
                <div className='search-dialog-results'>
                  <Results/>
                </div>
              </DialogContent>
              <DialogActions sx={{maxHeight: '400px' }}>
                <div className='search-dialog-pagination'>
                    <Pagination/>
                  </div>
              </DialogActions>
          </Paper>
        </InstantSearch>
      </Dialog>
    </>
  )
}

export default MainLayout