import React from 'react';
import { createBrowserRouter, createRoutesFromElements,
  Route, RouterProvider, Navigate, Outlet } from 'react-router-dom';
  import 'react-loading-skeleton/dist/skeleton.css';
import './App.css';
import MainLayout from './layouts/mainLayout.js';
import HomePage from './pages/Home.js';
import LoginPage from './pages/Login.js';
import SignUp from './pages/SignUp.js'; 
import FamilyPLan from './pages/FamilyPlan.js';
import Logout from './pages/logout.js';
import ProductDetails from './pages/ProductDetail.js';
import Cart from './pages/cart.js';
import Checkout from './pages/checkout.js';
import Medicine from './pages/medicine.js';
import NotFound from './pages/notFound.js';
import Referral from './pages/referral.js';
import ManageAccount from './pages/manageAccount.js';
import Search from './pages/search.js';
import BlogHome from './pages/Blog.js';
import AboutUs from './pages/AboutUs.js';
import CategoryMedicine from './pages/CategoryMedicine.js';
import AdLog from './pages/Dashboard.js';
import AnalyticsComponent from './pages/dummyAnalytics.js';
import OrderTransactions from './pages/orders.js';
import ResetPassword from './pages/ResetPassword.js';
import WishList from './pages/wishlist.js';
import PageBodyLayout from './layouts/pageBodyLayout.js';
import Offers from './pages/offers.js';
import BlogDetail from './pages/blogDetail.js';
import NoInternet from './pages/noInternet.js';
import Terms from './pages/terms.js';
import { Provider } from 'react-redux';
import store from './store/store.js';
import FirebaseComponent from './firebase.js';
import FeedBack from './components/feedBack.js';
import { Feed } from '@mui/icons-material';
import Privacy from './pages/privacy.js';
import Credits from './pages/credits.js';

const ProtectedRoutes = ()=>{
  const localStorageToken = localStorage.getItem("isLoggedIn");

	return localStorageToken ? <Outlet/> : <Navigate to="/signin"  replace />;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<MainLayout/>}>
        <Route index element={<HomePage/>}/>
        <Route path='family-planning' element={<FamilyPLan/>}/>
        <Route path='signout' element={<Logout/>}/>
        <Route path=':title/:id/details' element={<ProductDetails/>}/>
        <Route path=':id/:name/products' element={<Medicine/>}/>
        <Route path='search' element={<Search/>}/>
        <Route path='about-us' element={<AboutUs/>}/>
        <Route path='dashboard' element={<AdLog/>} />
        <Route path='terms&conditions' element={<Terms/>}/>
        <Route path='privacy-policy' element={<Privacy/>}/>
        <Route path='credits' element={<Credits/>}/>
        <Route path='cart' element={<Cart/>}/>
        <Route element={< PageBodyLayout/>}>
          <Route path='offers' element={<Offers/>}/>
          <Route path='blog' element={<BlogHome/>}/>
          <Route path='blog/:blog-title' element={<BlogDetail/>}/>
        </Route>
        <Route element={<ProtectedRoutes/>}>
          <Route path='manage/account' element={<ManageAccount/>}/>
          
          <Route path='analytics' element={<AnalyticsComponent/>}/>
          <Route path='transactions' element={<OrderTransactions/>}/>
          <Route element={<PageBodyLayout/>}>
            <Route path="wishlist" element={<WishList/>}/>
          </Route>
        </Route>
      </Route>
      <Route element={<ProtectedRoutes/>}>
        <Route path="checkout" element={<Checkout/>}/>
      </Route>
      <Route path='register' element={<SignUp/>}/>
      <Route path="signin" element={<LoginPage/>}/>
      <Route path="reset-password" element={<ResetPassword/>}/>
      <Route path=":email/referral" element={< Referral/>}/>
      <Route path="/error-page" element={<NoInternet/>}/>
      <Route path='*' element={<NotFound/>}/>
    </>
  )
)

const App = () => {


  return (
    <Provider store={store}>
      <FirebaseComponent/>
      {/* <FeedBack/> */}
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;
