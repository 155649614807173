import React from 'react'
import '../assets/css/referral.css'
import { Paper, FormControl,
  TextField, Button, FormControlLabel, RadioGroup,
  FormLabel, Radio, CircularProgress } from '@mui/material';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import { UseDispatch, useDispatch } from 'react-redux';
import { updateReferral } from '../store/auth-slice';
import { useParams } from 'react-router-dom'
import { set } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';


function Referral() {
  const [value, setValue] = React.useState('Other');
  const [loading, setLoading] = React.useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const p = useParams();

  const handleChange = (event)=>{
    setValue(event.target.value)

  }

  const setReferral = ()=>{
    setLoading(true)
    dispatch(updateReferral({email: p.email, referral: value}))
    .then(res=>{

      if(res['meta']['requestStatus'] === 'fulfilled'){
          setLoading(false);

          navigate('/')
      }
    })
    setLoading(false)
  }


  return (
    <div className='referral-div'>
      <Paper className='referral-paper'>
        <FormControl>
          <h3>How did you hear about Drugs Express?</h3>
          <FormLabel id="demo-controlled-radio-buttons-group">
            
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel value="Marie Stopes Uganda" control={<Radio />} label="Marie Stopes Uganda" />
            <FormControlLabel value="Viya" control={<Radio />} label="Viya Uganda" />
            <FormControlLabel value="Social Media" control={<Radio />} label="Social Media" />
            <FormControlLabel value="Tv/Radio" control={<Radio />} label="Tv/Radio" />
            <FormControlLabel value="Other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
        {
          loading ?
            <CircularProgress/>
          :
            <Button variant='contained' onClick={()=>setReferral()}>Continue <ArrowRightAltOutlinedIcon/></Button>
            
        }
      </Paper>

    </div>
  )
}

export default Referral