import React from 'react';
import '../assets/css/wishlist.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWishlistAsync, removeItemFromWishListAsync } from '../store/wishlist_slice';
import { Grid, Button } from '@mui/material';
import ProductCard from '../components/productCard';
import { getProductByIdAsync } from '../store/products_slice';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Skeleton from 'react-loading-skeleton';
import BreadCrumb from '../components/breadCrumb';

function WishList() {
  const dispatch = useDispatch()
  const user_id = useSelector(state=>state.auth.id);
  const [wishlist, setWishList] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const range = Array.from({ length: 12 }, (_, index) => index);


  React.useEffect(()=>{
    setIsLoading(true);

    dispatch(getAllWishlistAsync(user_id))
    .then(res=>{
      setIsLoading(true)
      if(res['meta']['requestStatus'] === 'fulfilled'){
        const arr = [];
        for(let i = 0; i < res.payload.length; i++){
          dispatch(getProductByIdAsync(res.payload[i].item_id))
          .then(res => {
            if(res['meta']['requestStatus'] === 'fulfilled'){
              arr.push(res.payload[0])
              setWishList([...arr]);

              setIsLoading(false);
            }
          })
          .catch(error=>{

          })
        }
        setIsLoading(false);
      }
    })
    .catch(error=>console.log('An error occurred.'))
    setIsLoading(false);
  },[user_id,])


  const removeFromWishList = (id, item_id)=>{
    dispatch(removeItemFromWishListAsync({id, item_id}))
    .then(res=>{
      setWishList((prevList) => prevList.filter((product) => product.id !== item_id));
    })
    .catch(error=>console.log('An error Occurred.'))
  }
  return (
    <>
    <BreadCrumb
        crumbs={[
        {text:'Wishlist', url:'/wishlist'}
    ]}/>
    <Grid container spacing={2}>
    {
      isLoading ?
        range.map((element, index) => (
          <Skeleton key={index} style={{ height: '320px', width: '200px' }} />
        ))
      : wishlist !== null ?
          wishlist.map((product) => (
            <Grid item xs={6} md={4} key={product.id}>
              <ProductCard
                id={product.id}
                image={product.images.length > 0 ? product.images[0] : ''}
                discount={product.discount}
                title={product.name}
                is_discounted={product.has_flash_sale}
                newPrice={product.has_flash_sale ? product.flash_sale_b2c_price : ''}
                originalPrice={product.b2c_price}
                description={product.description}
              />
              <div className='wishlist-remove'>
                <Button
                  color='error'
                  variant='contained'
                  onClick={() => removeFromWishList(user_id, product.id)}
                >
                  <DeleteOutlineOutlinedIcon/> Remove
                </Button>
              </div>
            </Grid>
          ))
      :
          <div className="wishlist-no-items">
            No Items in wish list.
          </div>
    }
    </Grid>
  </>
    
  )
}

export default WishList