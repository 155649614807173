import React, {useEffect, useState} from 'react'
import '../assets/css/cart.css';
import { Table, TableBody, TableHead, 
    TableRow, Button, TableCell, Alert,
    IconButton, CircularProgress, Dialog, TextField } from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { changeItemOrderAsync, changeItemQuantityAsync, getCartItemsAsync, removeCartItemAsync } from '../store/cart_slice';
import { createOrderAsync } from '../store/order_slice';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import { NavLink, useNavigate } from 'react-router-dom';
import BreadCrumb from '../components/breadCrumb';
import CartProductCard from '../components/cartproductcard';
import { getUserProfile } from '../store/auth-slice';
import { addCommas, formatPhoneNumber } from '../utils';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import images from '../assets';


function Cart() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [totalPrice, setTotalPrice] = useState(0)
    const [numberOfItems, setNumberOfItems] = useState(0)
    const [cartProducts, setCartProducts] = useState(null)
    const [totalAmount, setTotalAmount] = useState(0);
    const [open, setOpen] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(false)
    const [openNoProfile, setOpenNoProfile] = useState(false)
    const [openCheckingOut, setOpenCheckingOut] = useState(false)
    const [item, setItem] = useState(null)
    const [newQuantity, setNewQuantity] = useState(null);
    const [checkoutLoading, setCheckoutLoading] = useState(false)
    const [isChangeLoading, setIsChangeLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [removeFromCart, setRemoveFromCart] = useState(false);
    const [removeFromCartSuccess, setRemoveFromCartSuccess] = useState(false)
    const [removeFromCartFail, setRemoveFromCartFail] = useState(false);
    const [updateCartItemSuccess, setUpdateCartItemSuccess] = useState(false);
    const [updateCartItemFail, setUpdateCartItemFail] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [openNotLoggedIn, setOpenNotLoggedIn] = useState(false);

    const user = useSelector(state => state.auth.user)
    const profile = useSelector(state => state.auth.profile)


    const user_email = user !== null && user !== undefined ? user.email : ''
    const user_name = profile !== null && profile !== undefined ? profile.second_name+ ' '+ profile.first_name : ' '

    const isLoggedIn = localStorage.getItem('isLoggedIn')
    const cart = localStorage.getItem('cart')
    const u_email = localStorage.getItem('email')
    const localName = localStorage.getItem('name')
    const tel = localStorage.getItem('tel')



    const handleClickOpen = (i) => {
        setItem(i)
        setOpen(true);
    };
    
    const handleClose = (value) => {
        setOpen(false);
        setOpenUpdate(false);

    };

    const handleUpdateOpen = (i)=>{
        setItem(i)
        setOpenUpdate(true)
    }

    useEffect(()=>{
        setIsLoading(true)
        if(isLoggedIn){
            let t = 0;

            dispatch(getCartItemsAsync(u_email))
            .then(res=>{
                if(res['meta']['requestStatus'] === 'fulfilled'){
                    t = res.payload.cartList.reduce((accumulator, currentItem) => {
                        setTotalPrice(currentItem.data.total_price)
                        return accumulator + currentItem.data.total_price;
                    }, 0);
                    setTotalAmount(t)
                    setNumberOfItems(res.payload.cartList.length)
                    setCartProducts(res.payload.cartList)
                    
                    setIsLoading(false)

                }
            })
        }
        else if(isLoggedIn && cart){
        }
        else if(!isLoggedIn && cart){
            const nCart = JSON.parse(cart)
            let amount = 0;

            if(cart.length > 0){
                let finArr = [] 
                const uniqueCartArray = nCart.filter((obj, index, self) =>
                    index === self.findIndex((t) => (
                        t.product.id === obj.product.id && t.product.name === obj.product.name
                    ))
                );

                uniqueCartArray.map((element, index)=>{
                    amount += element.total_price
                    let e = {data:element}

                    finArr.push(e)
                    return null;
                })
                setCartProducts(finArr)
                setNumberOfItems(uniqueCartArray.length)
                setTotalAmount(amount)
            }

        }

        setIsLoading(false)

    },[cartProducts, totalAmount, cart, isLoggedIn, u_email])

    const removeItem = (cartItem)=>{
        if(isLoggedIn){
            dispatch(removeCartItemAsync(cartItem))
            .then(res=>{
                if(res['meta']['requestStatus'] === 'fulfilled'){
    
                    setRemoveFromCartSuccess(true)
                }
                else{
                    setRemoveFromCartFail(true)
                }
            })
            .catch(error => {
                console.log('An Error Occurred')
            })

            setTimeout(()=>{
                setOpen(false)
                setRemoveFromCartSuccess(false);
                setRemoveFromCartFail(false)
            }, 2000)
        }else{
            setOpen(false)
            setOpenNotLoggedIn(true)
        }
    }

    const updateCartItem = (cartItem, quantity)=>{
        if(isLoggedIn){
            setIsChangeLoading(true)
            const total_p = cartItem.product.price * quantity
            dispatch(changeItemQuantityAsync({cartItem: cartItem, qty: quantity, total_price: total_p}))
            .then(res=>{
                if(res['meta']['requestStatus'] === 'fulfilled'){
                    setUpdateCartItemSuccess(true)
                }
                else{
                    setUpdateCartItemFail(true)
                }

                setTimeout(()=>{
                    setUpdateCartItemSuccess(false)
                    setUpdateCartItemFail(false)
                    setOpenUpdate(false)
                    setIsChangeLoading(false)
                }, 5000)
            })
        }
        else{
            setOpenUpdate(false)
            setOpenNotLoggedIn(true)
        }
    }

    const createOrder = ()=>{
        console.log('CREATING ORDER>>>')
        if(isLoggedIn){
            console.log('creating order logged in>>>')
            setCheckoutLoading(true)
            setOpenCheckingOut(true)

            const ids = []
            const ctProducts = []
            cartProducts.forEach(element => {
                ids.push(element.data.product.id)

                const lf = {
                    "data":element.data,
                    "id":element.data.product.id,
                    "quantity":element.quantity,
                    "price":element.price,
                    "name":element.name,
                    "units":element.units,
                    "image":element.image
                }

                ctProducts.push(lf)
            });

            let datetime = new Date();

            const orderObj = {
                customer_map: {
                    email:user_email ? user_email : u_email,
                    name: user_name ? user_name : localName,
                    phone_number: tel ? formatPhoneNumber(tel) : ""
                },
                products: ctProducts,
                order_total_amount: totalAmount,
                pending:true,
                assigned:false,
                delivering:false,
                cancelled:false,
                completed:false,
                delivery_time:'',
                destination:'',
                time:datetime.getTime(),
                products_list:ids,
                app_mode:"drugs_express_b2c_web",
                customer_type:"user",
            }

            if( orderObj.products.length === 0 && orderObj.order_total_amount === 0){
                setShowAlert(true);
                setCheckoutLoading(false)
                setOpenCheckingOut(false)
                setTimeout(()=>{
                    setShowAlert(false);
                }, 5000)
            }
            else{
                dispatch(getUserProfile(user_email ? user_email : u_email))
                .then(res => {
                    if(res['meta']['requestStatus'] === 'fulfilled'){
                        if(res.payload.first_name && res.payload.second_name 
                            && res.payload.address && res.payload.phone_number !== undefined){

                                dispatch(changeItemOrderAsync(user_email ? user_email : u_email))
                                .then(res=>{
                                    if(res['meta']['requestStatus'] === 'fulfilled'){
                                        navigate('/checkout', {state:{order_object:orderObj}})
                                    }
                                    else(
                                        setTimeout(()=>{
                                            setOpenCheckingOut(false)
                                            setCheckoutLoading(false)
                                        },3000)
                                    )
                                })
                        }
                        else{
                            setTimeout(()=>{
                                setOpenCheckingOut(false)
                                setCheckoutLoading(false)
                            },3000)
                        }
                    }
                    else{
                        setOpenNoProfile(true)
                    }
                })
                
                
                setTimeout(()=>{
                    setOpenCheckingOut(false)
                    setCheckoutLoading(false)
                },3000)
            }
            setTimeout(()=>{
                setOpenCheckingOut(false)
                setCheckoutLoading(false)
            },3000)
        }
        else{
            setOpenNotLoggedIn(true)
        }
        console.log("ATTT>>>")
    }

  return (
    <>
    <BreadCrumb
        crumbs={[
        {text:'Shopping Cart', url:'/cart'}
    ]}/>
    {showAlert && <Alert severity='warning' variant='filled'>Your cart is empty.</Alert>}
    <div className='cart-div'>
        <div className='cart-table-div'>
            <div className='cart-table'>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell id='table-head'>ITEM</TableCell>
                            <TableCell id='table-head'>PRICE (Ugx)</TableCell>
                            <TableCell id='table-head'>QUANTITY</TableCell>
                            <TableCell id='table-head'>TOTAL PRICE (Ugx)</TableCell>
                            <TableCell id='table-head'>ACTION</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isLoading ?
                                <CircularProgress/>

                            :(numberOfItems <= 0 || cartProducts === null || cartProducts === undefined)?
                                <div className='cart-no-items'>
                                    No Items
                                </div>
                            :
                                cartProducts.map((item, index)=>{
                                    return(
                                        <TableRow key={index}>
                                            <TableCell>
                                                <div className='cart-product-name-img'>
                                                    <img src={item.data.product.image ? item.data.product.image : images.drugs} alt='' width="70px"/>
                                                    {item.data.product.name}
                                                </div>
                                            </TableCell>
                                            <TableCell>{addCommas(item.data.product.price.toString())}</TableCell>
                                            <TableCell>
                                                {item.data.quantity !== '' && item.data.quantity !== undefined ? item.data.quantity : item.data.product.quantity}
                                            </TableCell>
                                            <TableCell>{addCommas(item.data.total_price.toString())}</TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={()=>handleUpdateOpen(item.data)}
                                                >
                                                    <ModeEditOutlineOutlinedIcon/>
                                                </IconButton>
                                                <IconButton
                                                    onClick={()=>handleClickOpen(item.data)}
                                                >
                                                    <DeleteForeverOutlinedIcon color='red'/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
                <div className='cart-checkout'>
                    <span id="cart-checkout-amount">Total Amount: Ugx {addCommas(totalAmount.toString())}</span>

                    <Button
                        variant='contained'
                        color='success'
                        size='small'
                        onClick={()=>createOrder()}
                    >
                        { checkoutLoading ? <CircularProgress/> : <>'Checkout' <ArrowRightAltOutlinedIcon/></>}               
                    </Button>

                </div>
            </div>
        </div>
        <div className='mob-cart-div'>
            {
                cartProducts === null || cartProducts === undefined ?
                    <CircularProgress/>
                :(numberOfItems <= 0) ?
                    <div className='cart-no-items'>
                        No Items
                    </div>
                :
                    cartProducts.map((item, index) => {
                        return(
                            <CartProductCard
                                key = {item.id}
                                name = {item.data.product.name}
                                price = {item.data.product.price}
                                quantity = {item.data.product.quantity}
                                ttl_price = {item.data.total_price}
                                handleUpdate = {()=>handleUpdateOpen(item.data)}
                                handleRemove = {()=>handleClickOpen(item.data)}
                            />
                        )
                    })   
            }
        </div>
        <div className='mob-cart-checkout'>
                <span id="mob-cart-checkout-amount">Total Amount: Ugx {addCommas(totalAmount.toString())}</span>
                <Button
                    variant='contained'
                    color='success'
                    size='small'
                    onClick={()=>createOrder()}
                >
                    { checkoutLoading ? <CircularProgress/> : 'Checkout'}
                    <ArrowRightAltOutlinedIcon/>
                </Button>

        </div>
    </div>
    <Dialog open={open}>
        <div className='cart-dialog-icon'>
            {
                removeFromCartSuccess ?
                    <TaskAltOutlinedIcon style={{color:'green'}}/>
                : removeFromCartFail?
                    <CancelOutlinedIcon />
                :
                    <InfoOutlinedIcon style={{color:'gray'}}/>
            }
        </div>
        {
            removeFromCartSuccess ?
                <div className='cart-dialog-text'>
                    <p>Successfully removed from cart. Returning...</p>
                </div>
            : removeFromCartFail ?
                <div>
                    <p>Operation Failed!</p>
                </div>
            :
                <>
                    <div className='cart-dialog-text'>
                        Are you sure you want to remove <p id='item-name'>{item && item.product.name}</p> from your cart?
                    </div>
                    <div className='cart-dialog-action'>
                        <Button onClick={handleClose} color='error'>Cancel</Button>
                        <Button 
                            variant='contained'
                            size='small'
                            onClick={()=>removeItem(item)}
                        >
                            {!isChangeLoading ? 'Confirm' : <CircularProgress size={25}/>}
                        </Button>
                    </div>
                </>
        }
    </Dialog>
    <Dialog open={openUpdate}>
        <div className='cart-dialog-icon'>
            {
                updateCartItemSuccess ?
                    <TaskAltOutlinedIcon style={{color:'green'}}/>
                : updateCartItemFail ?
                    <CancelOutlinedIcon/>
                :
                    <ModeEditOutlineOutlinedIcon style={{color:'gray'}}/>
            }
        </div>
        <div className='cart-dialog-text'>
            {
                updateCartItemSuccess ?
                    <div className='cart-update-current'>
                        <p>Successfully updated item.</p>
                    </div>
                : updateCartItemFail ?
                    <div className='cart-update-current'>
                        <p>Operation Failed.</p>
                    </div>
                :
                    <>
                        <form>
                            <div className='cart-update-current'>
                                <div className='cart-update-unit-price'>Price per unit (Ugx): {item && item.product.price}</div>
                                <div className='cart-update-unit-price'>Current Quantity: {item && item.product.quantity}</div>
                                <div className='cart-update-unit-price'>Total Price (Ugx): {item && item.total_price}</div>
                            </div>
                            <div className='cart-update-quantity'>
                                <div className='cart-update-quatity-form'>
                                    Enter new Quantity: 
                                    <TextField
                                        type='number'
                                        placeholder='0'
                                        size='small'
                                        onChange={(e)=>{
                                            setNewQuantity(e.target.value)
                                        }}                      
                                    />
                                </div>
                            </div>
                        </form>
                    </>
            }
        </div>
        <div className='cart-dialog-action'>
            {
                updateCartItemSuccess ?
                    <></>
                : updateCartItemFail ?
                    <></>
                :
                    <>
                        <Button onClick={handleClose} color='error'>Cancel</Button>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={()=>{updateCartItem(item, newQuantity)}}
                        >
                            {!isChangeLoading ? 'Confirm' : <CircularProgress size={25}/>}
                        </Button>
                    </>
            }
        </div>
    </Dialog>
    <Dialog open={openNoProfile}>
        
        <div className='cart-no-profile-dialog'>
            <WarningAmberOutlinedIcon style={{color:'orange', fontSize:'25px'}}/>
            <p>Your profile is incomplete.</p>
            <p>Please complete your profile to continue the checkout process</p>
            <NavLink to='/manage/account'>
                <Button variant='contained'>Set Profile <ArrowRightAltOutlinedIcon/></Button>
            </NavLink>
        </div>
    </Dialog>
    <Dialog open={openCheckingOut}>
        
        <div className='cart-no-profile-dialog'>
            processing order...
        </div>
    </Dialog>
    <Dialog open={openNotLoggedIn}>
        <div className='notLoggedInCloseIcon'>
            <IconButton
                onClick={()=>{
                    setOpenUpdate(false)
                    setOpen(false)
                    setOpenNotLoggedIn(false)
                }}
            >
                <CloseIcon/>
            </IconButton>
        </div>
        <div className='action'>
            <WarningAmberIcon fontSize='large' style={{color:'orange'}}/>
        </div>
        <div className='notLoggedIn'>
            You are not logged in.
            <NavLink to='/signin'>
                Sign in here
            </NavLink>
        </div>
    </Dialog>
    </>
  )
}



export default Cart