import { createSlice } from '@reduxjs/toolkit';

const dialogSlice = createSlice({
  name: 'search_dialog',
  initialState: {
    openSearch: false,
  },
  reducers: {
    openSearchDialog: (state) => {
      state.openSearch = true;
    },
    closeSearchDialog: (state) => {
      state.openSearch = false;
    },
  },
});

export const { openSearchDialog, closeSearchDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
