import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

const diseasesCol = collection(db, 'diseases')

export const getAllDiseases = createAsyncThunk('diseases', async()=>{
    try {
        const diseasesSnapShot = await getDocs(diseasesCol);
        const diseasesList = diseasesSnapShot.docs.map((doc) => doc.data());
        
        return diseasesList;
        
    } catch (error) {
        throw(error)
    }
});


const diseases_slice = createSlice({
    name:'diseases',
    initialState:{
        diseases:null,
        isLoading:false,
        error: null
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllDiseases.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(getAllDiseases.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.diseases = action.payload;
            state.error = null;
        })
        .addCase(getAllDiseases.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
        })
    }
})


export const diseaseActions = diseases_slice.actions;
export default diseases_slice;