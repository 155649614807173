// src/components/Footer.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Accordion,
  TextField,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from '@mui/material';

import '../assets/css/styles.css'
import images from '../assets/index';

import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem } from 'semantic-ui-react';
import { deliveries, email_val, products, tel, tel_number,
  ug_largest_health, visitors, email, know_us, about_us,
  contact_us, sign_up_as, our_policies, terms,
  privacy, editorial, return_policy, buy_medicine, health_plan, ug_largest_digital, all_access
} from '../utils';
import CountUp from 'react-countup';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CircleIcon from '@mui/icons-material/Circle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div className='footer-div'>
      <div className='partners'>
        <Grid container >
          <Grid item xs={12} md={12} lg={12}>
            <h3>OUR PARTNERS</h3>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6} md={3} lg={3}>
            <a href="https://viya.ug/" target="_blank" useRef="noopener noreferrer">
              <img src={images.viya} alt="" className='partner-logos-img'/>
            </a>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <a href="https://equitygroupholdings.com/ug/" target="_blank" useRef="noopener noreferrer">
              <img src={images.equity} alt="" className='partner-logos-img'/>
            </a>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <a href='https://mariestopes.or.ug/' target='_blank' useRef="noopener noreferrer">
              <img src={images.marieStopes} alt="" className='partner-logos-img'/>
            </a>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <a href='https://www.kf.or.ug/' target='_blank' useRef="noopener noreferrer">
              <img src={images.kabaka} alt="" className='partner-logos-img'/>
            </a>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <a to='https://www.health.go.ug/' target='_blank' useRef="noopener noreferrer">
              <img src={images.moh} alt="" className='partner-logos-img'/>
            </a>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <a href='https://www.nda.or.ug/' target='_blank' useRef="noopener noreferrer">
              <img src={images.nda} alt="" className='partner-logos-img'/>
            </a>
          </Grid>
        </Grid>
      </div>
      <div className='footer-tagline'>
        <h2>{ug_largest_health}</h2>
        <div className='footer-tags-div'>
          <div className='footer-tags'>
              <p>Medication</p>
              <p>Cosmetics</p>
              <p>Medical Experts</p>
          </div>
          <div className='footer-tags'>
              <p>Hospitals</p>
              <p>Laboratories</p>
              <p>Medical Centers</p>
          </div>
          <div className='footer-tags'>
              <p>E-Business</p>
              <p>Health Education</p>
              <p>E-Health training</p>
          </div>
        </div>
        <h5>{all_access}</h5>
      </div>
      <div className='footer-heat-feeder'>
        <div className='productss'>
          {/* <h1>7000+</h1> */}
          <h1><CountUp end={7000} duration={10}/></h1>
          <p>{products}</p>
        </div>
        <div className='visitors'>
        <h1><CountUp end={2000} duration={10}/></h1>
          <p>{visitors}</p>
        </div>
        <div className='deliveries'>
        <h1><CountUp end={1500} duration={10}/></h1>
          <p>{deliveries}</p>
        </div>
      </div>
      <div className='footer-tel-email'>
        <div className='footer-tel'>
          <LocalPhoneOutlinedIcon/>
          <p><span>{tel}</span>{tel_number}</p>
        </div>
        <div className='footer-email'>
          <EmailOutlinedIcon/>
          <p><span>{email} </span>{email_val}</p>
        </div>
      </div>
      <div className='footer-nav'>
        <div className='know-us'>
          <ul>
            <li className='footer-nav-title'>{know_us}</li>
            <li><NavLink>{about_us}</NavLink></li>
            <li><NavLink>{contact_us}</NavLink></li>
            <li><NavLink to='credits'>Credits</NavLink></li>
            <li><NavLink>{sign_up_as}</NavLink></li>
          </ul>
        </div>
        <div className='policies'>
          <ul>
            <li className='footer-nav-title'>{our_policies}</li>
            <li><NavLink to='terms&conditions'>{terms}</NavLink></li>
            <li><NavLink to='privacy-policy'>{privacy}</NavLink></li>
            <li><NavLink>{editorial}</NavLink></li>
            <li><NavLink>{return_policy}</NavLink></li>
          </ul>
        </div>
        <div className='services'>
          <ul>
            <li className='footer-nav-title'>Our Services</li>
            <li><NavLink>{buy_medicine}</NavLink></li>
            <li><NavLink>{health_plan}</NavLink></li>
          </ul>
        </div>
        <div className='connect'>
          <p>Connect with us</p>
          <div className='download-link'>
            <form>
              <label>Get the link to download the App</label>
              <TextField 
                id="outlined-basic"
                label="Enter phone number"
                variant="outlined"
                size='small'
              />

              <Button variant='contained' color='success'>Send Link</Button>
            </form>
          </div>
          <p>Social Links</p>
          <div className='footer-socials'>
            <img src={images.twitter} alt=''/>
            <img src={images.whatsapp} alt=''/>
            <img src={images.instagram} alt=''/>
            <img src={images.linkedin} alt=''/>
            <img src={images.youtube} alt=''/>
          </div>
        </div>
      </div>
      <MobFooterNav/>
      <div className='footer-rights'>
        <p>Copyright &copy; {currentYear} DrugsExpress. All Rights Reserved.</p>
      </div>
    </div>
  );
};


function MobFooterNav() {

  const accordionData = [
    {
      'title':'Know Us',
      'accData':[
        {
          'url':'/about-us',
          'option':'About Us'
        },
        // {
        //   'url':'',
        //   'option':'Contact Us'
        // },
        {
          'url':'signin',
          'option':'Sign Up'
        },
        // {
        //   'url':'',
        //   'option':'Sign Up as Health Worker'
        // },
      ]
    },
    {
      'title':'Our Services',
      'accData':[
        {
          'url':'/',
          'option':' Buy Medicine'
        },
        // {
        //   'url':'',
        //   'option':'Health Plan'
        // },
      ]
    },
    {
      'title':'Our Policies',
      'accData':[
        {
          'url':'/terms&conditions',
          'option':'Terms and Conditions'
        },
        {
          'url':'privacy-policy',
          'option':'Privacy Policy'
        },
        // {
        //   'url':'',
        //   'option':'Editorial Policy'
        // },
        // {
        //   'url':'',
        //   'option':'Return Policy'
        // },
      ]
    },
  ]
  return (
    <div className='mob-footer'>
      {
        accordionData.map((item, index)=>{
          return(
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography gutterBottom>{item.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {
                  item.accData.map((itemOption, index)=>{
                    return(
                      <MenuItem key={index} className={index % 2 !== 0 ? 'mob-footer-menu-item' : 'mob-footer-menu-item-alt'}>
                        <NavLink to={itemOption.url} className='mob-footer-nav-link'>
                          {itemOption.option}  
                        </NavLink>
                      </MenuItem>
                    )
                  })
                }
              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </div>
  )
}


export default Footer;
