import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { getProductByCategoryId } from '../store/products_slice';
import { CircularProgress, Grid } from '@mui/material';
import ProductCard from '../components/productCard';
import images from '../assets/index.js'
import BreadCrumb from '../components/breadCrumb';
import '../assets/css/categoryMedicine.css';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';


function CategoryMedicine() {
    const {disease} = useParams();
    const dispatch = useDispatch();

    const [products, setProducts] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [title, setTitle] = useState('')

    useEffect(()=>{
      setIsLoading(true)
      dispatch(getProductByCategoryId(disease))
      .then(res => {
        if(res['meta']['requestStatus'] === 'fulfilled'){
          setTitle(res.payload[0].category_name)
          setProducts(res.payload)
          setIsLoading(false)
        }
      })
      setIsLoading(false)
    },[])
    


  return (
    <div className='product-div'>
      <div className='product-title'>
        {title === '' ? '' : <h5>{title}</h5>}
      </div>
      <div className='grid-div'>
        <Grid container spacing={2}>
          {
            products === null || isLoading ?
              <Grid item xs={12} md={12}>
                <div className='progress'>
                  <CircularProgress/>
                </div>
              </Grid>
            :
              products.map((item, index)=>{
                return(
                  <Grid item xs={6} sm={3} md={2} key={index}>
                    <ProductCard
                      id={item.id}
                      image={item.images.length > 0 ? item.images[0] : '' }
                      discount={item.discount}
                      is_discounted={item.has_flash_sale}
                      title={item.name}
                      newPrice={item.has_flash_sale ? item.flash_sale_b2c_price : ''}
                      originalPrice={item.b2c_price}
                    />
                  </Grid>
                )
              })
          }
        </Grid>
      </div>
    </div>
  )
}

export default CategoryMedicine