import React, {useEffect} from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { logoutAsync } from '../store/auth-slice';


function Logout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(()=>{
        localStorage.clear()
        try{
          dispatch(logoutAsync())
          navigate('/')
        }catch(error){
          console.log('An error Occurred. Logout Failed')
        }
    })
  return (
    <div>Logout</div>
  )
}

export default Logout