import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { collection, getDocs, addDoc, 
    deleteDoc,query, where, doc,
    updateDoc } from 'firebase/firestore';
import { db } from '../firebase';


const cartItemCol = collection(db, 'cart_items');

export const getCartItemsAsync = createAsyncThunk('cart', async (user_email) => {
    try {
      
      const q = query(cartItemCol, where("owner_email", "==", user_email), where("is_order", "==", false));
      const querySnapshot = await getDocs(q);
      let tp = 0

      const cartList= querySnapshot.docs.map((doc) => {

        tp = tp + doc.data().total_price

        return(
            {   
                data: doc.data(),
                id: doc.id,
                quantity: doc.data().product.quantity,
                price:doc.data().product.price,
                name:doc.data().product.name,
                units:doc.data().product.units,
                image:doc.data().product.image
            }
        )
      });
  
      return {cartList: cartList, total_p: tp}
  
    } catch (error) {
      throw error;
    }
  });

export const getOrderCartItemsAsync = createAsyncThunk('cart-order', async (user_email) => {
    try {
        
        const q = query(cartItemCol, where("owner_email", "==", user_email), where("is_order", "==", true));
        const querySnapshot = await getDocs(q);
        const cartList= querySnapshot.docs.map((doc) => {
            return(
                {
                    data: doc.data(),
                    id: doc.id
                }
            )
        });

        return cartList

    } catch (error) {
        throw error;
    }
});

export const createCartItemAsync = createAsyncThunk('create-cart-item', async(cartItem, {dispatch})=>{
    try{
        const q = query(cartItemCol,
            where("product", "==", cartItem.product),
            where("owner_email", "==", cartItem.owner_email),
            where('is_order', '==', false)
        );
        const querySnapshot = await getDocs(q);


        if (querySnapshot.size === 0) {

            await addDoc(cartItemCol, cartItem);

            return {message:'Item added to cart', variant:'success', total_p: cartItem.total_price}
        } else {
            return {message: 'Item already exists in the cart!', variant:'warning', total_p: 0}
        }

    }
    catch(error){
        throw error;
    }

})

export const changeItemQuantityAsync = createAsyncThunk('change-item-quantity', async(params)=>{
    try{
        const q = query(cartItemCol, where('owner_email', '==', params.cartItem.owner_email), where('product', '==', params.cartItem.product));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.docs.length > 0) {
            
          const id = querySnapshot.docs[0].id;
          const docRef = doc(cartItemCol, id)

          await updateDoc(docRef, {
            quantity: params.qty,
            total_price: params.total_price
          });

          return params.total_price;
        } 

    }
    catch (error){
        throw error;
    }

})

export const changeItemOrderAsync = createAsyncThunk('change-item-order', async(user_email)=>{
    try{
        const q = query(cartItemCol, where('owner_email', '==', user_email), where('is_order', '==', false));
        const querySnapshot = await getDocs(q);
    
        if (querySnapshot.docs.length > 0) {
            for(let i = 0; i < querySnapshot.docs.length; i++){
                const id = querySnapshot.docs[i].id;
                const docRef = doc(cartItemCol, id)
                
                await updateDoc(docRef, {
                  is_order:true
                });
            }

        } 

    }
    catch (error){
        throw error;
    }

})

export const removeCartItemAsync = createAsyncThunk('remove-cart-item', async(cartItem)=>{
    try{
        let id = null;
        const q = query(cartItemCol,
            where("owner_email", "==", cartItem.owner_email),
            where("product", "==", cartItem.product),
            where("is_order", "==", false)
        );
        const querySnapshot = await getDocs(q)

        querySnapshot.docs.map((doc)=> id = doc.id)

        const docRef = doc(cartItemCol, id);

        await deleteDoc(docRef)

    }
    catch (error){
        throw error;
    }
})



const cart_slice = createSlice({
    name:'cart',
    initialState:{products: [], isLoading: false, error: null, cartNumberofProducts: 0, message: null, totalPrice: 0.0},
    reducers:{
        addToCart(state, action){
            const newItem = action.payload
            const existingItem = state.products.find((item)=>item.product.generic_name === newItem.product.generic_name)
            if(existingItem){
                existingItem.quantity+= 1
                existingItem.price += newItem.product.b2b_price
            }
            else{
                state.products.push(newItem)
                state.cartNumberofProducts += 1
            }
        },
        sendCartMessage(state, action){
            state.message = action.payload
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(createCartItemAsync.pending, (state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(createCartItemAsync.fulfilled, (state, action)=>{
            const mess = action.payload
            state.isLoading = false;
            if(mess.message === 'Item added to cart'){
                state.cartNumberofProducts += 1;
            }
            state.totalPrice += action.payload.total_p
            state.error = null
        })
        .addCase(createCartItemAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
        })
        .addCase(getCartItemsAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getCartItemsAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.products = action.payload.cartList
            state.cartNumberofProducts = action.payload.cartList.length
            state.totalPrice = action.payload.total_p
            state.error = null
        })
        .addCase(getCartItemsAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(changeItemQuantityAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(changeItemQuantityAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null
        })
        .addCase(changeItemQuantityAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(removeCartItemAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(removeCartItemAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.cartNumberofProducts -= 1;
            state.error = null
        })
        .addCase(removeCartItemAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(getOrderCartItemsAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getOrderCartItemsAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.products = action.payload
            state.error = null
        })
        .addCase(getOrderCartItemsAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(changeItemOrderAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(changeItemOrderAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null
        })
        .addCase(changeItemOrderAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
    }
})


export const cartActions = cart_slice.actions;
export default cart_slice;