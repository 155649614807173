import React, { useEffect, useState } from 'react'
import '../assets/css/manageAccount.css'
import { Button, CircularProgress, FormControl, FormControlLabel, 
    FormLabel, Modal, Paper, Radio, RadioGroup, TextField,
InputAdornment, 
Alert} from '@mui/material'
import BreadCrumb from '../components/breadCrumb.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, updateProfile } from '../store/auth-slice.js';
import { useForm } from 'react-hook-form';
import { capitalizeFirstLetter } from '../utils.js';
import { NavLink, useNavigate } from 'react-router-dom';

function ManageAccount() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setFNameError(false)
        setSNameError(false)
        setPhoneError(false)
        setAddressError(false)
        setIsSubmitting(false)
    };
    const [profile, setProfile] = useState(null);
    const dispatch = useDispatch();
    const {handleSubmit, register} = useForm();
    const [date, setDate] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [radioValue, setRadioValue] = useState('none');
    const [fNameError, setFNameError] = useState(false);
    const [sNameError, setSNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [isUnderAge, setIsUnderAge] = useState(false);
    const [dateError, setDateError] = useState(false);

    const localEmail = localStorage.getItem('email')
    const localUID = localStorage.getItem('user_id')
    const localLoggedIn = localStorage.getItem('isLoggedIn')
   

    
    useEffect(()=>{
        let currentDate = new Date().getTime();
        if(localLoggedIn){
            dispatch(getUserProfile(localEmail))
            .then(res => {

                if(res.type === "auth/profile/fulfilled"){
                    let dob = new Date(res.payload.date_of_birth)
                    let diff = currentDate - dob;
                    const ageDate = new Date(diff)
                    const age = Math.abs(ageDate.getFullYear() - 1970);

                    const first_name = res.payload.first_name !== undefined ? res.payload.first_name : ''
                    const second_name = res.payload.second_name !== undefined ? res.payload.second_name : ''

                    const p = {
                        name: capitalizeFirstLetter(first_name) + ' ' + capitalizeFirstLetter(second_name),
                        birthday: dob.toLocaleDateString(),
                        gender: capitalizeFirstLetter(res.payload.gender),
                        phone: res.payload.phone_number,
                        age: isNaN(age) ? '' : age,
                        address: res.payload.address
                    }
                    setProfile(p)
                }
            })
        }
        else{
            navigate('/signin')
        }
    },[profile])

    const onRadioChange = (event)=>{
        event.preventDefault()

        setRadioValue(event.target.value)
    }

    const onSubmit = (data)=>{
        setIsSubmitting(true)

        

        var regex = /[a-zA-Z!@#$%^&*()_+{}\[\]:;<>,.?\/\\|~`'"\-=]/;

        if(data.fname.trim() === ''){
            setFNameError(true)
            setIsSubmitting(false)

        }else if(data.sname.trim() === ''){
            setSNameError(true)
            setIsSubmitting(false)
        
        }else if(regex.test(data.tel.trim()) || data.tel.trim() === ''){
            setPhoneError(true)
            setIsSubmitting(false)
        
        }else if(data.address.trim() === ' '){
            setAddressError(true)
            setIsSubmitting(false)
        }
        else{
            const currentDate = new Date()

            const time_diff = currentDate - date
            const age = Math.floor(time_diff / (1000 * 60 * 60 * 24 * 365.25));

            if(age < 18 ){
                setIsUnderAge(true)
                setIsSubmitting(false)
                setOpen(false)
                
                setTimeout(()=>{
                    setIsUnderAge(false)
                }, 7000)
            }
            else{

                if(date === null){
                    setDateError(true)
                    setIsSubmitting(false)
                }
                else{
                    const p = {
                        email:localEmail,
                        first_name:data.fname,
                        last_name:data.sname,
                        gender: radioValue,
                        phoneNumber:'+256'+data.tel,
                        address: data.address,
                        date_of_birth: date.$d.getTime()
                    }
            
                    dispatch(updateProfile(p))
                    .then(res =>{
                        if(res.type === "auth/update/profile/fulfilled"){
                            setProfile(res.payload)
                            setIsSubmitting(false)
                            setOpen(false)
                        }
                    })
                }
            }
        }

        setIsSubmitting(false)
    }

    return (
    <>
    <BreadCrumb
        crumbs={[
        {text:'Profile', url:'/manage/account'}
    ]}/>
    {
        isUnderAge ? 
            <Alert severity='error' >Drugs are not for sale to persons under 18 years. Read more - <NavLink>Terms and Conditions</NavLink></Alert>
        :
            ''
    }
    <div className='manage-account-wrapper'>
        <div className='manage-div'>
            <Paper className='profile-paper' elevation={3}>
                {/* <div className='profile-image'>
                    {
                        profile && profile.image !== undefined ?
                            <img src={images.face} alt=''/>
                        :
                            <img src={images.user1} alt=''/>
                    }
                </div> */}
                <div className='edit-profile-button'>
                    <h3>Profile</h3>
                    <Button onClick={handleOpen} variant='contained'><EditOutlinedIcon/>Set Profile</Button>
                </div>
                <div className='profile-data'>
                    <div className='profile-data-entry'>
                        <div className='profile-data-label-div'>
                            <h5><span id='profile-data-label'>Name:</span></h5>
                        </div>
                        <div className='profile-data-d'>
                            <p>{profile && profile.name !== undefined ? profile.name : <span id='empty'>{'_'}</span>}</p>
                        </div>
                    </div>
                    <div className='profile-data-entry'>
                        <div className='profile-data-label-div'>
                            <h5><span id='profile-data-label'>Age:</span></h5>
                        </div>
                        <div className='profile-data-d'>
                            <p> {profile && profile.age !== '' ? profile.age : <span id='empty'>{'_'}</span>}</p>
                        </div>
                    </div>
                    <div className='profile-data-entry'>
                        <div className='profile-data-label-div'>
                            <h5><span id='profile-data-label'>Gender: </span></h5> 
                        </div>
                        <div className='profile-data-d'>
                            <p>{profile && profile.gender !== undefined ? profile.gender : <span id='empty'>{'_'}</span>}</p>
                        </div>
                    </div>
                    <div className='profile-data-entry'>
                        <div className='profile-data-label-div'>
                            <h5><span id='profile-data-label'>Date of birth: </span></h5> 
                        </div>
                        <div className='profile-data-d'>
                            <p>{profile && profile.birthday !== undefined ? profile.birthday : <span id='empty'>{'_'}</span>}</p>
                        </div>
                    </div>
                    <div className='profile-data-entry'>
                        <div className='profile-data-label-div'>
                            <h5><span id='profile-data-label'>Phone: </span> </h5> 
                        </div>
                        <div className='profile-data-d'>
                            <p>{profile && profile.phone !== undefined ? profile.phone : <span id='empty'>{'_'}</span>}</p>
                        </div>
                    </div>
                    <div className='profile-data-entry'>
                        <div className='profile-data-label-div'>
                            <h5><span id='profile-data-label'>Address:</span></h5> 
                        </div>
                        <div className='profile-data-d'>
                            <p>{profile && profile.address !== undefined ? profile.address : <span id='empty'>{'_'}</span>}</p>
                        </div>
                    </div>
                </div>
            </Paper>
            <div className='edit-profile'>
                <form >
                    <div className='edit-profile-name'>
                        <FormControl>
                            <TextField
                                size='small'
                                type='text'
                                label='Last Name'
                                fullWidth
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                size='small'
                                type='text'
                                label='First Name'

                            />
                        </FormControl>
                    </div>
                    <div className='edit-profile-tel'>
                        <TextField
                            label='Phone Number'
                            type="text"
                            size='small'
                        />
                    </div>
                    <div className='edit-profile-address'>
                        <TextField
                            size='small'
                            type="text"
                            label="Address"
                            placeholder='e.g Kampala, Wandegeya, mosque road'
                        />
                    </div>
                    <Button variant='contained' color='success'>
                        Submit
                    </Button>
                </form>
            </div>
        </div>
        <div className='manage-account-similar-products'>

        </div>
    </div>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='mob-edit-profile-modal'
    >
        <Paper className='mob-edit-profile-paper'>
            <div className='profile-title'>
                <p>SET PROFILE</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='mob-edit-profile-entry'>                    
                    <TextField
                        size='small'
                        type='text'
                        label='Surname'
                        fullWidth
                        error={sNameError}
                        helperText={ sNameError ? 'Incorrect Entry' : ''}
                        {...register('sname',{required:true})}
                    />
                </div>
                <div className='mob-edit-profile-entry'>
                    <TextField
                        size='small'
                        type='text'
                        label='First Name'
                        fullWidth
                        error={fNameError}
                        helperText={fNameError ? 'Incorrect Entry' : ''}
                        {...register('fname',{required:true})}
                    />
                </div>
                <div className='mob-edit-profile-entry'>
                    <TextField
                        label='Phone Number'
                        type="text"
                        size='small'
                        fullWidth
                        error={phoneError}
                        helperText={phoneError ? 'Incorrect Entry' : ''}
                        {...register('tel',{required:true, maxLength:11})}
                        InputProps={{
                            startAdornment:<InputAdornment position="start">+256</InputAdornment>,
                        }}
                    />
                </div>
                <div className='mob-edit-profile-entry'>
                    <TextField
                        size='small'
                        type="text"
                        label="Address"
                        placeholder='e.g Kampala, Wandegeya, mosque road'
                        fullWidth
                        error={addressError}
                        helperText={addressError ? 'Incorrect Entry' : ''}
                        {...register('address',{required:true})}
                    />
                </div>
                <div className='mob-edit-profile-entry'>
                    <FormControl>
                        <FormLabel id="radio-buttons-group-label">Gender</FormLabel>
                        <RadioGroup 
                            aria-labelledby="demo-radio-buttons-group-label"
                            name='radio-buttons-group'
                            onChange={onRadioChange}
                        >
                            <FormControlLabel value='female' control={<Radio/>} label='Female'/>
                            <FormControlLabel value='male' control={<Radio/>} label='Male'/>
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className='mob-edit-profile-entry'>
                    {dateError && <p style={{color:'red'}}>*Date of Birth required.</p>}
                    Date of Birth:
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <MobileDatePicker
                                onChange={(newValue)=>setDate(newValue)}
                                openTo='year'
                                // minDate={min_date}
                            /> 
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                {/* <div className='mob-edit-profile-entry'>
                    Upload Image:<span id='mob-edit-label'> (not required)</span>
                    <input type='file' />
                </div> */}
                <Button variant='contained' color='success' type='submit'>
                    { isSubmitting ? <CircularProgress/> : 'Set Profile' }
                </Button>
            </form>
        </Paper>
    </Modal>
    </>
  )
}

export default ManageAccount