import React, {Suspense, lazy, useRef, useState} from 'react'
import '../assets/css/home.css';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Paper, Badge, Button, IconButton } from '@mui/material';

import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { welcome, ug_largest_digital, dskCarousel, mobCarousel, tabCarousel,
} from '../utils';
import images from '../assets/index.js';
import Banner from '../components/banner.js';
import LoadingSkeleton from '../components/loadingSkeleton.js';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';
import StickyDropDown from '../components/stickyDropDown.js';
import { openSearchDialog } from '../store/searchDialog_slice';

const FP = lazy(()=>import('../components/categories/FP.js'));
const StomachDisorder = lazy(()=>import('../components/categories/stomachDisorder.js'));
const CoughFlu = lazy(()=>import('../components/categories/coughFlu.js'));
const Malaria = lazy(()=>import('../components/categories/malaria.js'));
const EarNoseMouth = lazy(()=>import('../components/categories/earNoseMouth.js'));
const SexualWellness = lazy(()=>import('../components/categories/sexualWellness.js'));
const Diabetes = lazy(()=>import('../components/categories/diabetes.js'))
const PainFever = lazy(()=>import('../components/categories/painFever.js'));
const BabyCare = lazy(()=>import('../components/categories/babyCare.js'));
const Allergy = lazy(()=>import('../components/categories/allergy.js'));
const Hemmorhoids = lazy(()=>import('../components/categories/hemmorrhoids.js'));
const AntiFungal = lazy(()=>import('../components/categories/antiFungal.js'));
const AntiParasitic = lazy(()=>import('../components/categories/antiParasitic.js'));
const HealthDevices = lazy(()=>import('../components/categories/healthDevices.js'));
const CentralNervous = lazy(()=>import('../components/categories/centralNervous.js'));
const GenitalUrinary = lazy(()=>import('../components/categories/genitalUrinary.js'));
const Vitamins = lazy(()=>import('../components/categories/vitaminsMinerals.js'));
const Cosmetics = lazy(()=>import('../components/categories/cosmetics.js'));

function HomePage() {

  const [open, setOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isTalkToDoc, setIsTalkToDoc] = useState(false)
  const [toggleTalkToDoc, setToggleTalkToDoc] = useState(true);



  const auth = useSelector(state => state.auth)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const cartItemNum = useSelector(state => state.cart.cartNumberofProducts)
  const wishlistNumItmem = useSelector(state => state.wishlist.wishListNumOfItems)
  
  const email = localStorage.getItem('email')
  const loggedIn = localStorage.getItem('isLoggedIn')
  const localName = localStorage.getItem('name')

  const [cartNumber, setCartNumber] = useState(parseInt(localStorage.getItem('cartNum')) || 0);

  const name = auth.profile !== null && auth.profile !== undefined && auth.profile.second_name !== undefined && auth.profile.first_name !== undefined ? 
    auth.profile.second_name + ' ' + auth.profile.first_name : '';

  
  const dispatch = useDispatch();

  const scrollCategoriesDiv = useRef()

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  const handleScroll = () => {
    if (window.scrollY >= 200) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  const openSrch = ()=>{
    dispatch(openSearchDialog())
  }

  React.useEffect(()=>{
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[ isLoggedIn, auth,])

 
  return (
    <>
      <div className='home-wrapper'>
        <div className={`sticky-nav ${isFixed ? 'fixed' : ''}`}>
          <Paper elevation={2} className='sticky-nav-paper' square>
            <div className='sticky-nav-logo'>
              <NavLink to="/">
                <h3>DrugsExpress</h3>
              </NavLink>
            </div>
            <div className='sticky-nav-browse'>
              <div
                className='sticky-browse'
                onMouseEnter={()=>{
                  setIsVisible(true)
                }}
                onMouseLeave={()=>{
                  setIsVisible(false)
                }}
              >
                {
                  isVisible ?
                    <MoreHorizIcon fontSize='large'/>
                  :
                    <MenuIcon fontSize='large'/>
                }
                Browse Categories
              </div>
              {
                isVisible ?
                  <StickyDropDown
                    onEnter={()=>{
                      setIsVisible(true)
                    }}
                    onLeave={()=>setIsVisible(false)}
                  />
                :
                  <></>
              }
            </div>
            <div className='sticky-nav-search-div'>
              <NavLink
                to="search"
                style={({isActive})=>{
                  return {
                    textDecoration: isActive ? 'none' : 'none'
                  }
                }}
              >
                <div className='sticky-nav-search'>
                  <SearchOutlinedIcon/>
                  Search for medicines
                </div>
              </NavLink>             
            </div>
            <div className={loggedIn ? 'sticky-nav-navigation-in' : 'sticky-nav-navigation'}>
              {
                !loggedIn ?
                  <div className='sticky-nav-navigation-opt-auth'>
                    <NavLink to="signin">
                      <img src={images.user_auth} alt='' width="25"/>
                      Login/Sign Up
                    </NavLink>
                  </div>
                :
                  <></>
              }
              <div className='sticky-nav-navigation-opt-cart'>
                <NavLink to='/cart'>
                  <Badge
                    badgeContent={
                      loggedIn || loggedIn ? 
                        cartItemNum 
                      : (!loggedIn || loggedIn) === undefined && cartNumber?
                          cartNumber
                      : 0
                    }
                    color='primary'
                  >
                    <img src={images.shopping_cart} alt="" width="25"/>
                  </Badge>
                  Cart
                </NavLink>
              </div>
              {
                loggedIn ?
                  <div className='sticky-nav-navigation-opt-orders'>
                    <NavLink to="/transactions">
                      <img src={images.list} alt="" width="25"/>
                      Orders
                    </NavLink>
                  </div>
                :
                  <></>
              }
              <div className='sticky-nav-navigation-opt-wishlist'>
                <NavLink to="/wishlist">
                  <Badge badgeContent={loggedIn ? wishlistNumItmem : 0}>
                    <img src={images.heart} alt="" width="25"/>
                  </Badge>
                  Wishlist
                </NavLink>
              </div>
              {
                loggedIn ?
                  <div className="sticky-nav-navigation-opt-logout">
                    <NavLink to="/signout">
                      <LogoutIcon style={{color:'#EE1C22', marginRight:"10px"}}/>
                      Sign out
                    </NavLink>
                  </div>
                :
                  <></>
              }
            </div>
          </Paper>
        </div>
        <div className={`sticky-talk-to-doc ${isTalkToDoc ? 'talk-to-doc-fixed':''}`}>
          <Paper 
            className={toggleTalkToDoc ? "talk-to-doc-open" : "talk-to-doc-closed"}
            onClick={()=>{
              const url = `https://wa.me/+256789572345?text=Welcome to DrugsExpress. A doctor will be available.`;
              window.open(url, '_blank');
            }}
            elevation={2}
          >
              <img src={images.whatsapp} alt=""/> Click here to talk to a Doctor
          </Paper>
          <Paper elevation={2} className={!toggleTalkToDoc ? 'talk-to-doc-toggle' : 'talk-to-doc-toggle-off'}>
            <IconButton
              size='small'
              onClick={()=>{
                setToggleTalkToDoc(!toggleTalkToDoc);
              }}
            >
              <ChatOutlinedIcon fontSize="medium"/>
            </IconButton>
          </Paper>
          <div className={toggleTalkToDoc ? "close-talk-to-doc" : "close-talk-to-doc-off"}  onClick={()=>setToggleTalkToDoc(false)}>
            <DisabledByDefaultOutlinedIcon fontSize='small'/>
          </div>
        </div>
        <div className='carousel-div'>
           <div className='mob-welcome'>
            {
              (isLoggedIn || loggedIn) && auth.user !== null ?
                (
                  name === '' ? <p>Welcome <span>{auth.user.email}</span></p> : <p>Welcome <span>{name}</span></p>
                )
              : (isLoggedIn || loggedIn) && auth.user === null ?
                  ( localName !== undefined ? <p>Welcome <span>{localName}</span></p> : <p>Welcome <span>{email}</span></p>)
              :
                (
                  <>
                    {welcome}
                    <p>{ug_largest_digital}</p>
                  </>
                )
            }
          </div>
          <div className='mob-search-comp-div'>
            <div
              className='mob-search-comp-button'
              onClick={openSrch}
            >
              <SearchOutlinedIcon/> Search for Medicine and Health Products
            </div>
          </div>
          <div style={{
            fontWeight:'bold',
            paddingLeft:'10px',
            paddingTop:'10px',
            fontSize:'20px'}}
            className='mb-popular-cat-label'  
          >
            Popular Categories
          </div>
          <div className='mob-spec-navigation'>
            <NavLink to={{pathname:`v9Ro34HVrZGfZqskZCmi/Pain; Fever  /products`}}>
              <div>
                <Paper className='mob-navigation'>
                  <img src={images.pain} alt='' width='100' height='150'/>
                </Paper>
              </div>
            </NavLink>
            <NavLink to='family-planning'>
              <div >
                <Paper className='mob-navigation-1' >
                  <img src={images.family_planning} alt='' width='100' height='150'/>
                </Paper>
              </div>
            </NavLink>
            <NavLink >
              <div>
                <Paper className='mob-navigation-2' onClick={()=>setOpen(true)}>
                  <img src={images.wellness} alt='' width='100' height='150'/>
                </Paper>
              </div>
            </NavLink>
            <NavLink to='health-devices'>
              <div>
                <Paper className='mob-navigation-3'>
                  <img src={images.health_devices} alt='' width='100' height='150'/>
                </Paper>
              </div>
            </NavLink>
            <NavLink to='vitamins-nutrition'>
              <div>
                <Paper className='mob-navigation-4'>
                  <img src={images.vitamins_nutrition} alt='' width='100' height='150'/>
                </Paper>
              </div>
            </NavLink>
            <NavLink to='personal-care'>
              <div>
                <Paper className='mob-navigation-5'>
                  <img src={images.personale_care} alt='' width='100' height='150'/>
                </Paper>
              </div>
            </NavLink>
          </div>
         <div className='highlight-div'>
            <div className='home-carousel-div'>
               <Slider {...carouselSettings}>
                 {
                    dskCarousel.map((item, index)=>{
                        return(
                        <Paper className='carousel-paper' key={item}>
                            <img src={item} alt='' className='carousel-img'/>
                        </Paper>);
                    })
                } 
              </Slider> 
              
            </div>
          </div>
          <div className='mob-highlight-div'>
            <div className='home-carousel-div'>
               <Slider {...carouselSettings}>
                 {
                    mobCarousel.map((item, index)=>{
                        return(
                        <Paper className='carousel-paper' key={item}>
                            <img src={item} alt='' className='carousel-img'/>
                        </Paper>);
                    })
                } 
              </Slider> 
              
            </div>
          </div>
          <div className='tab-highlight-div'>
            <div className='home-carousel-div'>
               <Slider {...carouselSettings}>
                 {
                    tabCarousel.map((item, index)=>{
                        return(
                        <Paper className='carousel-paper' key={item}>
                            <img src={item} alt='' className='carousel-img'/>
                        </Paper>);
                    })
                } 
              </Slider> 
              
            </div>
          </div>
        </div>
        <div   className='home-body'>
          <Banner phoneNumber="+256706404040" message="Welcome to Drugs Express. How may we be of service ?"/>

          <Suspense fallback={<LoadingSkeleton/>}>
            <FP/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <StomachDisorder/>
          </Suspense>
          

          <Suspense fallback={<LoadingSkeleton/>}>
            <CoughFlu/>
          </Suspense>

          {/* <div>
            <img src={images.} alt=''/>
          </div> */}

          <Suspense fallback={<LoadingSkeleton/>}>
            <Malaria/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <Diabetes/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <SexualWellness/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <EarNoseMouth/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <PainFever/>
          </Suspense>

          {/* <Suspense fallback={<LoadingSkeleton/>}>
            <BabyCare/>
          </Suspense> */}

          <Suspense fallback={<LoadingSkeleton/>}>
            <AntiParasitic/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <Cosmetics/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <Vitamins/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <AntiFungal/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <Allergy/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <CentralNervous/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <GenitalUrinary/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <Hemmorhoids/>
          </Suspense>

          <Suspense fallback={<LoadingSkeleton/>}>
            <HealthDevices/>
          </Suspense>

          {/* <div className='home-offer-one'>
            <Paper elevation={2}>
              <img src={images.condoms} alt=''/>
              <p></p>
            </Paper>
          </div> */}


        </div>
        <div className='stop-nav'></div>
      </div>
    </>
  )
}


export default HomePage
