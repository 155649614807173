import React from 'react'
import '../assets/css/notFound.css';
import images from '../assets/index';
import { Button, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';


function NotFound() {
  return (
    <div className='notFound'>
        <Paper elevation={3} className='notFoundPaper'>
        <img src={images.not_found} alt='' width='100px'/>
        <h3>Oops!</h3>
        <p>Looks like you followed a broken link. We can't find the page you are looking for.</p>
        <NavLink to='/'>
            <Button color='primary'><KeyboardBackspaceOutlinedIcon/> BACK TO HOME </Button>
        </NavLink>
        </Paper>
    </div>
  )
}

export default NotFound