import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { collection, getDocs, query, where, limit, startAfter, orderBy, connectFirestoreEmulator } from 'firebase/firestore';
import { db } from '../firebase';


const productsCol = collection(db, 'products');
const categoryCol = collection(db, 'product_categories')



export const getProductsAsync = createAsyncThunk('products/all', async () => {
    try {
  
      const productsSnapShot = await getDocs(productsCol);
      const productList = productsSnapShot.docs.map((doc) => doc.data());

      return productList.slice(0, 100);

    } catch (error) {
      throw error;
    }
  });

export const getDiabetesProductsAsync = createAsyncThunk('products/diabetes', async ({page, pageSize, lastVisibleDoc}) => {
  try {

    const diabetesProductQuery = query(productsCol, where("category", "==", 'i8TBAoaBfFoNkuiuQRqK'), limit(pageSize))
    if(lastVisibleDoc){
      diabetesProductQuery = query(diabetesProductQuery, startAfter(lastVisibleDoc))
    }
    const dSnapShot = await getDocs(diabetesProductQuery);
    const diabetesProductsList= dSnapShot.docs.map((doc) => doc.data());
    const lastVisible = dSnapShot[ dSnapShot.length - 1 ]

    return {diabetesProductsList, lastVisible}

  } catch (error) {
    throw error;
  }
});

export const getFamilyPlanningAsync = createAsyncThunk('products/family-planning', async ({page, pageSize, lastVisibleDoc}) => {
  try {

    const FpProductQuery = query(productsCol, where("category", "==", 'RAUs4urbgYKECTZ6C21s'), limit(pageSize))

    if(lastVisibleDoc){
      FpProductQuery = query(FpProductQuery, startAfter(lastVisibleDoc))
    }
    const FpSnapShot = await getDocs(FpProductQuery);
    const familyPlanningProductsList= FpSnapShot.docs.map((doc) => doc.data());
    const lastVisible = FpSnapShot[FpSnapShot.length - 1]
    
    return {familyPlanningProductsList, lastVisible}

  } catch (error) {
    throw error;
  }
});

export const getEarNoseMouthProductsAsync = createAsyncThunk('products/ear-nose-mouth', async ({page, pageSize, lastVisibleDoc}) => {
  try {

    const earNoseMouthProductQuery = query(productsCol, where("category", "==", "XaobyboPpcrVhlKcb1W6"), limit(pageSize))

    if(lastVisibleDoc){
      earNoseMouthProductQuery = query(earNoseMouthProductQuery, startAfter(lastVisibleDoc))
    }
    const earNoseMouthSnapShot = await getDocs(earNoseMouthProductQuery);
    const earNoseMouthProductsList= earNoseMouthSnapShot.docs.map((doc) => doc.data());
    const lastVisible = earNoseMouthSnapShot[earNoseMouthSnapShot.length - 1]

    return {earNoseMouthProductsList, lastVisible}

  } catch (error) {
    throw error;
  }
});

export const getPainAndFeverAsync = createAsyncThunk('products/pain-and-fever', async ({page, pageSize, lastVisibleDoc}) => {
  try {

    const painAndFeverQuery = query(productsCol, where("category", "==", "v9Ro34HVrZGfZqskZCmi"), limit(pageSize))

    if(lastVisibleDoc){
      painAndFeverQuery = query(painAndFeverQuery, startAfter(lastVisibleDoc))
    }
    const painAndFeverSnapShot = await getDocs(painAndFeverQuery);
    const painAndFeverProductsList= painAndFeverSnapShot.docs.map((doc) => doc.data());

    const lastVisible = painAndFeverSnapShot[painAndFeverSnapShot.length - 1]

    return {painAndFeverProductsList, lastVisible}

  } catch (error) {
    throw error;
  }
});

export const getCosmeticsAsync = createAsyncThunk('products/cosmetics', async ({page, pageSize, lastVisibleDoc}) => {
  try {

    const cosmeticsQuery = query(productsCol, where("category", "==", "SuLMzNVVtSGKhmOOhdXz"), limit(pageSize))

    if(lastVisibleDoc){
      cosmeticsQuery = query(cosmeticsQuery, startAfter(lastVisibleDoc))  
    }
    const cosmeticsSnapShot = await getDocs(cosmeticsQuery);
    const cosmeticsProductsList= cosmeticsSnapShot.docs.map((doc) => doc.data());

    const lastVisible = cosmeticsSnapShot[cosmeticsSnapShot.length - 1];

    return {cosmeticsProductsList, lastVisible};

  } catch (error) {

    throw error;
  }
});

export const getStomachAsync = createAsyncThunk('products/stomach-disorders', async ({page, pageSize, lastVisibleDoc}) => {
  try {

    const stomachDisorderQuery = query(productsCol, where("category", "==", "QnZyZThPoOl5BmJlIbTO"), limit(pageSize))

    if(lastVisibleDoc){
      stomachDisorderQuery = query(stomachDisorderQuery, startAfter(lastVisibleDoc))
    }
    const stomachDisorderSnapShot = await getDocs(stomachDisorderQuery);
    const stomachDisorderList= stomachDisorderSnapShot.docs.map((doc) => doc.data());
    const lastVisible = stomachDisorderSnapShot[ stomachDisorderSnapShot.length - 1 ]

    return {stomachDisorderList, lastVisible};

  } catch (error) {

    throw error;
  }
});

export const getMalariaAsync = createAsyncThunk('products/malaria', async ({page, pageSize, lastVisibleDoc}) => {
  try {

    const malariaQuery = query(productsCol, where("category", "==", "TiyzVgiIZ8xj44YDiLig"), limit(pageSize))
    if(lastVisibleDoc){
      malariaQuery = query(malariaQuery, startAfter(lastVisibleDoc))
    }
    const malariaSnapShot = await getDocs(malariaQuery);
    const malariaList= malariaSnapShot.docs.map((doc) => doc.data());
    const lastVisible = malariaSnapShot[ malariaSnapShot.length - 1 ]

    return {malariaList, lastVisible};

  } catch (error) {

    throw error;
  }
});

export const getCoughFluAsync = createAsyncThunk('products/cough-flu', async ({page, pageSize, lastVisibleDoc}) => {
  try {

    const coughFluQuery = query(productsCol, where("category", "==", "TQ2USLYB3OfzzAzd8fS3"), limit(pageSize))

    if(lastVisibleDoc){
      coughFluQuery = query(coughFluQuery, startAfter(lastVisibleDoc))
    }
    const coughFluSnapShot = await getDocs(coughFluQuery);
    const coughFluList= coughFluSnapShot.docs.map((doc) => doc.data());
    const lastVisible = coughFluSnapShot[coughFluSnapShot.length - 1]

    return {coughFluList, lastVisible};

  } catch (error) {

    throw error;
  }
});

export const getVitaminsMineralsAsync = createAsyncThunk('products/vitamins-minerals', async ({page, pageSize, lastVisibleDoc}) => {
  try {
  
    let vitaminsMineralsQuery = query(productsCol, where("category", "==", "Ghvzoqy25vOlrjvayo6h"),limit(pageSize))
    
    if(lastVisibleDoc){
      vitaminsMineralsQuery = query(vitaminsMineralsQuery, startAfter(lastVisibleDoc))
    }
    const vitaminsMineralsSnapShot = await getDocs(vitaminsMineralsQuery);
    const vitaminsMineralsList= vitaminsMineralsSnapShot.docs.map((doc) => doc.data());
    const lastVisible = vitaminsMineralsSnapShot.docs[vitaminsMineralsSnapShot - 1]

    return {vitaminsMineralsList, lastVisible};

  } catch (error) {

    throw error;
  }
});

export const getSexualWellnessAsync = createAsyncThunk('product/sexual-wellness', async({page, pageSize, lastVisibleDoc, id})=>{
  try {
    let sexualWellnessQuery = query(productsCol, where("category", "==", "61FgyQwZ4JuUDPlgFjjh"), limit(pageSize))

    if(lastVisibleDoc){
      sexualWellnessQuery = query(sexualWellnessQuery, startAfter(lastVisibleDoc))
    }
    const sexualWellnessSnapshot = await getDocs(sexualWellnessQuery)
    const sexualWellnessList = sexualWellnessSnapshot.docs.map((doc)=>doc.data())
    const lastVisible = sexualWellnessSnapshot.docs[sexualWellnessSnapshot - 1]

    return {sexualWellnessList, lastVisible}

  } catch (error) {
    throw error;
  }
})

export const getHemorrhoidsAsync = createAsyncThunk('product/hemorrhoids', async({page, pageSize, lastVisibleDoc, id})=>{
  try {
    let hemorrhoidsQuery = query(productsCol, where("category", "==", "LUFVu97lr2YHleLcTFid"), limit(pageSize))

    if(lastVisibleDoc){
      hemorrhoidsQuery = query(hemorrhoidsQuery, startAfter(lastVisibleDoc))
    }
    const hemorrhoidsSnapshot = await getDocs(hemorrhoidsQuery)
    const hemorrhoidsList = hemorrhoidsSnapshot.docs.map((doc)=>doc.data())
    const lastVisible = hemorrhoidsSnapshot.docs[hemorrhoidsSnapshot - 1]

    return {hemorrhoidsList, lastVisible}

  } catch (error) {
    throw error;
  }
})

export const getBabyCareAsync = createAsyncThunk('product/baby-care', async({page, pageSize, lastVisibleDoc, id})=>{
  try {
    let babyCareQuery = query(productsCol, where("category", "==", "M2iatwWBmlz5K1AVffF9"), limit(pageSize))

    if(lastVisibleDoc){
      babyCareQuery = query(babyCareQuery, startAfter(lastVisibleDoc))
    }
    const babyCareSnapshot = await getDocs(babyCareQuery)
    const babyCareList = babyCareSnapshot.docs.map((doc)=>doc.data())
    const lastVisible = babyCareSnapshot.docs[babyCareSnapshot - 1]

    return {babyCareList, lastVisible}

  } catch (error) {
    throw error;
  }
})

export const getAntifungalAsync = createAsyncThunk('product/antifungals', async({page, pageSize, lastVisibleDoc, id})=>{
  try {
    let antifungalQuery = query(productsCol, where("category", "==", "ZTT6sHr8qPz6xOpR1i24"), limit(pageSize))

    if(lastVisibleDoc){
      antifungalQuery = query(antifungalQuery, startAfter(lastVisibleDoc))
    }
    const antifungalSnapshot = await getDocs(antifungalQuery)
    const antifungalList = antifungalSnapshot.docs.map((doc)=>doc.data())
    const lastVisible = antifungalSnapshot.docs[antifungalSnapshot - 1]

    return {antifungalList, lastVisible}

  } catch (error) {
    throw error;
  }
})

export const getAntiparasiticAsync = createAsyncThunk('product/antiparasitic', async({page, pageSize, lastVisibleDoc, id})=>{
  try {
    let antiparasiticQuery = query(productsCol, where("category", "==", "VpEPRblBE5qKm6U48iuY"), limit(pageSize))

    if(lastVisibleDoc){
      antiparasiticQuery = query(antiparasiticQuery, startAfter(lastVisibleDoc))
    }
    const antiparasiticSnapshot = await getDocs(antiparasiticQuery)
    const antiparasiticList = antiparasiticSnapshot.docs.map((doc)=>doc.data())
    const lastVisible = antiparasiticSnapshot.docs[antiparasiticSnapshot - 1]

    return {antiparasiticList, lastVisible}

  } catch (error) {
    throw error;
  }
})

export const getCentralNervousSystemAsync = createAsyncThunk('product/central-nervous-system', async({page, pageSize, lastVisibleDoc, id})=>{
  try {
    let centralNervousSystemQuery = query(productsCol, where("category", "==", "MhrixKg5P1YSTPJeH3Y9"), limit(pageSize))

    if(lastVisibleDoc){
      centralNervousSystemQuery = query(centralNervousSystemQuery, startAfter(lastVisibleDoc))
    }
    const centralNervousSystemSnapshot = await getDocs(centralNervousSystemQuery)
    const centralNervousSystemList = centralNervousSystemSnapshot.docs.map((doc)=>doc.data())
    const lastVisible = centralNervousSystemSnapshot.docs[centralNervousSystemSnapshot - 1]

    return {centralNervousSystemList, lastVisible}

  } catch (error) {
    throw error;
  }
})

export const getGenitalUrinarySystemAsync = createAsyncThunk('product/genital-urinary-system', async({page, pageSize, lastVisibleDoc, id})=>{
  try {
    let genitalUrinarySystemQuery = query(productsCol, where("category", "==", "nZprhAsxUocnW7vXCKpX"), limit(pageSize))

    if(lastVisibleDoc){
      genitalUrinarySystemQuery = query(genitalUrinarySystemQuery, startAfter(lastVisibleDoc))
    }
    const genitalUrinarySystemSnapshot = await getDocs(genitalUrinarySystemQuery)
    const genitalUrinarySystemList = genitalUrinarySystemSnapshot.docs.map((doc)=>doc.data())
    const lastVisible = genitalUrinarySystemSnapshot.docs[genitalUrinarySystemSnapshot - 1]

    return {genitalUrinarySystemList, lastVisible}

  } catch (error) {
    throw error;
  }
})

 export const getAllergyAsync = createAsyncThunk('product/allergy', async({page, pageSize, lastVisibleDoc, id})=>{
  try {
    let allergyQuery = query(productsCol, where("category", "==", "fIxEnLn9dqkCVRSnZJ1b"), limit(pageSize))

    if(lastVisibleDoc){
      allergyQuery = query(allergyQuery, startAfter(lastVisibleDoc))
    }
    const allergySnapshot = await getDocs(allergyQuery)
    const allergyList = allergySnapshot.docs.map((doc)=>doc.data())
    const lastVisible = allergySnapshot.docs[allergySnapshot - 1]

    return {allergyList, lastVisible}

  } catch (error) {
    throw error;
  }
})

export const getProductDetails = createAsyncThunk('product/details', async (product_id) => {
  try {
    const product = query(productsCol, where("id", "==", product_id))
    const productSnapShot = await getDocs(product)
    const productDetail = productSnapShot.docs.map((doc) => doc.data());

    return productDetail

  } catch (error) {
    throw error;
  }
});

export const getCategoriesAsync = createAsyncThunk('products/categories', async() => {
  try{

    const categorySnapShot = await getDocs(categoryCol)
    const categoryList = categorySnapShot.docs.map((doc)=>doc.data())

    return categoryList;
  }
  catch(error)
  {
    throw error;
  }
})

export const getCategoryProductsAsync = createAsyncThunk('category/products', async(category)=>{
  try{
    let id = null;
    const q = query(categoryCol, where("name", "==", category));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      id = doc.id
    });

    const categoryProducts = query(productsCol, where("category", "==", id))
    const cPSnapShot = await getDocs(categoryProducts);
    const categoryProductsList= cPSnapShot.docs.map((doc) => doc.data());
    return categoryProductsList

  }
  catch(error){
    throw error
  }
})

export const getProductByCategoryId = createAsyncThunk('disease/products', async({page, pageSize, lastVisibleDoc, id})=>{
  try{

    let productsQuery = query(productsCol, where("category", "==", id),
    limit(pageSize))

    if(lastVisibleDoc){
      productsQuery = query(productsQuery, startAfter(lastVisibleDoc))
    }

    const cPSnapShot = await getDocs(productsQuery);
    const categoryProductsList= cPSnapShot.docs.map((doc) => doc.data());
    const lastVisible = cPSnapShot.docs[cPSnapShot.docs.length - 1];

    return {categoryProductsList, lastVisible}

  }
  catch(error){
    throw error
  }
})

export const getProductByIdAsync = createAsyncThunk('product/id', async(id)=>{
  try {
    const q = query(productsCol, where("id", "==", id))
    const querySnapShot = await getDocs(q);
    const product = querySnapShot.docs.map((doc)=>doc.data());

    
    return product;
  } catch (error) {
    throw error;
  }
}) 

export const filterProducts = createAsyncThunk('product/filter', async({gender, brand})=>{
  try {

    if(gender){
      const FpProduct = query(productsCol, 
        where("category", "==", 'RAUs4urbgYKECTZ6C21s'),
        where("genders", "array-contains", gender)
      )
      const FpSnapShot = await getDocs(FpProduct);
      const familyPlanningProductsList= FpSnapShot.docs.map((doc) => doc.data());
      
      return familyPlanningProductsList
    }
    else if(brand){
      const FpProduct = query(productsCol, 
        where("category", "==", 'RAUs4urbgYKECTZ6C21s'),
        where("manufacturer_name", "==", brand)
      )
      const FpSnapShot = await getDocs(FpProduct);
      const familyPlanningProductsList= FpSnapShot.docs.map((doc) => doc.data());

      return familyPlanningProductsList
    }

  } catch (error) {
    throw error;
  }
})

export const getHealthDevicesAsync = createAsyncThunk('product/health-devices', async()=>{
  try {
    const health_devices = query(productsCol, where("category", "==", "iBII7BXabPyrAZl4hYbC"))
    const health_devicesSnapShot = await getDocs(health_devices);
    const health_devces_List= health_devicesSnapShot.docs.map((doc) => doc.data());

    return health_devces_List;
  } catch (error) {
    throw(error);
  }
})

const product_slice = createSlice({
    name:'products',
    initialState:{
      products: null,
      isLoading: false,
      error: null,
      earNoseMouthProducts: null,
      categories: null,
      diabetesProducts: null,
      categoryProducts: null,
      familyPlanningProducts: null,
      painAndFeverProducts: null,
      diseaseProducts: null,
      productById: null,
      fitleredProducts:null,
      cosmeticsProducts:null,
      healthDeviceProducts:null,
      malariaProducts:null,
      stomachProducts:null,
      coughFluProducts: null,
      vitaminsMineralsProducts : null,
      sexualWellnessProducts: null,
      hemorrhoidsProducts: null,
      babyCareProducts: null,
      antifungalProducts: null,
      antiparasiticProducts: null,
      centralNervousSystemProducts: null,
      genitalUrinarySystemProducts: null,
      allergyProducts: null
    },
    reducers:{

    },
    extraReducers:(builder)=>{
        builder
        .addCase(getProductsAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getProductsAsync.fulfilled, (state, action) => {
            state.products = action.payload;
            state.isLoading = false;
            state.error = null;
          })
          .addCase(getProductsAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getDiabetesProductsAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getDiabetesProductsAsync.fulfilled, (state, action) => {
            state.diabetesProducts = action.payload.diabetesProductsList;
            state.isLoading = false;  
            state.error = null;
          })
          .addCase(getDiabetesProductsAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getFamilyPlanningAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getFamilyPlanningAsync.fulfilled, (state, action) => {
            state.familyPlanningProducts = action.payload.familyPlanningProductsList;
            state.isLoading = false;  
            state.error = null;
          })
          .addCase(getFamilyPlanningAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getPainAndFeverAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getPainAndFeverAsync.fulfilled, (state, action) => {
            state.painAndFeverProducts = action.payload.painAndFeverProductsList;
            state.isLoading = false;  
            state.error = null;
          })
          .addCase(getPainAndFeverAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getCosmeticsAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getCosmeticsAsync.fulfilled, (state, action) => {
            state.cosmeticsProducts = action.payload.cosmeticsProductsList;
            state.isLoading = false;  
            state.error = null;
          })
          .addCase(getCosmeticsAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getStomachAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getStomachAsync.fulfilled, (state, action) => {
            state.stomachProducts = action.payload.stomachDisorderList;
            state.isLoading = false;  
            state.error = null;
          })
          .addCase(getStomachAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getMalariaAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getMalariaAsync.fulfilled, (state, action) => {
            state.malariaProducts = action.payload.malariaList;
            state.isLoading = false;  
            state.error = null;
          })
          .addCase(getMalariaAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getCoughFluAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getCoughFluAsync.fulfilled, (state, action) => {
            state.coughFluProducts = action.payload.coughFluList;
            state.isLoading = false;  
            state.error = null;
          })
          .addCase(getCoughFluAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getVitaminsMineralsAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getVitaminsMineralsAsync.fulfilled, (state, action) => {
            state.vitaminsMineralsProducts = action.payload.vitaminsMineralsList;
            state.isLoading = false;  
            state.error = null;
          })
          .addCase(getVitaminsMineralsAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getProductDetails.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getProductDetails.fulfilled, (state, action) => {
            state.products = action.payload;
            state.isLoading = false;
            state.error = null;
          })
          .addCase(getProductDetails.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getEarNoseMouthProductsAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getEarNoseMouthProductsAsync.fulfilled, (state, action) => {
            state.earNoseMouthProducts = action.payload.earNoseMouthProductsList;
            state.isLoading = false;
            state.error = null;
          })
          .addCase(getEarNoseMouthProductsAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getCategoriesAsync.pending, (state, action) => {
            state.isLoading = true;
            state.error = null
          })
          .addCase(getCategoriesAsync.fulfilled, (state, action) => {
            state.categories = action.payload;
            state.isLoading = false;
            state.error = null;
          })
          .addCase(getCategoriesAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getCategoryProductsAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getCategoryProductsAsync.fulfilled, (state, action) => {
            state.categoryProducts = action.payload;
            state.isLoading = false;
            state.error = null;
          })
          .addCase(getCategoryProductsAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getProductByCategoryId.pending, (state)=>{
            state.isLoading = true;
            state.error = null
          })
          .addCase(getProductByCategoryId.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.diseaseProducts = action.payload.categoryProductsList
            state.error = null
          })
          .addCase(getProductByCategoryId.rejected, (state, action)=>{
            state.isLoading = false
            state.error = action.error.message
          })
          .addCase(getProductByIdAsync.pending, (state)=>{
            state.isLoading = true;
            state.error = null
          })
          .addCase(getProductByIdAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.productById = action.payload
            state.error = null
          })
          .addCase(getProductByIdAsync.rejected, (state, action)=>{
            state.isLoading = false
            state.error = action.error.message
          })
          .addCase(filterProducts.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
          })
          .addCase(filterProducts.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.fitleredProducts = action.payload;
            state.error = null;
          })
          .addCase(filterProducts.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getHealthDevicesAsync.pending, (state, action) => {
            state.isLoading = true;
            state.error = null
          })
          .addCase(getHealthDevicesAsync.fulfilled, (state, action) => {
            state.healthDeviceProducts = action.payload;
            state.isLoading = false;
            state.error = null;
          })
          .addCase(getHealthDevicesAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getAntifungalAsync.pending, (state)=>{
            state.isLoading = true;
            state.error = null
          })
          .addCase(getAntifungalAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.antifungalProducts = action.payload.antifungalList
            state.error = null
          })
          .addCase(getAntifungalAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
          })
          .addCase(getSexualWellnessAsync.pending, (state)=>{
            state.isLoading = true;
            state.error = null
          })
          .addCase(getSexualWellnessAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.sexualWellnessProducts = action.payload.sexualWellnessList
            state.error = null
          })
          .addCase(getSexualWellnessAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
          })
          .addCase(getBabyCareAsync.pending, (state)=>{
            state.isLoading = true;
            state.error = null
          })
          .addCase(getBabyCareAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.babyCareProducts = action.payload.babyCareList
            state.error = null
          })
          .addCase(getBabyCareAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
          })
          .addCase(getAllergyAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getAllergyAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.allergyProducts = action.payload.allergyList         
          })
          .addCase(getAllergyAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getGenitalUrinarySystemAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getGenitalUrinarySystemAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.genitalUrinarySystemProducts = action.payload.genitalUrinarySystemList;
            state.error = null;
          })
          .addCase(getGenitalUrinarySystemAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getAntiparasiticAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getAntiparasiticAsync.fulfilled, (state, action)=>{
            state.isLoading = false
            state.antiparasiticProducts = action.payload.antiparasiticList;
            state.error = null;
          })
          .addCase(getAntiparasiticAsync.rejected, (state, action)=>{
            state.isLoading = false
            state.error = action.error.message
          })
          .addCase(getHemorrhoidsAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getHemorrhoidsAsync.fulfilled, (state, action)=>{
            state.hemorrhoidsProducts = action.payload.hemorrhoidsList;
            state.error = null;
            state.isLoading = false;
          })
          .addCase(getHemorrhoidsAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getCentralNervousSystemAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getCentralNervousSystemAsync.fulfilled, (state, action)=>{
            state.error = null;
            state.centralNervousSystemProducts = action.payload.centralNervousSystemList;
            state.isLoading = false;
          })
          .addCase(getCentralNervousSystemAsync.rejected, (state, action)=>{
            state.error = action.error.message;
            state.isLoading = false;            
          })
    }
})


export const productActions = product_slice.actions;
export default product_slice;