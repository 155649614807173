import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import { getAllBanners } from '../store/banner_slice';
import { Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import '../assets/css/offers.css';
import { offerImages } from '../utils';
import BreadCrumb from '../components/breadCrumb';
import images from '../assets';

function Offers() {
    const dispatch = useDispatch()
    const [offers, setOffers] = useState(null);

    useEffect(()=>{
        dispatch(getAllBanners())
        .then(res => {
            setOffers(res.payload)
        })
        .catch(error=>console.log())
    }, [])

    return (
        <div className='offers-div'>
            <div className='offers-wrapper'>
                {/* {
                    offers ? 
                    offers.map((element, index)=>{

                        return(
                            <NavLink>
                                <Paper elevation={3} className='offer-paper'>
                                    <img src={element.image} alt=''/>
                                </Paper>
                            </NavLink>
                        )
                    })
                    :
                    <div>
                        Sorry, we have no offers at the moment.
                    </div>
                } */}
                    <img src={images.sadface} alt=""/>
                    Sorry, we have no offers at the moment.
            </div>
        </div>
  )
}

export default Offers