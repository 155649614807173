import React, { useState } from 'react'
import '../assets/css/components.css'
import { IconButton, Paper, Alert, CircularProgress } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';

import images from '../assets';
import PercentIcon from '@mui/icons-material/Percent';
import { capitalizeProductName, formatString, calculatePercentageDiscount, addCommas } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { createCartItemAsync } from '../store/cart_slice';
import { addItemToWishListAsync } from '../store/wishlist_slice';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { FavoriteOutlined } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import BoltIcon from '@mui/icons-material/Bolt';




function ProductCard(props) {
  const user = useSelector(state => state.auth.user);
  const user_id = useSelector(state => state.auth.id);
  const [loggedIn, setLoggedIn] = React.useState(null)
  const [isSucess, setIsSuccess] = React.useState(null)
  const [isWishlistSuccess, setIsWishlistSuccess] = React.useState(null)
  const [duplicateCartItem, setDuplicateCartItem] = React.useState(false)
  const [duplicateWishlistItem, setDuplicateWishlistItem] = React.useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const isLoggedIn = localStorage.getItem('isLoggedIn')
  const localUEmail = localStorage.getItem('email')
  const localUID = localStorage.getItem('id')
  // const localUID 
  const dispatch = useDispatch();
  const navigate = useNavigate()

  React.useEffect(()=>{  
    setLoggedIn(isLoggedIn)
  },[])

  const createCartItem = ()=>{
    setIsLoading(true)
    if(loggedIn){
      const data = {}
      const currentDate = new Date()
  
      data['total_price']=props.originalPrice
      data['product'] = {
        id: props.id,
        name: props.title,
        price: props.originalPrice,
        quantity: 1,
        units:props.units,
        image:props.image
      }
      data['date_created'] = currentDate.getTime()
      data['owner_email'] = user ? user.email : localUEmail
      data['is_order'] = false
      data['owner_id'] = user_id ? user_id : localUID

      dispatch(createCartItemAsync(data))
      .then(res=>{
        if(res['meta']['requestStatus'] === 'fulfilled'){
          if(res.payload.variant === 'success'){
            setIsSuccess(true)
            setIsLoading(false)
          }
          else if(res.payload.variant === 'warning'){
            setIsLoading(false)
            setDuplicateCartItem(true)
          }
        }
      })
      .catch(error => {
        setIsSuccess(false)
        setIsLoading(false)
      })
    }
    else{
      let cart = localStorage.getItem('cart')
      cart = cart ? JSON.parse(cart) : []

      let cartNum = parseInt(localStorage.getItem('cartNum'))
      let cartTotal = parseInt(localStorage.getItem('cartTotal'))


      const data = {}
      const currentDate = new Date()
  
      data['total_price']=props.originalPrice
      data['product'] = {
        id: props.id,
        name: props.title,
        price: props.originalPrice,
        quantity: 1,
        units:props.units,
        image:props.image
      }
      data['date_created'] = currentDate.getTime()
      data['is_order'] = false

      

      cart.push(data)


      const updatedCart = JSON.stringify(cart)
      const newCartNum = cart.length;
      const newCartTotal = cartTotal + props.originalPrice

      localStorage.setItem('cart', updatedCart)
      localStorage.setItem('cartNum', newCartNum)
      localStorage.setItem('cartTotal', newCartTotal)

      setIsSuccess(true)
    }
    setTimeout(()=>{
      setIsSuccess(null)
      setDuplicateCartItem(false)
    }, 2000)

    setIsLoading(false)
  }

  const addToWishList = ()=>{
    if(user !== null && isLoggedIn){
      const currentDate = new Date()
      const w = {
        item_id: props.id,
        item_type: "product",
        products: [props.id],
        time: currentDate.getTime(),
        user: user_id
      }      

      dispatch(addItemToWishListAsync(w))
      .then((res)=>{
        if(res['meta']['requestStatus'] === 'fulfilled'){
          if(res.payload.variant === 'success'){
            setIsWishlistSuccess(true)
          }
          else if(res.payload.variant === 'warning'){
            setDuplicateWishlistItem(true)
          }
        }
      })
      .catch((error)=>{
        console.log('An Error Occurred.')
        setIsWishlistSuccess(false)
      })
  

      setTimeout(()=>{
        setIsWishlistSuccess(null)
        setDuplicateWishlistItem(false)
      }, 5000)
    }
    else if(user === null && isLoggedIn === false){
      navigate('/signin', {state:{message:'Unauthorized User. Sign in to proceed'}})
    }
  };
  return (
    <Paper
      className='custom-card'
      elevation={1}
      sx={{ "&:hover": { boxShadow: 12 } }}
    >
      <NavLink
        to={{pathname:`/${formatString(props.title)}/${props.id}/details`}}
        style={({isActive})=>{
          return {
            textDecoration: isActive ? 'underline' : 'none'
          }
        }}
      >
        <div className='product-card-img'>
          {
            props.image === '' ?
              <img src={images.drugs} alt='' style={{width:'70px', height:'70px'}}/>
            :
              <img src={props.image} alt=''/> 
          }
        </div>
      </NavLink>
        <div className='product-name'>
          <p>{capitalizeProductName(props.title, ' ')}</p>
        </div>
        <div className='product-current-price'>
          {
            props.is_discounted ?
              <div className="product-price-diff">
                <div className='product-o-prc'>
                  <p id='product-old-price'>
                    <span>Ugx. </span>
                    {addCommas(props.originalPrice.toString())}
                  </p>
                </div>
                <p id='product-current-price-orig'>
                  <span id='product-current-price-label'>Ugx. </span>
                  {addCommas(props.newPrice.toString())}
                </p>
              </div>
            :
              <>
                <p id='product-current-price-orig'>
                  <span id='product-current-price-label'>Ugx. </span>
                  {addCommas(props.originalPrice.toString())}
                </p>
              </>
          }
        </div>
        {
          isSucess ?
            <Alert severity="success">Added to cart.</Alert>
          : isSucess === false && isLoggedIn ?
              <Alert severity='error'>Operation failed.</Alert>
          : isSucess === null && !isLoggedIn ?
              <></>
          : isWishlistSuccess && isLoggedIn ?
              <Alert severity='success'>Added to wishlist.</Alert>
          : isWishlistSuccess === false && isLoggedIn ?
              <Alert severity='error'>Operation failed.</Alert>
          : isWishlistSuccess === null && !isLoggedIn ?
              <></>
          : duplicateCartItem && isLoggedIn ?
              <Alert severity='warning'>Item already in cart.</Alert>
          : duplicateWishlistItem && isLoggedIn ?
              <Alert severity="warning">Item already in wishlist.</Alert>
          :
            <></>
        }
        <div className='product-add-to-cart'>
          { 
            (!isLoading && props.dispensation === false) ?
              <IconButton style={{borderRadius:'25px'}} onClick={()=>{createCartItem()}}>
                <AddShoppingCartIcon fontSize='large' color='success'/>
              </IconButton>
            : (!isLoading && props.dispensation === "redirect") ?
                <IconButton style={{borderRadius:'25px'}} onClick={()=>{navigate(`/${formatString(props.title)}/${props.id}/details`)}}>
                  <AddShoppingCartIcon fontSize='large' color='success'/>
                </IconButton>
            :
              <CircularProgress/>
          }
          <IconButton style={{borderRadius:'25px'}} onClick={()=>{addToWishList()}}>
            <FavoriteBorderIcon fontSize='large'/>
          </IconButton>
        </div>
        <div className='product-view-more'>
          <NavLink
            to={{pathname:`/${formatString(props.title)}/${props.id}/details`}}
            style={({isActive})=>{
              return {
                textDecoration: isActive ? 'underline' : 'none'
              }
            }}
          >
            <RemoveRedEyeOutlinedIcon/> View more
          
          </NavLink>
        </div>
    </Paper>
  )
}

function ProductRating(props) {
  return (
    <div className='rating'>
      <BoltIcon style={{color:'gold'}} fontSize='large'/>
      {calculatePercentageDiscount(props.original_price, props.new_price)}
      <PercentIcon/>
      off
    </div>
  )
}

export default ProductCard