import { Box, Paper } from '@mui/material';
import React from 'react';
import '../assets/css/featuredP.css';
import { NavLink } from 'react-router-dom';
import { formatString } from '../utils';

function FeaturedProducts(props) {
  return (
    <NavLink
        to={{pathname:`/${formatString(props.title)}/${props.id}/details`}}
    >
        <Paper elevation={0} className='featuredP-card'>
            <img src={props.image} alt=''   />
        </Paper>
    </NavLink>
  )
}

export default FeaturedProducts