import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { db } from '../firebase'
import { collection, getDocs } from 'firebase/firestore'

const bannerCol = collection(db, 'web_banners')


export const getAllBanners = createAsyncThunk('banners', async()=>{
    try{
        const bannerSnapShot = await getDocs(bannerCol);
        const bannerList = bannerSnapShot.docs.map((doc)=> doc.data());                             
        return bannerList
    }
    catch(error){
        throw error;
    }
})


const banner_slice = createSlice({
    name:'banners',
    initialState:{
        isLoading: false,
        bannerList: null,
        error: null
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllBanners.pending, (state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getAllBanners.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.bannerList = action.payload
            state.error = null
        })
        .addCase(getAllBanners.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
    }
})

export const bannerActions = banner_slice.actions;
export default banner_slice;