import React, { useEffect } from 'react'
import { Paper, TextField, Button, CircularProgress, IconButton, InputAdornment, Alert } from '@mui/material';
import { useForm } from "react-hook-form"

import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import '../assets/css/login.css';
import { useDispatch, useSelector } from 'react-redux';
import { googleLoginAsync, loginAsync } from '../store/auth-slice';
import images from '../assets/index'
import { extractErrorMessage, terms } from '../utils';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { getCartItemsAsync } from '../store/cart_slice';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { getAllWishlistAsync } from '../store/wishlist_slice';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { createCartItemAsync } from '../store/cart_slice';





function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const error = useSelector((state)=>state.auth.error)
  const {register, handleSubmit} = useForm()
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorValue, setErrorValue] = React.useState(null)
  const [isVisible, setIsVisible] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const {state} = location || {};
  const {message} = state || {};

  let cart = localStorage.getItem('cart')
  const cartArr = JSON.parse(cart || '[]');

  const hidePass = ()=>{
    setIsVisible(!isVisible)
  }

  const handleCartItems = async (cartItems, userEmail, userId) => {
    try {
      const uniqueCartArr = cartItems.filter((item, index, self) =>
        index === self.findIndex((t) => t.product.id === item.product.id)
      );

      
      uniqueCartArr.forEach(item => {
        item['owner_email'] = userEmail;
        item['owner_id'] = userId;
      });


      const promises = uniqueCartArr.map((item, index) => {
        dispatch(createCartItemAsync(item))
        .then(res=>{
        })
      });

      const results = await Promise.all(promises)
      .then(()=>{
        navigate('/cart')
      })
      .catch((err)=>{
        console.log('error', err)
      });


      localStorage.setItem('cart', JSON.stringify([]));
      localStorage.setItem('cartNun', 0)

    } catch (error) {
      console.error('Error processing cart items', error);
    }
  };

  const onLogin =  (data) => {
    setIsLoading(true)
    try{
      dispatch(loginAsync(data))
      .then((res)=>{
        if(res.meta.requestStatus === 'fulfilled')
        { 
          localStorage.removeItem('cart')
          if(cartArr.length > 0){
            handleCartItems(cartArr, res.payload.email, res.payload.user_id);
          }
          else{
            navigate('/')
          }
          setIsLoading(false)
        }
        else if(res.meta.requestStatus === 'rejected'){
          const err = extractErrorMessage(res.error.message)
          setErrorValue(err)

          setTimeout(()=>{
            setErrorValue(null)
          },5000)
        }
      })
      setIsLoading(false)
    }catch(error){
      setIsLoading(false)
    }
    setIsLoading(false);
  }

  const signInWithGoogle = async()=>{
    setIsLoading(true)
    try {
      const res = await dispatch(googleLoginAsync())
      
      if(res.meta.requestStatus === 'fulfilled'){

        setIsLoading(false)
        setIsSuccess(true)
        await dispatch(getCartItemsAsync(res.payload.email));

        await dispatch(getAllWishlistAsync(res.payload.user_id));

        setIsSuccess(false)
        if(cartArr.length > 0){
          handleCartItems(cartArr, res.payload.email, res.payload.user_id);
        }
        else{
          navigate('/')
        }
      }
    } catch (error) {
      setIsLoading(false)
      setIsSuccess(false)
      console.log('An error occurred.')
    }

  }



  return (
    <div className='login-div'>
      <div className='login-highlight'>
        <div className='back-to-home'>
          <NavLink to='/'>
            <KeyboardBackspaceIcon fontSize='medium'/>
            <p>Back To Home</p>
          </NavLink>
        </div>
        <div className='login-mob-back-to-home'>
          <div className='login-mob-back-btn' >
            <HomeOutlinedIcon fontSize='small'/>
            <p>Home</p>
          </div>
        </div>
        <img src={images.logo} alt=''/>
        
        <div>
          <h3>Sign In to Drugs Express</h3>
        </div>
      </div>
      <div className='login-paper-div'>
        <div className='login-error'>
          {error && <p style={{color:'red'}}>Invalid Email or Password.</p>}
          {
            message && <p style={{color:'red'}}>{message}</p>
          }
        </div>
        <Paper className='login-paper' elevation={5} style={{borderRadius:'20px'}}>
          <div className='login-heading'>
            <p>Sign in with your credentials</p>
          </div>
          <form noValidate onSubmit={handleSubmit(onLogin)} className='login-form'>
            <TextField
              variant='outlined'
              type='email'
              label='Email'
              size='small'
              {...register('email',{required:true})}
            />
            <div>
              <TextField
                fullWidth
                label="Password"
                type={isVisible ? "text" :"password" }
                variant="outlined"
                size="small"
                {...register('password',{required:true})}
                InputProps={{
                  endAdornment:(
                    <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={hidePass}
                      edge="end"
                    >
                      {isVisible ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                    </IconButton>
                  </InputAdornment>
                  ),
                }}
              />

            </div>
            <div className='login-forgot-pass'>
              <NavLink to='/reset-password'>Forgot password?</NavLink>
            </div>
            { isLoading ? <div className='login-progress'><CircularProgress/></div> : <Button variant='contained' type='submit'>LOGIN</Button>}
          </form>
          <div className='login-social'>
            Or sign in with
            <Button variant='outlined' size='small' id="login-social-btn" onClick={signInWithGoogle}>
                <img src={images.google} alt='' width={25}/>
                google
            </Button>
            {
              (isLoading && isSuccess === false) ?
                <Alert severity='success' icon={<CheckIcon />}>Signing you in....</Alert>
              :(isLoading === false && isSuccess === true) ?
                <Alert severity='success' icon={<CheckIcon />}>Sucessfully signed in. Redirecting...</Alert>
              :
                <></>
            }
          </div>
          <div className='login-terms'>
            By continuing you agree to our
            <NavLink to='/terms&conditions'>{terms}</NavLink>
          </div>
        </Paper>
        <div className='no-account'>
          Do not have an account yet? Sign up <NavLink to='/register'>here</NavLink>
        </div>
        <div className='login-contact'>
          For further support, reach out to us on +256 706 404 040 or send us an email at info@drugsexpress.co.ug.
        </div>
      </div>
    </div>
  )
}

export default LoginPage