import React, {useState} from 'react'
import {
    FamilyPlanningSidePanel,
    CosmeticsSidePanel,
    CoughFluSidePanel,
    HealthSupplementsSidePanel,
    HealthDevicesSidePanel,
    AntiMalarialSidePanel,
    StomachDisorderSidePanel,
    PainFeverSidePanel
} from './dropDown'
import {
    capitalizeProductName,
    cosmetics,
    cough_cold_fever,
    family_planning,
    health_devices,
    supplements,
    stomach_disorder,
    pain,
    anti_malarials,
    dropDownList
} from '../utils.js'

function StickyDropDown(props) {
    const [isExtraVisible, setIsExtraVisible] = useState(false)
    const [isCosmeticsVisible, setIsCosmeticsVisible] = useState(false)
    const [isCoughAndFluVisible, setIsCoughAndFluVisible] = useState(false)
    const [isHealthSupplementsVisible, setIsHealthSupplementsVisible] = useState(false)
    const [isHealthDevicesVisible, setIsHealthDevicesVisible] = useState(false)
    const [isAntiMalarialVisible, setIsAntiMalarialVisible] = useState(false)
    const [isPainFeverVisible, setIsPainFeverVisible] = useState(false)
    const [isStomachDisorderVisible, setIsStomachDisorderVisible] = useState(false)


  return (
    <div className='sticky-categories-dropdown-div'>
        <div
            className='sticky-navigation-panel'
            onMouseEnter={props.onEnter}
            onMouseLeave={props.onLeave}
        >
            {
                dropDownList.map((item, index)=>{
                return(
                    <div 
                        className='sticky-navigation-dropdown-child'
                        key={index}
                        onMouseEnter={()=>{
                        if(item.text === family_planning){
                            setIsExtraVisible(true)
                        }

                        if(item.text === cosmetics){
                            setIsCosmeticsVisible(true)
                        }

                        if(item.text === cough_cold_fever){
                            setIsCoughAndFluVisible(true)
                        }

                        if(item.text === health_devices){
                            setIsHealthDevicesVisible(true)
                        }

                        if(item.text === supplements){
                            setIsHealthSupplementsVisible(true)
                        }

                        if(item.text === anti_malarials){
                            setIsAntiMalarialVisible(true)
                        }

                        if(item.text === pain){
                            setIsPainFeverVisible(true)
                        }

                        if(item.text === stomach_disorder){
                            setIsStomachDisorderVisible(true)
                        }

                        }}
                        onMouseLeave={()=>{
                        setIsExtraVisible(false)
                        setIsCosmeticsVisible(false)
                        setIsCoughAndFluVisible(false)
                        setIsHealthDevicesVisible(false)
                        setIsHealthSupplementsVisible(false)
                        setIsAntiMalarialVisible(false)
                        setIsPainFeverVisible(false)
                        setIsStomachDisorderVisible(false)
                        }}
                    >
                        <div className='sticky-navigation-dropdown-child-img'>
                            <img src={item.image} alt=''/>  
                        </div>
                        <div className='sticky-navigation-dropdown-child-p'>
                            <p>{item.text}</p>
                        </div>
                        <div className='sticky-navigation-dropdown-child-icon'>
                            <item.icon />
                        </div>

                        {
                        isExtraVisible && item.text === family_planning ?
                            <FamilyPlanningSidePanel isSticky={true}/>
                        : (isCosmeticsVisible && item.text === cosmetics) ?
                            <CosmeticsSidePanel isSticky={true}/>
                        : (isCoughAndFluVisible && item.text === cough_cold_fever) ?
                            <CoughFluSidePanel isSticky={true}/>
                        :  (isHealthSupplementsVisible && item.text === supplements) ?
                                <HealthSupplementsSidePanel isSticky={true}/>
                        : (isHealthDevicesVisible && item.text === health_devices) ?
                            <HealthDevicesSidePanel isSticky={true}/>
                        : (isAntiMalarialVisible && item.text === anti_malarials) ?
                            <AntiMalarialSidePanel isSticky={true}/>
                        : (isStomachDisorderVisible && item.text === stomach_disorder) ?
                            <StomachDisorderSidePanel isSticky={true}/>
                        : (isPainFeverVisible && item.text === pain) ?
                            <PainFeverSidePanel isSticky={true}/>
                        :
                            <></>
                        }
                    </div>
                )
                })
            }
        </div>
    </div>
  )
}

export default StickyDropDown