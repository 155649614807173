import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getBlogComments, getBlogPost, getBloggerById, addBlogComment, updateComment, deleteComment } from '../store/blog_slice'
import { ErrorSharp } from '@mui/icons-material'
import { convertEpochToDatetime } from '../utils'
import '../assets/css/blogDetails.css';
import { Paper, Button, TextField, IconButton, Menu, MenuItem, CircularProgress } from '@mui/material'
import BreadCrumb from '../components/breadCrumb'
import AddIcon from '@mui/icons-material/Add';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import images from '../assets'


function BlogDetail() {
    const params = useParams()
    const dispatch = useDispatch();
    const [blog, setBlog] = React.useState({})
    const [blogger, setBlogger] = React.useState({})
    const [isLoggedIn, setIsLoggedIn] = React.useState(false)
    const [comments, setComments] = React.useState([])
    const [subComment, setSubComment] = React.useState('');
    const [emptyField, setEmptyField] = React.useState(false)
    const [commentErr, setCommentErr] = React.useState(false)
    const [showEdit, setShowEdit] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [updatedComment, setUpdatedComment] = React.useState('')
    const [updateLoading, setUpdateLoading] = React.useState(false)
    const [updateFailed, setUpdateFailed] = React.useState(false)
    
    const open = Boolean(anchorEl);
    
    const name = localStorage.getItem('name')
    const user_id = localStorage.getItem('user_id')

    React.useEffect(()=>{
        dispatch(getBlogPost(params['blog-title']))
        .then(res=>{
            if(res['meta']['requestStatus'] === 'fulfilled'){
                const paragraphs = res.payload[0].post.body.split(/\n\n|\n/);
                let blog_obj = {
                    id: res.payload[0].post_id,
                    title: res.payload[0].post.title,
                    date: convertEpochToDatetime(res.payload[0].post.time),
                    images: res.payload[0].post.images[0],
                    products: res.payload[0].post.products,
                    body:paragraphs
                }
                
                setBlog(blog_obj)

                dispatch(getBloggerById(res.payload[0].post.added_by))
                .then(response => {
                    if(response['meta']['requestStatus'] === 'fulfilled'){
                        setBlogger({
                            name: response.payload.first_name + " " + response.payload.second_name
                        })
                    }
                })
                .catch( error => console.log(error))

                dispatch(getBlogComments(res.payload[0].post_id))
                .then(response =>{
                    if(response['meta']['requestStatus'] === 'fulfilled'){
                        setComments(response.payload)

                        dispatch(getBloggerById())
                    }
                })
                .catch(error => console.log(error))
            }
            
        })
        .catch(error => console.log('An error occurred.'))

        const isLogged = localStorage.getItem(isLoggedIn)
        if(isLogged){
            setIsLoggedIn(true)
        }
        else{
            setIsLoggedIn(false)
        }


    },[comments])

    const addComment = (c)=>{
        dispatch(addBlogComment(c))
        .then(res =>{
            if(res['meta']['requestStatus'] === 'fulfilled'){
                setSubComment('')

            }
            else if(res['meta']['requestStatus'] === 'rejected'){
                setCommentErr(true)
            }
        })
        .catch(error => console.log('An error occurred.'))
    }

    const editComment = (c)=>{
        setUpdateLoading(true)
        dispatch(updateComment(c))
        .then(res=>{
            if(res['meta']['requestStatus'] === 'fulfilled'){

                setUpdateLoading(false)
                setShowEdit(false)
            }
            else if(res['meta']['requestStatus'] === 'rejected'){
                setUpdateFailed(true)

                setShowEdit(false)
                setUpdateLoading(false)
            }

        })
        .catch(error => console.log('An error occurred'))
        setShowEdit(false)
        setUpdateLoading(false)
    }

    const removeComment = (c)=>{
        dispatch(deleteComment(c))
        .then(res => {
        })
        .catch(error => console.log('An error occurred'))
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

  return (
    <>
        <BreadCrumb crumbs={[{text:'Blog', url:`/blog`}, {text:`${blog.title}`, url:`/blog/${blog.title}`}]}/>
        <div className='blog-detail-wrapper'>

            <div className='blog-detail-date'>{blog.date}</div>
            <div className='blog-detail-title'><h1>{blog.title}</h1></div>
            <div className='blog-detail-img'>
                <img src={blog.images ? blog.images : images.image_gallery} alt=''/>
            </div>
            {
                blog.body !== undefined ?
                    blog.body.map((blogText, index)=>{
                        return <p>{blogText}</p>
                    })
                :
                    ''
            }

            <div className='blog-posted-by'>
                <div className='blog-posted-by-detail'>
                    <p>Author</p> 
                    {
                        blogger.name !== undefined ?
                            <p className='blog-posted-by-name'>{blogger.name}</p>
                        :
                            ''
                    }
                </div>
            </div>

            <div className='blog-details-comments'>
                <div className='blog-add-wrapper'>
                    <Paper elevation={0} className='blog-details-card'>
                        <div className='blog-add-comment-div'>
                            <p>{emptyField ? '*Required' : commentErr ? 'Failed. Try again' : ''}</p>
                            <TextField
                                multiline
                                onChange={(event)=>{
                                    setSubComment(event.target.value)
                                }}
                                size='small'
                            />
                            <div className='blog-add-div'>
                                <Button 
                                    onClick={()=>{
                                        if( subComment !== ''){
                                            const comm = {
                                                "admin": false,
                                                "approved": true,
                                                "images": [],
                                                "message_level": "level_one_comment",
                                                "private": false,
                                                "sender":{
                                                    "id": user_id,
                                                    "name":name,
                                                    "image":""
                                                },
                                                "text": subComment,
                                                "thing_id": blog.id,
                                                "thing_type": "post",
                                                "time": Date.now()
                                            }

                                            addComment(comm)
                                        }
                                        else{
                                            setEmptyField(true);

                                            setTimeout(()=>{setEmptyField(false)},2000)
                                        }
                                    }}
                                    className='blog-add-comment-btn'
                                    color='success'
                                >
                                    <AddIcon/> Add Comment
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className='comments'>
                    {
                        comments.map((element, index)=>{
                            return(
                                <Paper elevation={2} className='comment-card'>
                                    <div className='comment-card-icon-div'>
                                        <div className='comment-name-icon'>
                                            <AccountCircleIcon fontSize='large' className='comment-card-icon'/>
                                            <p>{element.sender.name ? element.sender.name : 'unknown user'}</p>
                                        </div>
                                        <div className='comment-edit'>
                                            {
                                                user_id === element.sender.id ?
                                                    <>
                                                        <IconButton
                                                            id="basic-button"
                                                            aria-controls={open ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                            onClick={handleClick}
                                                        >
                                                            <MoreVertIcon fontSize='' style={{color:'gray'}}/>
                                                        </IconButton>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                        >
                                                            <MenuItem>
                                                                <Button 
                                                                    size="small"
                                                                    onClick={()=>{setShowEdit(true)}}
                                                                    // 
                                                                >
                                                                    <ModeEditIcon/> Edit
                                                                </Button>
                                                            </MenuItem>
                                                            <MenuItem>
                                                                <Button
                                                                    size='small'
                                                                    onClick={()=>removeComment({"id":user_id, "t_id":element.thing_id})}
                                                                    color='error'
                                                                >
                                                                    <DeleteForeverOutlinedIcon/> Remove
                                                                </Button>
                                                            </MenuItem>                                                
                                                        </Menu>        
                                                    </>
                                                :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    <div className='comment-card-text'>
                                        {
                                            !showEdit ?
                                                <p>{element.text}</p>
                                            : showEdit && user_id === element.sender.id ?
                                                <div className='comment-edit-div'>
                                                    <TextField
                                                        onChange={(e)=>{setUpdatedComment(e.target.value)}}
                                                        placeholder={element.text}
                                                        size='small'
                                                    />
                                                    <div className='comment-edit-action'>
                                                        <Button 
                                                            onClick={()=>{
                                                                editComment({
                                                                    "id":user_id,
                                                                    "t_id":element.thing_id,
                                                                    "text":updatedComment})
                                                            }}
                                                            color='success'
                                                        >
                                                            { updateLoading ? <CircularProgress/> : "Confirm"}
                                                        </Button>
                                                        <Button onClick={()=>setShowEdit(false)} color='error'>Cancel</Button>
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                        }
                                    </div>
                                </Paper>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </>
  )
}

export default BlogDetail