import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { db } from '../firebase'
import { addDoc, collection, getDocs, where, query, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore'


const orderCol = collection(db, 'orders')
const paymentCol = collection(db, 'payments')

export const getAllOrdersAsync = createAsyncThunk('orders', async(user_email)=>{
        try {
            const q = query(orderCol, where("customer_map.email", "==", user_email), where("pending", "==", false));
            const querySnapshot = await getDocs(q);
            const orderList= querySnapshot.docs.map((doc) => {
                return(
                    {
                        data: doc.data(),
                        id: doc.id    
                    }
                )
            });
        
            return orderList
        
        } catch (error) {
            console.log(error);
            throw error;
        }
})

export const createOrderAsync = createAsyncThunk('add-order', async(orderItem)=>{
    try{
        const res = await addDoc(orderCol, orderItem)

        return res.id
    }
    catch(error){
        throw error;
    }
})

export const updateOrderAsync = createAsyncThunk('update-order', async(params)=>{
    try{
        const q = query(orderCol, where('customer_map.email', '==', params.user_email), where('pending', '==', false));
        const querySnapshot = await getDocs(q);
    
        if (querySnapshot.docs.length > 0) {

          const id = querySnapshot.docs[0].id;
          const docRef = doc(orderCol, id)

          await updateDoc(docRef, {
            pending: true,
            lat:params.lat,
            long:params.lng,
            destination: params.destination
          });

        } 

    }
    catch (error){
        throw error;
    }
})

export const getUserOrdersAsync = createAsyncThunk('user/orders', async(email)=>{
    try{
        const q = query(orderCol, where('customer_map.email', '==', email), where('cancelled', '==', false));
        const querySnapshot = await getDocs(q);

        const orderList = querySnapshot.docs.map((doc)=>{
            return(
                {
                    data: doc.data(),
                    id: doc.id   
                }
            )
        })

        return orderList
    }
    catch(error){
        throw error;
    }
})

export const deleteUserOrderAsync = createAsyncThunk('delete/order/user', async(id)=>{
    try {
        const q = query(orderCol, where("__name__", "==", id))
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length > 0) {

            const docRef = doc(orderCol, id)
  
            await updateDoc(docRef, {
              cancelled: true,
              pending:false
            });
        }

    } catch (error) {
        throw error;
    }
})

export const confirmPaymentAsync = createAsyncThunk('confirm/order', async({id, orderID})=>{
    try {

        const q = query(paymentCol, where("thing_id", "==", orderID))
        const querySnapshot = await getDocs(q)

        querySnapshot.docs.map((d)=>{})

    } catch (error) {
        throw error;
    }
})

const order_slice = createSlice({
    name:'order',
    initialState:{orders:[], isLoading: false, error: null, },
    extraReducers: (builder)=>{
        builder
        .addCase(createOrderAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(createOrderAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.orders = action.payload
            state.error = null;
        })
        .addCase(createOrderAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
        })
        .addCase(getAllOrdersAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(getAllOrdersAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.orders = action.payload
            state.error = null;
        })
        .addCase(getAllOrdersAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
        })
        .addCase(updateOrderAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(updateOrderAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null;
        })
        .addCase(updateOrderAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
        })
        .addCase(getUserOrdersAsync.pending, (state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getUserOrdersAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.orders = action.payload;
            state.error = null
        })
        .addCase(getUserOrdersAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(deleteUserOrderAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(deleteUserOrderAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null;
        })
        .addCase(deleteUserOrderAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message;
        })
        .addCase(confirmPaymentAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(confirmPaymentAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null
        })
        .addCase(confirmPaymentAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
    }
})

export const orderActions = order_slice.actions;
export default order_slice;