import React, {useState, useEffect} from 'react'
import images from '../assets/index'
import '../assets/css/checkout.css'
import { Button, FormControl, InputLabel, 
    TextField, Container, Box, Paper,
    IconButton, NativeSelect,Checkbox, FormGroup, FormControlLabel
} from '@mui/material'
import { confirmPaymentAsync, createOrderAsync } from '../store/order_slice'
import { GoogleMap, LoadScript, StandaloneSearchBox } from '@react-google-maps/api';

import { useDispatch, useSelector, } from 'react-redux'
import { getUserProfile } from '../store/auth-slice'

import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { useNavigate, useLocation } from 'react-router-dom'
import GoogleMapReact from 'google-map-react';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';

import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import { addCommas, calculateDistance } from '../utils'

const MarkerComponent = ({ text }) => <EditOutlinedIcon fontSize='large' color='error'/>

function Checkout() {
    const [orders, setOrders] = useState(null)
    const [details, setDetails] = useState(null)
    const [userInfo, setUserInfo] = useState(null)
    const [checked, setChecked] = useState(false)
    const [payNowChecked, setPayNowChecked] = useState(false)
    const [payMethod, setPayMethod] = useState("")
    const [deliveryFees, setDeliveryFees] = useState(0)
    const [totalOrderAmount, setTotalOrderAmount] = useState(0)
    const [lat, setLat] = useState(0.3338);
    const [lon, setLon] = useState(32.5684);
    const [address, setAddress] = useState('');
    const [noAddress, setNoAddress] = useState(false)
    const [autocomplete, setAutocomplete] = useState(null);
    const user_email = useSelector(state => {
        if (state.auth.user && state.auth.user.email) {
          return state.auth.user.email;
        } else {
          return '';
        }
    });
    const u_email = localStorage.getItem('email')
    const [isFinished, setIsFinished] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()

    const localName = localStorage.getItem('name')
    const localEmail = localStorage.getItem('email')
    const user_id = localStorage.getItem('user_id')
    
    const response_url = "https:drugs-express.web.app"
    

    const {order_object} = location.state || {};

    const libraries = ["places"];
    
    const defaultProps = {
        center: {
          lat: lat,
          lng: lon
        },
        zoom: 15
    };


    const getDeliveryCoordinates = async(add)=>{
        fromAddress(add)
        .then(({results})=>{
            setLat(results[0].geometry.location.lat)
            setLon(results[0].geometry.location.lng)
        })
        .catch((error)=>{
            console.log('An error occurred')
        })
    }

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };

    const onPlaceChanged = async () => {
        if (autocomplete !== null) {
            const plc = autocomplete.getPlaces()
            const lat = plc[0].geometry.location.lat()
            const lon = plc[0].geometry.location.lng()
            const add = plc[0].formatted_address.includes(plc[0].name)
            ? plc[0].formatted_address
            : `${plc[0].name}, ${plc[0].formatted_address}`;
            
            setAddress(add)
            setLat(lat)
            setLon(lon)
            const delivery_cdnts = {
                "latitude":lat,
                "longitude":lon
            }
            const distance = calculateDistance(delivery_cdnts)
            const deliveryFeesUrl = 'https://us-central1-drugs-express.cloudfunctions.net/getDeliveryFee';
    
            await fetch(deliveryFeesUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    distance: distance,
                    weight: 0,
                    isExpress: false
                })
            })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    return Promise.reject('HTTP Error: ' + res.status + ' ' + res.statusText);
                }
            })
            .then(data => {
                const orderTotal = order_object.order_total_amount + data.deliveryFee

                setTotalOrderAmount(orderTotal)
                setDeliveryFees(data.deliveryFee)
            })
            .catch(error => {
                console.error('Fetch Error:', error);
            });
            
        } else {
            console.log('An error occurred.');
        }
    };

    const handlePayOnDeliveryChange = (e)=>{
        setChecked(!checked);
        setPayNowChecked(false);
    }

    const handlePayNowChange = (e)=>{
        setPayNowChecked(!payNowChecked);
        setChecked(false);
    }

    useEffect(()=>{

        dispatch(getUserProfile(user_email !== null || user_email !== '' ? user_email : u_email))
        .then(res=>{
            if(res['meta']['requestStatus'] === 'fulfilled'){
                setUserInfo(res.payload)
            }
        })


    },[lat, lon, address, payMethod,])

    const submitOrder = async ()=>{
        setIsSubmitting(true);
        const url = "https://api.olycash.com/v2/purchases/pay"
        const fireFuncUrl = 'https://hellob2cwebworld-k5byflmwca-uc.a.run.app'

        const headers = {
            'Content-Type':'application/json',
            'Authorization':'f253f4d9fb574d1ef5076a48c38cbade04116c0b',
            'Account':'133CC25',
        }

        const payBody = {
            "account_id":"133CC25",
            "item_name":"order_desc",
            "quantity":"1",
            "total":"1000",
            "price":"1000",
            "currency":"UGX",
            "buyer_first_name":"Andrew",
            "buyer_last_name":"Musali",
            "buyer_email":"andrewmusali@gmail.com",
            "buyer_telephone":"256787699743",
            "post_response_url":fireFuncUrl,
            "method":"mobile_money"
        }

        if(address !== ''){

            order_object["order_total_amount"]=totalOrderAmount;
            order_object["lat"] = lat !== null ? lat : ''
            order_object["long"] = lon !== null ? lon : ''
            order_object["destination"] = address !== '' ? address : ''
            order_object["pending"] = true
            order_object["customers"] = [user_id];
            order_object["payment_method"] = checked && !payNowChecked ? "pay_on_delivery" : "pay_now"
            order_object["delivery_fee"] = deliveryFees

            // dispatch(createOrderAsync(order_object))
            // .then(res=>{
            //     if(res['meta']['requestStatus'] === 'fulfilled'){
            //         setIsFinished(true)
            //         setIsSubmitting(false)
            //         const orderId = res.payload

            //         dispatch(confirmPaymentAsync({id:user_id, orderID:orderId}))
            //         .then(res =>{
            //         })


            //     }
            //     else{
            //         setIsSubmitting(false)
            //     }
            // })
        }
        else{
            setNoAddress(true)
        }



        setTimeout(()=>{
            setIsSubmitting(false)
            setNoAddress(false)
        },5000)
    }

  return (
    <Container maxWidth="false" disableGutters>
        <Box 
            component="section"
            height={100}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingTop={1}
            backgroundColor='whitesmoke'
        >
            <img src={images.logo} alt='' className='drugs-express-logo'/>
            {!isFinished ? <h3>Payment</h3> : <h3>Success</h3>}

            
            <Box
                width={200}
                display="flex"
                justifyContent="space-between"
                sx={{
                    width:'150px'
                }}
            >
                <Box
                    width={100}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="center"
                    textAlign="center"
                    fontSize={12}
                    sx={{
                        width:'70px'
                    }}  
                >
                    <SecurityOutlinedIcon/>
                    Make Secure Payments
                </Box>
                <Box
                    width={100}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    alignItems="center"
                    textAlign="center"
                    fontSize={12}
                    sx={{
                        width:'70px'
                    }}
                >
                    <LocalPhoneIcon/>
                    Contact Us
                </Box>
            </Box>
        </Box>

        <Box
            className="checkout-wrapper"

            // sx={{
            //     flexDirection:'column',
            //     paddingLeft:"5px",
            //     paddingRight:"5px"
            // }}
        >
            {
                !isFinished ?
                    <>
                        <Box
                            display="flex"
                            flexDirection="column"
                            flex={0.7}
                            sx={{
                                flex:1,
                                width: `100%`,
                                justifyContent:'center',
                            }}
                        >
                            <Paper elevation={3} variant='outlined' className='customer-add-paper'>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    paddingTop={1}
                                    paddingBottom={1}
                                    borderBottom="1px dotted"
                                >
                                    <Box
                                        display="flex"
                                    >
                                        <ContactMailOutlinedIcon/>
                                        <h5 className='customer-add-title'>Customer</h5>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="center" padding={0}>
                                        <IconButton size='small' style={{padding:'0px'}}>
                                            <EditOutlinedIcon/>
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    paddingLeft={3}
                                    paddingTop={2}

                                    sx={{
                                        paddingLeft:'5px'
                                    }}
                                >
                                    <p className='customer-add-name'>{userInfo ? `${userInfo.first_name} ${userInfo.second_name}` : localName}</p>
                                    <p className='customer-add-contact'>{userInfo ? userInfo.email + " | " + userInfo.phone_number + " | " + userInfo.address : localEmail + " | "}</p>
                                </Box>
                            </Paper>

                            <Paper elevation={3} variant='outlined' className='customer-delivery-loc'>
                                <Box
                                    display="flex"
                                    alignContent="center"
                                    paddingTop={1}
                                    paddingBottom={1}
                                    borderBottom="1px dotted"
                                >
                                    <MyLocationOutlinedIcon/>
                                    <h5 className='customer-add-title'>Set Delivery Address<span style={{color:"red"}}> *</span></h5>
                                </Box>
                                <Box
                                    width={735}
                                    sx={{
                                        width:'400px'
                                    }}
                                >
                                    <LoadScript 
                                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                        libraries={libraries}
                                    >
                                        <StandaloneSearchBox
                                            onLoad={onLoad}
                                            onPlacesChanged={onPlaceChanged}
                                        >
                                            <input
                                                type="text"
                                                placeholder="Search for delivery address..."
                                                className='delivery-loc-search'
                                            />
                                        </StandaloneSearchBox>
                                        <Box className="google-map-box">
                                            <GoogleMap
                                                mapContainerStyle={{
                                                    height: '100%',
                                                    width: '100%'
                                                }}
                                                zoom={16}
                                                center={{
                                                    lat: lat,
                                                    lng: lon
                                                }}
                                            >
                                                <MarkerComponent lat={lat} lng={lon} text=""/>
                                            </GoogleMap>
                                        </Box>
                                    </LoadScript>
                                </Box>
                            </Paper>

                            <Paper elevation={3} variant='outlined' className='customer-payment'>
                                <Box
                                    display="flex"
                                    alignContent="center"
                                    paddingTop={1}
                                    paddingBottom={1}
                                    borderBottom="1px dotted"
                                >
                                    <PaymentOutlinedIcon/>
                                    <h5 className='customer-add-title'>Payment Method<span style={{color:"red"}}> *</span></h5>
                                </Box>
                                <Box
                                    marginTop={2}
                                >
                                    <FormGroup >
                                        <FormControlLabel 
                                            control={
                                                <Checkbox
                                                    checked={payNowChecked}
                                                    size='large'
                                                    onChange={handlePayNowChange}
                                                />
                                            }
                                            label={
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="flex-end"
                                                >
                                                    <p className='customer-pay-on-dlvr'>Pay now<span></span></p>
                                                </Box>
                                            }
                                        />
                                        <FormControlLabel 
                                            control={
                                                <Checkbox
                                                    checked={checked}
                                                    size='large'
                                                    onChange={handlePayOnDeliveryChange}
                                                />
                                            }
                                            label={
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="flex-end"
                                                >
                                                    <p className='customer-pay-on-dlvr'>Pay on Delivery<span></span></p>
                                                </Box>
                                            }
                                        />
                                    </FormGroup>

                                </Box>
                                <Box>

                                </Box>
                            </Paper>
                        </Box>
                        <Box
                            // display="flex"
                            // flexDirection="column"
                            // flex={0.3}
                            // sx={{
                            //     flex:1,
                            //     marginTop:'50px'
                            // }}
                            className="summary-wrapper"
                        >
                            <Paper elevation={3} className='customer-chk-card'>
                                <Box
                                    backgroundColor="whitesmoke"
                                    padding={2}
                                >
                                    <p className='customer-chk-sum-title'>Checkout Summary</p>
                                </Box>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    paddingLeft={1}
                                    paddingRight={1}
                                    paddingTop={2}
                                >
                                    <p className='chk-title'>Name:</p>
                                    <p>{localName}</p> 
                                </Box>
                                <Box display="flex" justifyContent="space-between" paddingLeft={1} paddingRight={1}>
                                    <p className='chk-title'>Number of items:</p>
                                    <p>{order_object.products_list.length}</p> 
                                </Box>
                                <Box display="flex" justifyContent="space-between" paddingLeft={1} paddingRight={1}>
                                    <p className='chk-title'>Item total:</p>
                                    <p>Ugx. {addCommas(order_object.order_total_amount.toString())}</p> 
                                </Box>
                                <Box display="flex" justifyContent="space-between" paddingLeft={1} paddingRight={1}>
                                    <p className='chk-title'>Delivery fee:</p>
                                    <p>Ugx. {addCommas(deliveryFees.toString())}</p> 
                                </Box>
                                <Box display="flex" justifyContent="space-between" paddingLeft={1} paddingRight={1}>
                                    <p className='chk-title'>Order total:</p>
                                    <p>Ugx. {addCommas(totalOrderAmount.toString())}</p> 
                                </Box>
                                <Box display="flex" justifyContent="space-between" paddingLeft={1} paddingRight={1}>
                                    <p className='chk-title'>Payment method:</p>
                                    <p>{ checked && !payNowChecked ? "Pay on delivery" : (payNowChecked && !checked ) ? "Pay now" : "(Payment method not set)"}</p> 
                                </Box>
                                <Box display="flex" justifyContent="space-between" paddingLeft={1} paddingRight={1}>
                                    <p className='chk-title'>Delivery address:{noAddress ? <span style={{color:'red'}}>(Required*)</span> : ''}</p>
                                    <p>{address ? address : "(Address not set)"}</p>
                                </Box>
                                <Box display="flex" justifyContent="center" paddingTop={2} paddingBottom={2}>
                                    <Button variant='outlined' className='chk-btn' onClick={()=>submitOrder()}>proceed</Button>
                                </Box>
                            </Paper>
                        </Box>
                    </>
                :
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        width={"100%"}
                        height={500}
                        paddingTop={3}
                        paddingBottom={5}
                        // backgroundColor="coral"
                    >
                        <img src={images.success} alt='' width={250}/>
                        <h1>Order successful.</h1>
                        <Button variant='outlined' onClick={()=>navigate('/transactions')}>track your order <LocationOnIcon size="small"/></Button>
                    </Box>
            }
        </Box>
    </Container>
  )
}

export default Checkout