import { Paper, Button } from '@mui/material'
import React from 'react'
import '../assets/css/components.css'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { addCommas } from '../utils';

function CartProductCard(props) {
  return (
    <Paper elevation={3} className='cart-product-card'>
        <div className='cart-product-description'>
            <p className='cart-product-name'>{props.name}</p>
            <div className='cart-product-data'>
                <div className='cart-product-label'>
                    <p>Price per unit (Ugx):</p>
                    <p>Quantity:</p>
                    <p>Total Price (Ugx):</p>
                </div>
                <div className='cart-product-vars'>
                    <p> {addCommas(props.price.toString())}</p>
                    <p> {props.quantity}</p>
                    <p> {addCommas(props.ttl_price.toString())}</p>
                </div>
            </div>
        </div>
        <div className='cart-product-buttons'>
            <Button onClick={props.handleUpdate} variant='outlined' size='small'><ModeEditOutlineOutlinedIcon/>Edit</Button>
            <Button onClick={props.handleRemove} variant='contained' color='error' size='small'><DeleteForeverOutlinedIcon/>Remove</Button>
        </div>
    </Paper>
  )
}

export default CartProductCard