import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {
    addDoc,
    collection,
    updateDoc,
    doc,
    getDoc,
    setDoc,
    getDocs,
    where,
    query
} from 'firebase/firestore';
import { db } from '../firebase';

const orderFeedbackCol = collection(db, 'order_feedback')

export const getFeedback = createAsyncThunk('feedback', async(user_id)=>{
    try {

        const q = query(orderFeedbackCol,
            where("customer", "==", user_id),
            where("customer_type", "==", "user"),
            where("pending", "==", true),
            where("responded", "==", false),
            where("rejected", "==", false)
            )
            const querySnapshot = await getDocs(q);
            const feedback = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            return feedback;

    } catch (error) {
        throw error;
    }
})

export const updateFeedBackAsync = createAsyncThunk('feedback/update', async(params)=>{
    try {
        const updateFeedbackQuery = query(orderFeedbackCol, 
            where("customer", "==", params.uid),
            where("customer_type", "==", "user"),
            where("pending", "==", true),
            where("responded", "==", false),
            where("rejected", "==", false)
        )

        const querySnapShot = await getDocs(updateFeedbackQuery)

        if(querySnapShot.docs.length > 0){
            const id = querySnapShot.docs[0].id
            const docRef = doc(orderFeedbackCol, id)

            await updateDoc(docRef, {
                bad: params.bad,
                good: params.good,
                pending: params.pending,
                rating: params.rating,
                rejected: params.rejected,
                responded: params.responded,
                responded_from: params.responded_from,
                responder_id: params.responder_id,
                updated_at: params.updated_at
            });

            // return something or nothing
        }
        
    } catch (error) {
        throw error;
    }
})


const order_feedback_slice = createSlice({
    name:'order_feedback',
    initialState:{ feedback: null, isLoading: false, error: null},
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(getFeedback.pending, (action, state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(getFeedback.fulfilled, (action, state)=>{
            state.isLoading = false;
            state.error = null
            state.feedback = action.payload
        })
        .addCase(getFeedback.rejected, (action, state)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(updateFeedBackAsync.pending, (action, state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(updateFeedBackAsync.fulfilled, (action, state)=>{
            state.isLoading = false;
            state.error = null
        })
        .addCase(updateFeedBackAsync.rejected, (action, state)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
    }
})

export const orderFeedbackActions = order_feedback_slice.actions

export default order_feedback_slice;