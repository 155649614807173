import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {db} from '../firebase';
import { addDoc, collection, updateDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { getDocs, where, query } from 'firebase/firestore';

const notificationsCol = collection(db, 'notifications')


export const createNotificationAsync = createAsyncThunk('create/notification', async(id)=>{
    try {
        const currentDate = new Date();


        const notification = {
            app_link:'https://play.google.com/store/apps/details?id=com.drugsexpress.drugs_express',
            body:'Your account has been created successfully. Welcome to drugs express',
            notify:'true',
            thing_id: id,
            thing_type:'user',
            time:currentDate.getTime(),
            title:'Welcome to Drugs Express',
            viewed: false,
            web_link:'https://drugsexpress.co.ug/'
        }

        const notificationsCol2 = collection(db,`notifications/${id}/${id}`);
        const newNotification = await addDoc(notificationsCol2, notification)

        return {message:'notification sent'}

    } catch (error) {
        throw error;
    }
})


export const createLoginNotificationAsync = createAsyncThunk('create/login/notification', async(id)=>{
    try {
        const currentDate = new Date();


        const notification = {
            app_link:'https://play.google.com/store/apps/details?id=com.drugsexpress.drugs_express',
            body:'You have successfully logged in.',
            notify:'true',
            thing_id: id,
            thing_type:'user',
            time:currentDate.getTime(),
            title:'Welcome to Drugs Express',
            viewed: false,
            web_link:'https://drugsexpress.co.ug/'
        }

        const notificationsCol2 = collection(db,`notifications/${id}/${id}`);
        const newNotification = await addDoc(notificationsCol2, notification)

        return {message:'notification sent'}

    } catch (error) {
        throw error;
    }
})

export const createLogoutNotificationAsync = createAsyncThunk('create/logout/notification', async(id)=>{
    try {
        const currentDate = new Date();


        const notification = {
            app_link:'https://play.google.com/store/apps/details?id=com.drugsexpress.drugs_express',
            body:'You have successfully logged out.',
            notify:'true',
            thing_id: id,
            thing_type:'user',
            time:currentDate.getTime(),
            title:'Welcome to Drugs Express',
            viewed: false,
            web_link:'https://drugsexpress.co.ug/'
        }

        const notificationsCol2 = collection(db,`notifications/${id}/${id}`);
        const newNotification = await addDoc(notificationsCol2, notification)

        return {message:'notification sent'}

    } catch (error) {
        throw error;
    }
})

const notifications_slice = createSlice({
    name:'notification',
    initialState:{isLoading:false, notification:null, error:null},
    extraReducers:(builder)=>{
        builder
        .addCase(createNotificationAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(createNotificationAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.notification = action.payload
            state.error = null
        })
        .addCase(createNotificationAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(createLoginNotificationAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(createLoginNotificationAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.notification = action.payload
            state.error = null
        })
        .addCase(createLoginNotificationAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
        .addCase(createLogoutNotificationAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(createLogoutNotificationAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.notification = action.payload
            state.error = null
        })
        .addCase(createLogoutNotificationAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
    }
})

export const notificationActions = notifications_slice.actions;
export default notifications_slice;