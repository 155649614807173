import ptwo from './images/p2.jpg';
import logo from './images/drugs_logo_5.png'
import logo6 from './images/drugs_logo_6.png'
import google from './images/google.png'
import success from './images/success.png'
import medicine from './images/medicine.png'
import cardiology from './images/cardiology.png'
import cardio_condition from './images/cardio-condition.png'
import user from './images/user.png'
import vitamin from './images/vitamin.png'
import medical_insurance from './images/medical-insurance.png'
import twitter from './images/twitter.png';
import whatsapp from './images/whatsapp.png';
import instagram from './images/instagram.png';
import linkedin from './images/linkedin.png';
import youtube from './images/youtube.png';
import fast_delivery from './images/fast_delivery.png';
import whatsapp_big from './images/whatsapp_big.png';
import not_found from './images/not_found.png';
import user1 from './images/user1.png'
import flash from './images/flash.png'
import image_gallery from './images/image_gallery.png'
import user_auth from './images/user_auth.png';
import heart from './images/heart.png';
import shopping_cart from './images/shopping_cart.png';
import skincare from './images/skincare.png'
import sick from './images/sick.png';
import nutrition_pyramid from './images/nutritional_pyramid.png';
import protein_shake from './images/protein_shake.png';
import protection from './images/protection.png';
import family from './images/family.png';
import settings from './images/settings.png';
import user_profile from './images/user_profile.png';
import approve from './images/approve.png';
import health_condition from './images/health_condition.webp';
import health_devices from './images/popular_categories/health-care-devices.webp';
import personale_care from './images/popular_categories/personal-care.webp';
import medicine_mob from './images/medicine_mob.webp';
import sm_family_planning from './images/family_planning/sm-fp.webp';
import md_family_planning from './images/family_planning/md-fp.webp';
import md_family_planning1 from './images/family_planning/md-fp1.webp';
import lg_family_planning from './images/family_planning/lg-fp.webp';
import lg_family_planning1 from './images/family_planning/lg-fp1.webp';
import vitamins_nutrition from './images/popular_categories/vitamins.webp';
import pain from './images/popular_categories/pain.webp';
import wellness from './images/popular_categories/sexual-wellness.webp';
import add from './images/add.png';
import minus from './images/minus.png';
import drugs from './images/drugs.png';
import offer1 from './images/offer1.png';
import offer2 from './images/offer2.jpg';
import offer3 from './images/offer3.jpg';
import allergy from './images/allergy.png';
import bloating from './images/bloating.png';
import antimalarials from './images/antimalarials.png';
import warning from './images/warning.png';
import viya from './images/viya.png';
import marieStopes from './images/uganda.webp';
import nda from './images/nda.jpeg';
import moh from './images/moh.jpeg';
import ctadsk from './images/ctadsk.png';
import ctamob from './images/ctamob.png';
import ctaipd from './images/ctaipd.png';
import momo from './images/momo.jpg';
import mtn from './images/mtn.png';
import airtel from './images/airtel.png';
import list from './images/list.png';
import equity from './images/equity.png';
import kabaka from './images/kabaka.png';
import talk_to_doc from './images/talk_to_doc.png';
import scarousel1 from './images/carousel/scarousel1.webp'
import scarousel2 from './images/carousel/scarousel2.webp'
import scarousel3 from './images/carousel/scarousel3.webp'
import mcarousel1 from './images/carousel/mcarousel1.webp'
import mcarousel2 from './images/carousel/mcarousel2.webp'
import mcarousel3 from './images/carousel/mcarousel3.webp'
import lcarousel1 from './images/carousel/lcarousel1.webp'
import lcarousel2 from './images/carousel/lcarousel2.webp'
import lcarousel3 from './images/carousel/lcarousel3.webp'
import sadface from './images/sadface.png'
// import pain from ''

const images = {
    ptwo, talk_to_doc, sadface,
    logo, google, success, vitamin, medicine, medical_insurance,
    user, cardiology, cardio_condition, logo6, twitter, whatsapp,
    instagram, linkedin, youtube, fast_delivery, whatsapp_big,
    not_found, user1, flash, image_gallery, user_auth, heart, shopping_cart,
    sick, skincare, nutrition_pyramid, protein_shake, protection, family, settings,
    user_profile, approve, medicine_mob, personale_care,
    health_devices, health_condition, sm_family_planning, vitamins_nutrition, pain,
    wellness, add, minus, drugs, md_family_planning, lg_family_planning,
    offer1, offer2, offer3, allergy, bloating, antimalarials, warning,
    viya, marieStopes, nda, moh, lg_family_planning1, md_family_planning1,
    ctadsk, ctamob, ctaipd, momo, mtn, airtel, list, scarousel1, scarousel2,
    scarousel3, mcarousel1, mcarousel2, mcarousel3, lcarousel1, lcarousel2, lcarousel3,
    equity, kabaka
}

export default images;

