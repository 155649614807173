import React, {useState, useEffect} from 'react'
import '../assets/css/signup.css'
import { Container, Card, Paper, TextField, Button, CircularProgress, IconButton, InputAdornment, Alert } from '@mui/material';
import { useForm } from "react-hook-form"
import images from '../assets/index'
import { NavLink, useNavigate } from 'react-router-dom'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../store/auth-slice';

function ResetPassword() {
    const dispatch = useDispatch();
    const {register, handleSubmit} = useForm()
    const [complete, setComplete] = useState(false)
    const [isLoading, setIsLoading] = React.useState(false)

    

    const onSubmit = (data)=>{
        dispatch(resetPassword(data.email))
        .then(res=>{
            if(res.meta.requestStatus === 'fulfilled'){
                setComplete(true)

                setTimeout(()=>{
                    setComplete(false)
                },5000)
            }
        })
    }

  return (
    <div className='login-div'>
      <div className='login-highlight'>
        <div className='back-to-home'>
          <NavLink to='/'>
            <HomeOutlinedIcon fontSize='small'/>
            Home
          </NavLink>
        </div>
        <img src={images.logo} alt=''/>
        <div>
          <h3>Welcome to Drugs Express</h3>
        </div>
      </div>
      <div className='login-paper-div'>
        <div className='login-error'>
          {complete ? <Alert severity='success'><p>Password reset email sent. Check your inbox.</p></Alert> : <></>}
        </div>
        <Paper className='login-paper' elevation={5}>
          <div className='login-heading'>
            <p>Reset Your Password</p>
          </div>
          <form noValidate onSubmit={handleSubmit(onSubmit)} className='login-form'>
            <TextField
              variant='outlined'
              type='email'
              label='Email'
              size='small'
              {...register('email',{required:true})}
            />
            { isLoading ? <div className='login-progress'><CircularProgress/></div> : <Button variant='contained' type='submit'>RESET PASSWORD</Button>}
          </form>

        </Paper>
      </div>
    </div>
  )
}

export default ResetPassword