import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth, db } from '../firebase';
import { addDoc, collection, updateDoc, doc, getDoc, setDoc, getDocs, where, query  } from 'firebase/firestore';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { capitalizeFirstLetter } from '../utils';


const userCol = collection(db, 'users')

export const loginAsync = createAsyncThunk('auth/login', async(credentials)=>{

    try{
      const response = await signInWithEmailAndPassword(
          auth,
          credentials.email,
          credentials.password
      )
      let profile_response = null;


      const q = query(userCol, where("email", "==", credentials.email))

      const profileSnapshot =  await getDocs(q);
      if(profileSnapshot.docs.length > 0){

        profile_response = profileSnapshot.docs[0].data()
        localStorage.setItem('name', capitalizeFirstLetter(profile_response.first_name) + " " + capitalizeFirstLetter(profile_response.second_name) )
        localStorage.setItem('tel', profile_response.phone_number)
      } 
      const serializedData = {
        email:response.user.email, 
        accessToken: response.user.accessToken,
        refreshToken: response.user.stsTokenManager.refreshToken,
        tokenExpirationTime: response.user.stsTokenManager.expirationTime,
        profile: profile_response !== null ? profile_response : undefined,
        user_id:response.user.uid
      }

      localStorage.setItem('email', response.user.email)
      localStorage.setItem('isLoggedIn', true)
      localStorage.setItem('user_id', response.user.uid)
      
      return serializedData
    }
    catch (error){
        throw error;
    }

})

export const googleLoginAsync = createAsyncThunk( 'auth/google', async ()=>{
  const provider = new GoogleAuthProvider();
    
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    // const checkEmailFunction = httpsCallable(functions, 'checkEmailExists');
    // const email = user.email;
    // const rlt = await checkEmailFunction({email});

    // console.log("CUPPAAA", rlt.data.emailExists)

    let profile_response = null;

    const q = query(userCol, where("email", "==", user.email))

      const profileSnapshot =  await getDocs(q);
      if(profileSnapshot.docs.length > 0){

        
       profile_response = profileSnapshot.docs[0].data()

       localStorage.setItem('name', capitalizeFirstLetter(profile_response.first_name) + " " + capitalizeFirstLetter(profile_response.second_name) )
       localStorage.setItem('tel', profile_response.phone_number)
      } 
      const serializedData = {
        email:user.email,
        accessToken: user.accessToken,
        refreshToken: user.stsTokenManager.refreshToken,
        tokenExpirationTime: user.stsTokenManager.expirationTime,
        profile: profile_response !== null ? profile_response : undefined,
        user_id:user.uid
      }
      localStorage.setItem('email', user.email)
      localStorage.setItem('isLoggedIn', true)
      localStorage.setItem('user_id', user.uid)

      return serializedData

    } catch (error) {
      console.log('An error occurred', error)
    }
  }
)

export const logoutAsync = createAsyncThunk('auth/logout', async(credentials)=>{
    try{
        const response = await signOut(auth)
        localStorage.clear()
        return response
    }
    catch (error){
        throw error;
    }

})

export const registerAsync = createAsyncThunk('auth/register', async(credentials)=>{
  try{
    const response = await createUserWithEmailAndPassword(
      auth,
      credentials.email,
      credentials.password
    )

    const currentDate = new Date()
    currentDate.setSeconds(0,0)

    const u = {
      email:response.user.email,
      date_created: currentDate.getTime(),
    }
    const newUserRef = doc(userCol, response.user.uid);

    await setDoc(newUserRef, u)

    const serializedData = {
      id:response.user.uid,
      email:response.user.email,
      accessToken: response.user.accessToken,
      refreshToken: response.user.stsTokenManager.refreshToken,
      tokenExpirationTime: response.user.stsTokenManager.expirationTime,
    }
    localStorage.setItem('email', response.user.email)
    localStorage.setItem('isLoggedIn', true)
    
    return serializedData
  }
  catch(error){
    throw error;
  }
})

export const updateProfile = createAsyncThunk('auth/update/profile', async(data)=>{
  try{
    const q = query(userCol, where('email', '==', data.email));
    const querySnapshot = await getDocs(q);

    if(querySnapshot.docs.length > 0){
      const id = querySnapshot.docs[0].id;
      const docRef = doc(userCol, id)

      await updateDoc(docRef, {
        first_name: data.first_name,
        second_name: data.last_name,
        gender:data.gender,
        phone_number: data.phoneNumber,
        address: data.address,
        date_of_birth: data.date_of_birth
      })

      const updatedUserProfileSnapShot = await getDoc(docRef)
      const updatedUserProfile = updatedUserProfileSnapShot.data()
      return updatedUserProfile

    }
    else{
      const docRef = await addDoc(userCol, data);
      const newUserProfileSnapShot = await getDoc(docRef);
      const newUserProfile = newUserProfileSnapShot.data();
      return newUserProfile;
    }
  }
  catch (error){
    throw error;
  }
})

export const getUserProfile = createAsyncThunk('auth/profile', async(email)=>{
  try{
    const q = query(userCol, where('email', '==', email));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs[0].data();
  }
  catch(error){
    throw error;
  }
})

export const resetPassword = createAsyncThunk('auth/reset-password', async(email)=>{
  try {
    await sendPasswordResetEmail(
      auth,
      email
    )

    return {message:'Password reset email sent. Check your inbox.'}
  } catch (error) {
    throw error;
  }
})

export const updateReferral = createAsyncThunk('auth/set-referral', async(params)=>{
  try{
    const q = query(userCol, where('email', '==', params.email));
    const querySnapshot = await getDocs(q);

    if(querySnapshot.docs.length > 0){
      const id = querySnapshot.docs[0].id;
      const docRef = doc(userCol, id);

      getDoc(docRef).then(async (docSnapShot)=>{
        if (docSnapShot.exists()){
          const data = docSnapShot.data();
          if('referral' in data ){
            if(data.referral !== ''){
              return 'Referral already exists on object.'
            }
          }
          else{
            await updateDoc(docRef, {
              referral:params.referral
            })
          }
        }
      })
    }


  }
  catch(error){
    throw error;  
  }

})

const auth_slice = createSlice({
    name:'auth',
    initialState:{ user: null, isLoggedIn: false, isLoading: false, error: null, profile: null, id:null },
    reducers:{
        logout: (state)=>{
            state.isLoggedIn = false
        }
    },
    extraReducers: (builder)=>{
        builder
        .addCase(loginAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(loginAsync.fulfilled, (state, action) => {
            state.user = action.payload;
            state.isLoggedIn = true;
            state.isLoading = false;
            state.error = null;
            state.profile = action.payload.profile  
            state.id = action.payload.user_id
          })
          .addCase(loginAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(logoutAsync.pending, (state) => {
            state.isLoading = true;
            state.error = null;
          })
          .addCase(logoutAsync.fulfilled, (state) => {
            state.user = null;
            
            state.isLoggedIn = false;
            state.isLoading = false;
            state.error = null;
          })
          .addCase(logoutAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(registerAsync.pending, (state)=>{
            state.isLoading = true;
            state.error = null;
          })
          .addCase(registerAsync.fulfilled, (state, action)=>{
            state.user = action.payload
            state.isLoggedIn = true;
            state.isLoading = false;
            state.error = null;
          })
          .addCase(registerAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(getUserProfile.pending, (state)=>{
            state.isLoading = true;
            state.error = null;
          })
          .addCase(getUserProfile.fulfilled, (state, action)=>{
            state.profile = action.payload
            state.isLoading = false;
            state.error = null;
          })
          .addCase(getUserProfile.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(updateProfile.pending, (state)=>{
            state.isLoading = true;
            state.error = null;
          })
          .addCase(updateProfile.fulfilled, (state, action)=>{
            state.profile = action.payload
            state.isLoading = false;
            state.error = null;
          })
          .addCase(updateProfile.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          })
          .addCase(resetPassword.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null
          })
          .addCase(resetPassword.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.error = null
          })
          .addCase(resetPassword.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
          })
          .addCase(googleLoginAsync.pending, (state, action)=>{
            state.isLoading = true;
            state.error = null;
          })
          .addCase(googleLoginAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.user = action.payload;
            state.isLoggedIn = true;
            state.isLoading = false;
            state.error = null;
            state.profile = action.payload.profile  
            state.id = action.payload.user_id
          })
          .addCase(googleLoginAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
          });
    }
})

export const authActions = auth_slice.actions

export default auth_slice;