import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Stack, TextField, Paper, 
  CircularProgress, InputProps, InputAdornment,
  IconButton
} from '@mui/material';
import { useForm } from "react-hook-form";
import '../assets/css/signup.css'

import images from '../assets';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { registerAsync } from '../store/auth-slice';
import { extractErrorMessage, isValidEmail } from '../utils';
import { createNotificationAsync } from '../store/notification_slice';


export default function BasicTabs() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const error = useSelector((state)=>state.auth.error)
  const {register, handleSubmit} = useForm()
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorValue, setErrorValue] = React.useState(null)
  const [isVisible, setIsVisible] = React.useState(false);

  
  const hidePass = ()=>{
    setIsVisible(!isVisible)
  }

  const handleOnSubmit = (data)=>{
    setIsLoading(true)

    if(isValidEmail(data.email)){
      const credentials = {
        email:data.email,
        password:data.password
      }
      try{
        if(data.password === data.confirm_password && data.password.length > 6){
          dispatch(registerAsync(credentials))
          .then((res)=>{
            if(res.meta.requestStatus === 'fulfilled')
            {
  
              dispatch(createNotificationAsync(res.payload.id))
              .then(res=>{
              })
              navigate(`/${data.email}/referral`)
            }
            else if(res.meta.requestStatus === 'rejected'){
              const err = extractErrorMessage(res.error.message)
              setErrorValue(err)
            }
          })
        }
        else if(data.password.length < 6){
          setErrorValue('password should have more than 6 characters')
        }
        else if(data.password !== data.confirm_password){
          setErrorValue('passwords do not match')
  
        }
        setTimeout(()=>{
          setIsLoading(false)
          setErrorValue(null)
        },3000)
      }
      catch(error){
        console.log("An error occurred")
      }
    }
    else{
      setErrorValue('Invalid email domain')

      setTimeout(()=>{
        setErrorValue(null)
        setIsLoading(false)
      },3000)
    }
  }

  return (

      <div className='register-container'>
        <div className='register-div'>
          <div className='back-to-home-reg'>
            <div className='back-home-btn'>
              <NavLink to='/'>
                <HomeOutlinedIcon fontSize='small'/>
                <p>Back To Home</p>
              </NavLink>
            </div>
            <div className='mob-back-to-home'>
              <NavLink to='/'>
                <HomeOutlinedIcon fontSize='small'/>
                <p>Home</p>
              </NavLink>
            </div>
          </div>
          <div className='register-logo'>
            <img src={images.logo} alt=''/>
          </div>
          <div className='register-div-heading'>
            <h3>Don't have an account yet!</h3>
          </div>
          <div className='register-paper-div'>
            <div className='register-error'>{errorValue && <p>{errorValue}</p>}</div>
            <Paper elevation={6} className='register-paper' style={{borderRadius:'25px'}}>
              <div className='register-paper-heading'>Join Us</div>
              <form noValidate onSubmit={handleSubmit(handleOnSubmit)}>
              <Stack spacing={3}>
                    <TextField 
                        label="Email"
                        type="email"
                        variant="outlined"
                        size="small"
                        {...register('email', {required:true})}
                    />
                    <TextField
                      label="Password"
                      type={isVisible ? "text" :"password" }
                      variant="outlined"
                      size="small"
                      {...register('password')}
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={hidePass}
                            edge="end"
                          >
                            {isVisible ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                          </IconButton>
                        </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label="Confirm Password"
                      type={isVisible ? "text" :"password" }
                      variant="outlined"
                      size="small"
                      {...register('confirm_password')}
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={hidePass}
                            edge="end"
                          >
                            {isVisible ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                          </IconButton>
                        </InputAdornment>
                        ),
                      }}
                    />
                    <div className='register-btn-div'>
                      {
                        isLoading ?
                          <CircularProgress/>
                        :
                          <Button variant="contained" color="success" type="submit" className='register-btn'>
                            Join
                          </Button>
                          
                      }
                    </div>
                    
                  </Stack>
                
              </form>
              <div className='register-terms'>
                By Continuing you agree to our

                <div><NavLink>Terms and Conditions</NavLink> and <NavLink>Privacy Policy</NavLink></div>
              </div>
            </Paper>
          </div>
          <div className='register-contact'>
            For further support, reach out to us on +256 706 404 040 or send us 
            an email at info@drugsexpress.co.ug.
          </div>
        </div>
      </div>
  );
}
