import React from 'react';
import { Typography, Container, Card, CardContent } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, LineChart, Line, PolarGrid, PolarAngleAxis, PolarRadiusAxis, RadarChart, Radar } from 'recharts';

function AnalyticsComponent() {
  // Sample data for the charts
  const chartData = [
    { name: 'January', sales: 65 },
    { name: 'February', sales: 59 },
    { name: 'March', sales: 80 },
    { name: 'April', sales: 81 },
    { name: 'May', sales: 56 },
    { name: 'June', sales: 55 },
    { name: 'July', sales: 40 },
  ];

  const doughnutData = [
    { name: 'Red', value: 300 },
    { name: 'Blue', value: 50 },
    { name: 'Yellow', value: 100 },
  ];

  const lineData = [
    { name: 'January', traffic: 65 },
    { name: 'February', traffic: 59 },
    { name: 'March', traffic: 80 },
    { name: 'April', traffic: 81 },
    { name: 'May', traffic: 56 },
    { name: 'June', traffic: 55 },
    { name: 'July', traffic: 40 },
  ];

  const polarData = [
    { subject: 'Red', A: 11, B: 16 },
    { subject: 'Green', A: 7, B: 3 },
    { subject: 'Yellow', A: 14, B: 8 },
    { subject: 'Blue', A: 14, B: 8 },
    { subject: 'Pink', A: 11, B: 16 },
  ];

  return (
    <div>
      {/* Sales Chart */}
      <Container maxWidth="lg" style={{ marginTop: '20px' }}>
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Sales Analytics
            </Typography>
            <BarChart width={600} height={300} data={chartData}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="sales" fill="#8884d8" />
            </BarChart>
          </CardContent>
        </Card>
      </Container>

      {/* Website Traffic Doughnut Chart */}
      <Container maxWidth="lg" style={{ marginTop: '20px' }}>
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Website Traffic Analytics
            </Typography>
            <PieChart width={400} height={300}>
              <Pie data={doughnutData} dataKey="value" nameKey="name" fill="#8884d8" />
            </PieChart>
          </CardContent>
        </Card>
      </Container>

      {/* User Engagement Line Chart */}
      <Container maxWidth="lg" style={{ marginTop: '20px' }}>
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              User Engagement Analytics
            </Typography>
            <LineChart width={600} height={300} data={lineData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="traffic" stroke="#8884d8" />
            </LineChart>
          </CardContent>
        </Card>
      </Container>

      {/* Polar Area Chart */}
      <Container maxWidth="lg" style={{ marginTop: '20px' }}>
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Newsletter Subscription Analytics
            </Typography>
            <RadarChart outerRadius={90} width={400} height={300} data={polarData}>
              <PolarGrid />
              <PolarAngleAxis dataKey="subject" />
              <PolarRadiusAxis angle={30} domain={[0, 20]} />
              <Radar name="Value" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
            </RadarChart>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default AnalyticsComponent;
