import { configureStore } from '@reduxjs/toolkit';
import banner_slice from './banner_slice';
import auth_slice from './auth-slice';
import product_slice from './products_slice';
import review_slice from './reviews_slice';
import blog_slice from './blog_slice';
import cart_slice from './cart_slice';
import order_slice from './order_slice';
import wishlist_slice from './wishlist_slice';
import order_feedback_slice from './order_feedback';
import ui_slice from './ui_slice';
import diseases_slice from './diseases_slice';
import notifications_slice from './notification_slice';
import dialogReducer from './searchDialog_slice';

const store = configureStore({
  reducer: {
    banners: banner_slice.reducer,
    products: product_slice.reducer,
    auth: auth_slice.reducer,
    cart: cart_slice.reducer,
    wishlist: wishlist_slice.reducer,
    orders: order_slice.reducer, 
    reviews :review_slice.reducer,
    order_feedback: order_feedback_slice.reducer,
    dialog: dialogReducer,
  },
});

export default store;
