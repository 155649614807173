import images from './assets/index';
import ShoppingCartCheckoutOutlinedIcon from '@mui/icons-material/ShoppingCartCheckoutOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


// functions
export function extractErrorMessage(inputString) {
    const lastSlashIndex = inputString.lastIndexOf('/');
    const closingParenthesisIndex = inputString.indexOf(')');
  
    if (lastSlashIndex !== -1 && closingParenthesisIndex !== -1) {
      const result = inputString.substring(lastSlashIndex + 1, closingParenthesisIndex);
      return result.trim();
    } else {
      return 'Sorry, an error occurred.';
    }
}

// COLORS
export const redColor = '#fd5c63';
export const whiteColor = '#FFFFFF';
export const blackColor = 'black';
export const activeColor = '#4B9CD3'


// TEXT
export const home = 'Home';
export const wish_list = 'Wish List';
export const offers = 'Offers';
export const cart = 'Cart';
export const health_blog = 'Health Blog';
export const about_us = 'About Us';
export const medicine_text = 'Medicine';
export const family_planning = 'Family Planning';
export const health_devices = 'Health Devices';
export const vitamins = 'Vitamins & Nutrition';
export const personal_care = 'Personal Care';
export const health_condition = 'Health Condition';
export const login = 'Login'
export const sign_up = 'Sign Up'
export const welcome = 'Welcome to Drugs Express';
export const ug_largest_digital = "Delivering Health & Beauty with care";
export const ug_largest_health = "Uganda's Leading Online Pharmacy & Telehealth Platform";
export const all_access = "All in the palm of your hands"
export const products = "Products";
export const deliveries = "Deliveries";
export const visitors = "Visitors";
export const tel = "Tel: ";
export const tel_number = "+256 706 404 040";
export const email = "Email: ";
export const email_val = "info@drugsexpress.co.ug";
export const know_us = "Know Us";
export const contact_us = "Contact Us";
export const sign_up_as = "Sign Up as Health Worker";
export const our_policies = "Our Policies";
export const terms = "Terms and Conditions";
export const privacy = "Privacy Policy";
export const editorial = "Editorial Policy";
export const return_policy = "Return Policy";
export const services = "Our Services";
export const buy_medicine = "Buy Medicine";
export const health_plan = "Health Plan";
export const quantityText = 'Quantity';
export const search = "Search";
export const cosmetics = "Body Care"
export const cough_cold_fever = "Cough & Flu"
export const supplements = "Health Supplements";
export const stomach_disorder = "Stomach & Intestinal Disorder"
export const my_orders = "My Orders"
export const pain = "Pain & Fever";
export const anti_malarials = 'Anti Malarials'
export const ear_nose_oropharynx = 'Ear, Nose and Oropharynx'

export const offerImages = [images.offer1, images.offer2, images.offer3]

// carousel
export const mobCarousel = [images.scarousel1, images.scarousel2, images.scarousel3]
export const tabCarousel = [images.mcarousel1, images.mcarousel2, images.mcarousel3]
export const dskCarousel = [images.lcarousel1, images.lcarousel2, images.lcarousel3]


// credits
export const credits = [
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com",
    title:"Icons made by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com",
    title:"Buy Icons made by Freepik"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/torskaya",
    title:"Icons made by torskaya - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/injury",
    title:"Injury icons created by SBTS2018 - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/bloating",
    title:"Bloating icons created by bsd - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/hay-fever",
    title:"Hay fever icons created by Park Jisun - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/smart-cart",
    title:"Smart cart icons created by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/uniconlabs",
    title:"uniconlabs"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/family",
    title:"Family icons created by Good Ware - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/freepik",
    title:"Freepik"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/cosmetics",
    title:"Cosmetics icons created by iconixar - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/sick",
    title:"Sick icons created by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/equipment",
    title:"Equipment icons created by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/nutrition",
    title:"Nutrition icons created by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/nutrition",
    title:"Nutrition icons created by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/protein-shake",
    title:"Protein shake icons created by Smashicons - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/hygiene",
    title:"Hygiene icons created by tulpahn - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/user",
    title:"User icons created by Iconpro86 - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/skills",
    title:"Skills icons created by Kiranshastry - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/dmitri13",
    title:"dmitri13"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/pixel-perfect",
    title:"Pixel perfect"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/medicine",
    title:"Medicine icons"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/twitter",
    title:"Twitter icons created by Pixel perfect - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/whatsapp",
    title:"Whatsapp icons created by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/linkedin",
    title:"Linkedin icons created by riajulislam - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/youtube",
    title:"Youtube icons created by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/sale",
    title:"Sale icons created by Pixel perfect - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/iconsea",
    title:"Icons - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/google",
    title:"Google icons created by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/offer",
    title:"Offer icons created by Flat Icons - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/linkedin",
    title:"Linkedin icons created by riajulislam - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/family",
    title:"Family icons created by Vectors Tank - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/heart-condition",
    title:"Heart condition icons created by muhammad atho' - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/kerismaker",
    title:"Stickers made by Kerismaker"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/vitamins",
    title:"Vitamins icons created by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/delivery",
    title:"Delivery icons created by monkik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/health",
    title:"Health icons created by nawicon - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/vitamins",
    title:"Maps and location icons created by IYIKON - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/food-delivery",
    title:"Food delivery icons created by HJ Studio - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/ida-desi-mariana",
    title:"Medicine icons created by Freepik - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/mansygraphics",
    title:"Icons made by MansyGraphics - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/thunder",
    title:"Thunder icons created by Smashicons - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/kliwir-art",
    title:"Icons created by kliwir art - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/fajrul-fitrianto",
    title:"Icons created by Fajrul Fitrianto - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/hexagon075",
    title:"Icons created by Hexagon075 - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/success",
    title:"Success icons created by Talha Dogar - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/user",
    title:"User icons created by kmg design - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/vectaicon",
    title:"Icons created by vectaicon - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/people",
    title:"People icons created by Mike Zuidgeest - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/logout",
    title:"Logout icons created by Graphics Plazza - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/heart",
    title:"Heart icons created by designhub - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/ecommerce",
    title:"Ecommerce icons created by rukanicon - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/free-icons/transaction",
    title:"Transaction icons created by Robert Angle - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com/authors/heisenberg-jr",
    title:"Icons created by heisenberg-jr - Flaticon"
  },
  {
    flatIcon:"https://www.flaticon.com/",
    url:"https://www.flaticon.com",
    title:"Icons created by Prosymbols Premium - Flaticon"
  },
]

// Navigation Data
export const navDropDownList = [
  {
    'url':'transactions ',
    'icon':<ShoppingCartCheckoutOutlinedIcon className='auth-menu-item-icon'/>,
    'option':'Orders'
  },
  {
    'url':'manage/account',
    'icon':<SettingsOutlinedIcon className='auth-menu-item-icon'/>,
    'option':'Manage Account',
  },
  {
    'url':'signout',
    'icon':<LogoutOutlinedIcon className='auth-menu-item-icon'/>,
    'option':'Log out'
  }
]

// nav tabs
export const nav_tab_options = [
  {url:'/', page:'Home'},
  {url:'/blog',page:'Blog'},
  {url:'/offers',page:'Offers'},
];

export const dropDownList = [
  {
    image:images.family,
    text:family_planning,
    icon:KeyboardArrowRightIcon
  },
  {
    image:images.antimalarials,
    text:anti_malarials,
    icon:KeyboardArrowRightIcon
  },
  {
    image:images.allergy,
    text:cough_cold_fever,
    icon:KeyboardArrowRightIcon
  },
  {
    image:images.sick,
    text: pain,
    icon:KeyboardArrowRightIcon
  },
  {
    image:images.bloating,
    text: stomach_disorder,
    icon:KeyboardArrowRightIcon
  },
  {
    image:images.protection,
    text:cosmetics,
    icon:KeyboardArrowRightIcon
  },
  {
    image:images.protein_shake,
    text:supplements,
    icon:KeyboardArrowRightIcon
  },
  {
    image:images.cardiology,
    text:health_devices,
    icon:KeyboardArrowRightIcon
  },
]


// FUNCTIONS

export function isValidEmail(email) {
  const allowedDomains = ['gmail.com', 'mmpll.com'];
  
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return false;
  }

  const domain = email.split('@')[1];
  
  return allowedDomains.includes(domain);
}

export const capitalizeFirstLetter = (str)=>{
  if(str !== undefined){
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

export const capitalizeProductName = (str, separator)=> {
  if(str !== undefined){
    return str.split(separator).map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(separator);
  }
  return '';
}

export const convertEpochToDatetime = (epochTime) => {
  const date = new Date(epochTime);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const fDay = day < 10 ? '0' + day : day;
  const fMonth = month < 10 ? '0' + month : month;

  const datetime = `${fDay}/${fMonth}/${year}`;

  return datetime;
};

export function splitTextAtPattern(text, pattern = "\\d+\\. \\*\\*") {
  const escapedPattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedPattern, 'g');
  return text.split(regex);
}


export function formatString(input) {
  let formattedString = input.trim();

  formattedString = formattedString.replace(/\s+/g, '-');

  return formattedString;
}

export function calculatePercentageDiscount(oPrice, nPrice){
  const discount = oPrice - nPrice;

  const percentageDiscount = (discount / oPrice) * 100;

  return Math.floor(percentageDiscount);
}

export function addCommas(numberString) {
  const number = Number(numberString.replace(/\D/g, ''));
  
  if (isNaN(number)) {
    return 'Invalid input';
  }

  return number.toLocaleString();
}

const home_coordinates = {"latitude":process.env.REACT_APP_HOME_LOCATION_LAT, "longitude":process.env.REACT_APP_HOME_LOCATION_LONG}

export function calculateDistance(delivery_coordinates){
  const p = 0.017453292519943295;
  const c = Math.cos;
  const a = 0.5 - 
      c((delivery_coordinates.latitude - home_coordinates.latitude) * p) / 2 + 
      c(home_coordinates.latitude * p) * 
      c(delivery_coordinates.latitude * p) * 
      (1 - c((delivery_coordinates.longitude - home_coordinates.longitude) * p)) / 
      2;
  const dist = 12742 * Math.asin(Math.sqrt(a));

  return dist;
}

export const formatPhoneNumber = (number)=>{
  if (number.startsWith('0')) {
    return '256' + number.slice(1);
  }

  if (number.startsWith('+')) {
    return number.slice(1);
  }

  return number;
}

// terms & conditions, Privacy Policy
export const termsDesc = [
  {
    title:'Introduction.',
    text:`Welcome to the Drugs Express B2C platform.\n
      These Terms and Conditions govern your use of our platform. 
      By accessing or using our platform, you agree to comply with these Terms.
      If you do not agree with these Terms, please do not use our platform.`
  },
  {
    title:'Account Registration.',
    text:`You must register an account to use certain features of our platform.
      You agree to provide accurate and complete information during registration and to keep your
      account information updated.
      You are responsible for maintaining the confidentiality of your account credentials and for all
      activities that occur under your account.`
  },
  {
    title:'Use of Platform.',
    text:`You may use our platform for lawful business purposes only.
      You agree not to misuse our platform, including but not limited to engaging in any unauthorized
      or illegal activity.
      We reserve the right to suspend or terminate your account if you violate these Terms.`
  },
  {
    title:'Orders and Payments.',
      text:`All orders placed on our platform are subject to acceptance and availability.
      Prices are subject to change without notice.
      Payments must be made using the methods specified on our platform.`
  },
  {
    title:'Delivery and Returns.',
    text:`Delivery times are estimates and may vary.
      You are responsible for providing accurate delivery information.
      Returns and refunds are subject to our Return Policy, which is incorporated into these Terms.`
  },
  {
    title:'Intellectual Property.',
    text:`All content on our platform, including text, graphics, logos, and images, is the property of Drugs
      Express or its licensors and is protected by intellectual property laws.
      You may not use, reproduce, or distribute any content without our prior written permission.`
  },
  {
    title:'Privacy.',
    text:`Your use of our platform is also governed by our Privacy Policy, which explains how we collect,
      use, and protect your personal information.`
  },
  {
    title:'Limitation of Liability.',
    text:`To the maximum extent permitted by law, Drugs Express shall not be liable for any indirect,
      incidental, special, or consequential damages arising out of or in connection with your use of our
      platform.`
  },
  {
    title:'Changes to Terms.',
    text:`We may update these Terms from time to time. Any changes will be posted on this page, and
      your continued use of the platform constitutes acceptance of the revised Terms.`
  },
  {
    title:'Governing Law.',
    text:`These Terms are governed by the laws of Uganda, without regard to its conflict of laws
      principles.`
  },
  {
    title:'Contact Us.',
    text:'If you have any questions about these Terms, please contact us at info@drugsexpress.co.ug or +256 706 404 040.'
  }
]

export const privacyDesc = [
  {
    title:'Introduction',
    text:`This Privacy Policy explains how Drugs Express collects, uses, and discloses your personal
      information when you use our B2B platform.`
  },
  {
    title:'Information We Collect',
    text:`Business Information: We collect information that you provide to us, such as your business
      name, contact details, and payment information.
      Usage Information: We collect information about how you use our platform, including your IP
      address, browser type, and pages visited.`
  },
  {
    title:'How We Use Your Information',
    text:`To process and fulfill your orders.
      To communicate with you about your account and orders.
      To improve our platform and services.
      To comply with legal obligations.`
  },
  {
    title:'Sharing Your Information',
    text:`We may disclose your information if required by law or to protect our rights.`
  },
  {
    title:'Security',
    text:`We implement appropriate technical and organizational measures to protect your personal
      information from unauthorized access, use, or disclosure.`
  },
  {
    title:'Your Rights',
    text:`You have the right to access, correct, or delete your personal information.
      You can update your account information through your account settings.`
  },
  {
    title:'Cookies',
    text:`Our platform uses cookies to enhance your user experience. You can control the use of cookies
      through your browser settings.`
  },
  {
    title:'Changes to This Policy',
    text:`We may update this Privacy Policy from time to time. Any changes will be posted on this page,
      and your continued use of the platform constitutes acceptance of the revised policy.`
  },
  {
    title:'Contact Us',
    text:`If you have any questions about this Privacy Policy, please contact us at info@drugsexpress.co.ug or +256 706 404 040`
  }
]
