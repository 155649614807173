import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { collection, getDocs, query, where, addDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';

const wishListCol = collection(db, 'saved_products');


export const getAllWishlistAsync = createAsyncThunk('wishlist/all', async(id)=>{
    try {
        const wishListItems = query(wishListCol,
            where("user", "==", id),
            where("item_type", "==", "product")  
        )
        const wishListSnapShot = await getDocs(wishListItems);
        const wishList = wishListSnapShot.docs.map((doc)=>doc.data())
        return wishList;
    } catch (error) {
        throw error;
    }
})

export const addItemToWishListAsync = createAsyncThunk('wishlist/add', async(wishListItem)=>{
    try {
        const q = query(wishListCol, where("item_id", "==", wishListItem.item_id), where("user", "==", wishListItem.user))
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size === 0) {
            const w = await addDoc(wishListCol, wishListItem)
            return {message:'Item added to wishlist', variant:'success'}
        }
        else{
            return {message: 'Item already exists in the wishlist!', variant:'warning'}
        }
    } catch (error) {
        
        throw error;
    }
})

export const removeItemFromWishListAsync = createAsyncThunk('wishlist/remove', async({id, item_id})=>{
    try {
        let doc_id =null;
        const q = query(wishListCol, where("user", "==", id), where("item_id", "==", item_id));
        const querySnapshot = await getDocs(q)

        querySnapshot.docs.map((doc)=> doc_id = doc.id)

        const docRef = doc(wishListCol, doc_id);

        await deleteDoc(docRef)


    } catch (error) {
        throw error;
    }
})

const wishlist_slice = createSlice({
    name:'wishlist',
    initialState: {
        wishlist_items: null,
        isLoading: false,
        error: null,
        message:null,
        wishListNumOfItems:0
    },
    extraReducers: (builder)=>{
        builder
        .addCase(getAllWishlistAsync.pending, (state)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(getAllWishlistAsync.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.wishlist_items = action.payload;
            state.wishListNumOfItems = action.payload.length
        })
        .addCase(getAllWishlistAsync.rejected, (state, action)=>{
            state.error = action.error.message;
            state.isLoading = false;
        })
        .addCase(addItemToWishListAsync.pending, (state)=>{
            state.isLoading = true;
            state.error = null;
        })
        .addCase(addItemToWishListAsync.fulfilled, (state, action)=>{
            const message = action.payload
            state.message = action.payload.message;    
            state.isLoading = false;
            state.error = null;
            state.wishListNumOfItems += 1;
        })
        .addCase(addItemToWishListAsync.rejected, (state, action)=>{
            state.error = action.error.message;
            state.isLoading = false;
        })
        .addCase(removeItemFromWishListAsync.pending, (state)=>{
            state.isLoading = true;
            state.error = null
        })
        .addCase(removeItemFromWishListAsync.fulfilled, (state)=>{
            state.isLoading = false;
            state.error = null;
            state.wishListNumOfItems -= 1;
        })
        .addCase(removeItemFromWishListAsync.rejected, (state, action)=>{
            state.isLoading = false;
            state.error = action.error.message
        })
    }
})


export const wishListActions = wishlist_slice.actions;
export default wishlist_slice;